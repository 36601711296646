import React, { useState } from "react";
import { Row, Col, Button } from "reactstrap";
import { useDispatch } from "react-redux";
import { editQuestion } from "store/actions/questionActions";
import { deleteQuestion } from "store/actions/questionActions";

import {
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	Dropdown,
} from "reactstrap";

const OptionsBlock = (props) => {
	const dispatch = useDispatch();
	// const isMount = useIsMount();
	const { negative_marks, max_marks, id, testId, question_type } = props;

	//question type
	// const [select, setSelect] = useState(question_type);
	// const handleSelectChange = (e) => {
	// 	// setSelect(e.target.value);
	// 	dispatch(editQuestionType({ id, question_type: e.target.value }));
	// };
	const handleSelectChange = (value) => {
		// setSelect(e.target.value);
		dispatch(editQuestion({ id, question_type: value }));
	};

	const dropdownOptions = {
		// mmcq: {
		// 	text: "MCQ",
		// 	id: "mmcq",
		// 	className: "fas fa-dot-circle text-primary",
		// },
		smcq: {
			text: "SMCQ",
			id: "smcq",
			className: "fas fa-dot-circle text-primary",
		},
		integer: {
			text: "Numeric",
			id: "integer",
			className: "fas fa-list-ol",
		},

		fill_in_blank: {
			text: "Fill in the blanks",
			id: "fill_in_blank",
			className: "fas fa-pen-square text-info",
		},

		true_false: {
			text: "True/False",
			id: "true_false",
			className: "fas fa-check-square text-success",
		},

		subjective: {
			text: "Subjective",
			id: "subjective",
			className: "fas fa-align-left text-warning",
		},
	};

	const renderDropDownOptions = () => {
		return Object.values(dropdownOptions).map(({ text, id, className }) => {
			return (
				<DropdownItem onClick={() => handleSelectChange(id)} key={id}>
					<i className={className} />
					{text}
				</DropdownItem>
			);
		});
	};

	const [dropdownOpen, setDropdownOpen] = useState(false);

	const toggle = () => setDropdownOpen((prevState) => !prevState);

	//negative_marks
	const incrementNegativeMarks = () =>
		dispatch(
			editQuestion({
				id,
				negative_marks: Number(negative_marks) + 0.5,
				// max_marks,
			})
		);
	const decrementNegativeMarks = () =>
		dispatch(
			editQuestion({
				id,
				negative_marks: Number(negative_marks) - 0.5,
				// max_marks,
			})
		);
	const handleNegativeMarks = (negativeMarksValue) => {
		dispatch(
			editQuestion({
				id,
				negative_marks: Number(negativeMarksValue),
				// max_marks,
			})
		);
	};

	//MAX marks
	const increment = () =>
		dispatch(
			editQuestion({
				id,
				max_marks: max_marks + 0.5,
				// negative_marks
			})
		);
	const decrement = () =>
		dispatch(
			editQuestion({
				id,
				max_marks: max_marks - 0.5,
				// negative_marks
			})
		);
	const handleMaxMarks = (maxMarksValue) =>
		dispatch(
			editQuestion({
				id,
				max_marks: Number(maxMarksValue),
				// negative_marks
			})
		);

	///delete a question
	const handleDelete = () =>
		dispatch(deleteQuestion({ questionId: id, testId: testId }));

	return (
		<Row className="mb-3 py-1 pb-2 border-bottom  border-top align-baseline ">
			<Col className="d-flex flex-column">
				<div className="mb-2">{null}</div>
				<Dropdown className="m-auto" isOpen={dropdownOpen} toggle={toggle}>
					<DropdownToggle caret color="secondary">
						<i
							className={`mr-3 ${dropdownOptions[question_type]?.className}`}
						/>
						{dropdownOptions[question_type]?.text}
					</DropdownToggle>
					<DropdownMenu>{renderDropDownOptions()}</DropdownMenu>
				</Dropdown>
				{/* <Select2
					// choice = [('1', 'SMCQ'), ('2', 'MMCQ'), ('3', 'Integer'), ('4', 'Fill In The Blanks'),('5','True False'), ('6', 'Subjective')]
					value={question_type}
					data={[
						{
							text: "MCQ",
							id: 1,
						},
						{
							text: "Numeric",
							id: 3,
						},

						{
							text: "Fill in the blanks",
							id: 4,
						},
						{
							text: "True/False",
							id: 5,
						},
						{
							text: "Subjective",
							id: 6,
						},
					]}
					onChange={handleSelectChange}
				/> */}
			</Col>
			<Col className="d-flex flex-column text-danger display-5 text-center border-left border-right">
				<div className="mb-2">Negative Marks</div>
				<div className="d-flex justify-content-center ">
					<Button
						outline
						color="danger"
						size="sm"
						onClick={decrementNegativeMarks}
						disabled={negative_marks <= 0}
					>
						<i className="fas fa-minus" aria-hidden="true"></i>
					</Button>

					<input
						className="btn border border-danger text-danger"
						type="number"
						value={negative_marks}
						onChange={(e) => handleNegativeMarks(e.target.value)}
						style={{ maxWidth: "4rem", padding: "0.2rem" }}
					/>
					<Button
						outline
						color="danger"
						size="sm"
						onClick={incrementNegativeMarks}
					>
						<i className="fas fa-plus" aria-hidden="true"></i>
					</Button>
				</div>
			</Col>
			<Col className="d-flex flex-column text-info display-5 text-center">
				<div className="mb-2">Maximum Marks</div>
				<div className="d-flex justify-content-center">
					<Button
						outline
						color="info"
						size="sm"
						onClick={decrement}
						disabled={max_marks <= 0}
					>
						<i className="fas fa-minus" aria-hidden="true"></i>
					</Button>
					<input
						className="w-50 btn border border-info text-info"
						type="number"
						value={max_marks}
						onChange={(e) => handleMaxMarks(e.target.value)}
						min="0"
						style={{ maxWidth: "4rem", padding: "0.2rem" }}
					/>
					<Button outline color="info" size="sm" onClick={increment}>
						<i className="fas fa-plus" aria-hidden="true"></i>
					</Button>
				</div>
			</Col>
			<Col className="d-flex flex-column text-danger display-5 text-center border-left">
				<Button
					color="danger"
					size="md"
					className="d-block m-auto"
					onClick={handleDelete}
				>
					Delete
				</Button>
			</Col>
		</Row>
	);
};

export default OptionsBlock;
