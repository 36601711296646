import React from "react";
import { Button } from "reactstrap";
import FormikControl from "../../../components/Formik/FormikControl";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import Axios from "axios";
import urls from "../../../../../shared/urls";

const EnrollStudent = ({
	name = "",
	toggleEdit,
	updateChapter,
	course_id,
	chapter_id,
	sendNotification,
}) => {
	const enrollmentSchema = Yup.object().shape({
		name: Yup.string()
			.min(2, "Too Short!")
			.max(50, "Too Long!")
			.required("Required"),
	});

	const initialValues = { name: name };

	const onSubmit = async (values, submitProps) => {
		try {
			const response = await Axios.put(
				urls.course.block(course_id, chapter_id),
				values
			);
			updateChapter(response.data);
			submitProps.resetForm();
			toggleEdit();
			sendNotification("success", "Successfully Edited");
		} catch (error) {
			console.log(error);
			if (error.response) {
				sendNotification("error", error.response.detail);
			} else {
				sendNotification();
			}
		}
	};

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={enrollmentSchema}
			onSubmit={onSubmit}
			enableReinitialize
		>
			{(formik) => (
				<Form>
					<p className="lead text-dark mt-0">Edit Chapter</p>
					<FormikControl
						control="input"
						type="text"
						label="Chapter Name"
						name="name"
						placeholder="Chapter Name"
					/>

					<Button
						color="primary"
						type="submit"
						disabled={!formik.dirty || formik.isSubmitting}
					>
						Save Changes
					</Button>
					<Button
						className="ml-auto "
						color="outline-primary"
						data-dismiss="modal"
						type="button"
						onClick={toggleEdit}
					>
						Close
					</Button>
				</Form>
			)}
		</Formik>
	);
};

export default EnrollStudent;
