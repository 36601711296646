import Axios from "axios";
import urls from "shared/urls";
import { SET_TEST_META } from "store/constants/testMetaConstants";

export const editTestMeta = (formData) => async (dispatch, getState) => {
	const { testId, ...data } = formData;
	if (testId) {
		try {
			const response = await Axios.put(urls.test.singleTest(testId), data);
			dispatch({ type: SET_TEST_META, payload: response.data });
		} catch (error) {
			console.error(error.message);
		}
	}
};
