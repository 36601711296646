import React from "react";
import { Col, Row } from "reactstrap";
import AnswerBlock from "../AnswerBlock";
import QuestionBlock from "../QuestionBlock";
import SolutionBlock from "../SolutionBlock";
const Mcq = (props) => {
	//options block
	const { id, question_text, question_type, choices, solution_text } = props;
	//solution
	const { solution } = props;
	return (
		<Row>
			<Col md={6}>
				<QuestionBlock
					question={question_text}
					id={id}
					question_type={question_type}
				/>
			</Col>
			<Col md={6}>
				<Row>
					{choices &&
						choices.map((choice, index) => (
							<Col md={12} className="mx-0 px-0" key={choice.id}>
								<AnswerBlock
									option={choice.choice_text}
									isCorrect={choice.is_answer}
									optionNumber={`option_${index + 1}`}
									choiceId={choice.id}
									questionId={id}
								/>
							</Col>
						))}

					<Col md={12} className="mx-0 px-0">
						<SolutionBlock
							option={solution_text}
							optionNumber={"solution"}
							checkboxHidden={true}
							id={id}
						/>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default Mcq;
