import { FETCH_TEACHERS_SUCCESS } from "../constants/teacherConstants";
// import keyBy from "lodash/keyBy";
import { USER_LOGOUT } from "store/constants/authConstants";

const initialState = [];

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case FETCH_TEACHERS_SUCCESS:
			return payload.map((teacher) => ({
				name: `${teacher.first_name} ${teacher.last_name}`,
				...teacher,
			}));
		case USER_LOGOUT:
			return initialState;
		default:
			return state;
	}
};
