import Axios from "axios";
import urls from "../../shared/urls";
import {
	FETCH_PLANS_REQUEST,
	FETCH_PLANS_SUCCESS,
	FETCH_PLANS_FAILED,
	EMPTY_ALL_PLANS,
	DELETE_PLANS_REQUEST,
	DELETE_PLANS_SUCCESS,
	DELETE_PLANS_FAILED,
	EDIT_PLANS_REQUEST,
	EDIT_PLANS_SUCCESS,
	EDIT_PLANS_FAILED,
	ADD_PLAN_REQUEST,
	ADD_PLAN_SUCCESS,
	ADD_PLAN_FAILED,
} from "../constants/planConstants";

export const fetchPlans = (courseId) => async (dispatch) => {
	try {
		dispatch({ type: FETCH_PLANS_REQUEST });
		const response = await Axios(urls.plans.data(courseId));
		dispatch({ type: FETCH_PLANS_SUCCESS, payload: response.data.results });
	} catch (error) {
		dispatch({ type: FETCH_PLANS_FAILED });
		console.log(error.message);
	}
};

export const addPlan = (data, sendNotification, toggle, courseId) => async (
	dispatch
) => {
	try {
		dispatch({ type: ADD_PLAN_REQUEST });
		const response = await Axios.post(urls.plans.data(courseId), data);

		dispatch({
			type: ADD_PLAN_SUCCESS,
			payload: { [response.data.id]: response.data },
		});
		toggle();
		sendNotification("success", "Plan added successfully");
	} catch (error) {
		dispatch({ type: ADD_PLAN_FAILED });
		console.error(error.message);
	}
};

export const editPlan = (
	data,
	sendNotification,
	toggle,
	courseId,
	planId
) => async (dispatch) => {
	try {
		dispatch({ type: EDIT_PLANS_REQUEST });
		const response = await Axios.put(urls.plans.update(courseId, planId), data);
		dispatch({
			type: EDIT_PLANS_SUCCESS,
			payload: { [response.data.id]: response.data },
		});
		toggle();
		sendNotification("success", "Plan Edited successfully");
	} catch (error) {
		dispatch({ type: EDIT_PLANS_FAILED });
		console.error(error.message);
	}
};
export const deletePlan = (courseId, planId, sendNotification) => async (
	dispatch
) => {
	try {
		dispatch({ type: DELETE_PLANS_REQUEST });
		await Axios.delete(urls.plans.update(courseId, planId));
		dispatch({ type: DELETE_PLANS_SUCCESS, payload: planId });
		sendNotification("success", "Plan deleted successfully");
	} catch (error) {
		dispatch({ type: DELETE_PLANS_FAILED });
		console.log(error.message);
	}
};

export const emptyPlans = (payload) => async (dispatch) => {
	dispatch({ type: EMPTY_ALL_PLANS });
};
