import React from "react";
import { Field, ErrorMessage } from "formik";
import TextError from "./TextError";
import { FormGroup } from "reactstrap";
const FilePicker = (props) => {
	const { label, name, ...rest } = props;
	return (
		<FormGroup>
			<label htmlFor={name}>{label}</label>
			<div className="custom-file">
				<Field>
					{({ form }) => {
						const { setFieldValue } = form;
						return (
							<input
								className="custom-file-input"
								type="file"
								onChange={(event) =>
									setFieldValue(name, event.currentTarget.files[0])
								}
								{...rest}
							/>
						);
					}}
				</Field>
				<label className="custom-file-label">Browse</label>
			</div>
			<ErrorMessage component={TextError} name={name} />
		</FormGroup>
	);
};

export default FilePicker;
