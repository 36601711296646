import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { deleteRecommendedBooks } from "store/actions/addBookAction";
import { getRecommendedBooks } from "store/actions/addBookAction";
import moment from "moment";
import EditRecommendedBook from "./EditRecommendedBook";
const RecommendedBook = ({ item, showEditHandler }) => {
  const [showReadMore, setShowReadMore] = useState(false);
  const dispatch = useDispatch();
  const deleteButton = (id) => {
    deleteRecommendedBooks(id, () => {
      getRecommendedBooks()(dispatch);
    })(dispatch);
    //console.log("deleteButton", id)
  };
  const editRecommendedBooks = (id) => {
    showEditHandler(id);
    console.log("edit", id);
  };
  return (
    <div>
      <div className="list-group-item-action list-group-item">
        <div className="d-flex">
          <div className="col">
            <div className="row">
              <div className="col-12">
                <span className="text-primary h2">{item.title}</span>
              </div>
              <div className="col-12">
                <div className="row">
                  <div className="col-12 col-md-8">
                    {item.content.length > 150 ? (
                      <>
                        <span>
                          {showReadMore
                            ? item.content
                            : item.content.slice(0, 150)}
                        </span>
                        <span
                          className="read-more"
                          onClick={() => setShowReadMore((prev) => !prev)}
                        >
                          &nbsp;...{!showReadMore ? "read more" : "read less"}
                        </span>{" "}
                      </>
                    ) : (
                      item.content
                    )}
                  </div>
                  <div className="d-flex align-items-end justify-content-end justify-content-xs-start col-12 col-md-4 flex-column ">
                    {moment(item.updated).format(" Do MMMM  YYYY, h:mm a")}
                    <div className="buttons-wrap mt-3">
                      <button
                        className="edit-btn"
                        onClick={() => {
                          editRecommendedBooks(item.id);
                        }}
                      >
                        edit
                      </button>
                      <button
                        className="delete-btn edit-btn"
                        onClick={() => {
                          deleteButton(item.id);
                        }}
                      >
                        delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecommendedBook;
