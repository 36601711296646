import React from "react";
import { Form, Formik } from "formik";
import * as yup from "yup";
import FormikControl from "../components/Formik/FormikControl";
import { Button } from "reactstrap";
import { addBook } from "store/actions/addBookAction";
import { useDispatch } from "react-redux";

const urlRegex =
  /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;

const validationSchema = yup.object().shape({
  title: yup.string().required("Required"),
  content: yup.string().required("Required"),
  url: yup.string().required("Required").matches(urlRegex, "Enter valid url"),
});

const initialValues = {
  title: "",
  content: "",
  url: "",
};

const AddRecommended = ({ setShowRecommendedList }) => {
  const dispatch = useDispatch();
  const cancelClickHandler = () => {
    setShowRecommendedList(true);
  };

  const onSubmit = (values) => {
    console.log(values);
    addBook(values, () => {
      setShowRecommendedList(true);
    })(dispatch);
  };
  return (
    <>
      <div className="col-lg-9">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {(formik) => {
            return (
              <Form>
                <FormikControl
                  control="input"
                  type="text"
                  label={"Title"}
                  name="title"
                  placeholder={"Add title"}
                />
                <FormikControl
                  control="input"
                  type="textarea"
                  rows="6"
                  label={"Content"}
                  placeholder={"Add content"}
                  name="content"
                />
                <FormikControl
                  control="input"
                  type="text"
                  label={"URL"}
                  name="url"
                  placeholder={"Add url"}
                />
                <Button
                  type="submit"
                  className="w-50"
                  color="primary"
                  disabled={formik.isSubmitting || !formik.dirty}
                >
                  Submit
                </Button>
                <Button
                  color="secondary"
                  className="float-right"
                  onClick={() => setShowRecommendedList(true)}
                >
                  Cancel
                </Button>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default AddRecommended;
