import React from "react";
import { useEffect } from "react";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
const DeleteModal = ({
	isOpen,
	handleDelete,
	toggleDelete,
	toggle,
	name,
	children,
}) => {
	useEffect(() => {
		return () => {
			document.querySelector("body").classList.remove("modal-open");
		};
	});
	return (
		<Modal
			isOpen={isOpen}
			toggle={toggleDelete || toggle}
			className="modal-dialog-centered"
		>
			<ModalBody>
				{children ? (
					children
				) : (
					<>
						Do you want to delete
						<span className="font-weight-bold font-italic"> {name}</span>
					</>
				)}
			</ModalBody>
			<ModalFooter>
				<Button color="danger" onClick={handleDelete}>
					Delete
				</Button>
				<Button color="secondary" onClick={toggleDelete || toggle}>
					Cancel
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default DeleteModal;
