import React, { useEffect, useState } from "react";
import "../../../assets/css/argon-dashboard-pro-react.css";
import AddRecommended from "./AddRecommended";
import { getRecommendedBooks } from "store/actions/addBookAction";
import { useSelector, useDispatch } from "react-redux";
import RecommendedBook from "./RecommendedBook";
import EditRecommendedBook from "./EditRecommendedBook";


const Recommended = () => {
  const [showRecommendedList, setShowRecommendedList] = useState(true);
  const [showAddRecommendedList, setShowAddRecommendedList] = useState(false);
  const [showEditRecommendedList, setShowEditRecommendedList] = useState(false);
  const recommendedBooks = useSelector((state) => state.recommendedBooks);
  const [seletedEdit, setSelectedEdit] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    getRecommendedBooks()(dispatch);
  }, []);

  const showEditHandler = (id) => {
    setShowEditRecommendedList(true);
    setShowAddRecommendedList(false)
    setShowRecommendedList(false)
    setSelectedEdit(id)
  }

  const addRecommendedHandler = () => {
    setShowRecommendedList(false);
    setShowAddRecommendedList(true);
    setShowEditRecommendedList(false);
  };
  const hideRecommendedHandler = () => {
    setShowRecommendedList(true);
    setShowAddRecommendedList(false);
    setShowEditRecommendedList(false);
  };
  return (
    <>
      {showRecommendedList ? (
        <div className="col-lg-9">
          <button
            type="button"
            onClick={addRecommendedHandler}
            className="mb-3 btn btn-primary btn-lg btn-block"
          >
            Add Recommended
          </button>
          <ul className="list-group">
            {recommendedBooks?.results?.map((item, index) => (
              <RecommendedBook item={item} key={index} showEditHandler={showEditHandler} />
            ))}
          </ul>
        </div>
      ) : null}
      {
        showAddRecommendedList ? <AddRecommended setShowRecommendedList={hideRecommendedHandler} /> : null
      }
      {
        showEditRecommendedList ? <EditRecommendedBook setShowRecommendedList={hideRecommendedHandler} id={seletedEdit}/> : null
      }
    </>
  );
};

export default Recommended;
