import React, { useEffect, useState } from "react";
import classnames from "classnames";

// reactstrap components
import {
  Card,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Col,
  Row,
} from "reactstrap";

import UpcomingEvents from "./UpcomingEvents/UpcomingEvents";
import OnGoingEvents from "./OnGoingEvents/OnGoingEvents";
import LiveChat from "./LiveChat/LiveChat";
import { useDispatch } from "react-redux";
import { fetchUpcomingEvents } from "store/actions/liveEventActions";
import { fetchOngoingEvents } from "store/actions/liveEventActions";
import { useHistory } from "react-router";

const LiveEvents = (props) => {
  const history = useHistory();
  const institute = props.location.hash.split("/")[2];
  const blockId = props.location.hash.split("/")[3];
  useEffect(() => {
    if (!props.location.hash.split("/")[1]) {
      setTabs(1);
    } else if (
      props.location.hash.split("/")[1] &&
      props.location.hash.split("/")[1].includes("u")
    )
      setTabs(1);
    else setTabs(2);
  }, [props]);
  const [tabs, setTabs] = useState(1);

  const toggleNavs = (e, index) => {
    e.preventDefault();
    setTabs(index);
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchUpcomingEvents());
    dispatch(fetchOngoingEvents());
  }, [dispatch]);

  return (
    <>
      <Row>
        <Col xs={12} md={8}>
          <div className="nav-wrapper">
            <Nav
              className="nav-fill flex-column flex-md-row"
              id="tabs-icons-text"
              pills
              role="tablist"
            >
              <NavItem>
                <NavLink
                  aria-selected={tabs === 1}
                  className={classnames("mb-sm-3 mb-md-0 cursor-pointer", {
                    active: tabs === 1,
                  })}
                  onClick={(e) => {
                    toggleNavs(e, 1);
                    history.push("/admin/live-events/#/u");
                  }}
                  role="tab"
                >
                  <i className="fas fa-stream mr-2 " />
                  Upcoming
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to="/admin/live-events"
                  aria-selected={tabs === 2}
                  className={classnames("mb-sm-3 mb-md-0 cursor-pointer", {
                    active: tabs === 2,
                  })}
                  onClick={(e) => {
                    toggleNavs(e, 2);
                    history.push("/admin/live-events/#/o");
                  }}
                  role="tab"
                >
                  <i className="fas fa-tv mr-2 cursor-pointer" />
                  Ongoing
                </NavLink>
              </NavItem>
            </Nav>
          </div>
          <Card className="shadow">
            <CardBody>
              <TabContent activeTab={"tabs" + tabs}>
                <TabPane tabId="tabs1">
                  <UpcomingEvents {...props} />
                </TabPane>
                <TabPane tabId="tabs2">
                  <OnGoingEvents {...props} />
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={8}>
          {institute &&
            blockId &&
            props.location.hash.split("/")[1] &&
            props.location.hash.split("/")[1].includes("o") && (
              <LiveChat {...props} />
            )}
        </Col>
      </Row>
    </>
  );
};

export default LiveEvents;
