import Axios from "axios";
import urls from "../../shared/urls";
import {
	FETCH_SUBJECTS_FAILED,
	FETCH_SUBJECTS_REQUEST,
	FETCH_SUBJECTS_SUCCESS,
} from "../constants/subjectConstants";

export const fetchSubjects = (payload) => async (dispatch) => {
	try {
		dispatch({ type: FETCH_SUBJECTS_REQUEST });
		const response = await Axios(urls.subjects.fetchSubjects);
		// console.log(response.data);
		dispatch({ type: FETCH_SUBJECTS_SUCCESS, payload: response.data.results });
	} catch (error) {
		dispatch({ type: FETCH_SUBJECTS_FAILED });
	}
};
