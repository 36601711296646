export const FETCH_QUESTIONS_REQUEST = "FETCH_QUESTIONS_REQUEST";
export const FETCH_QUESTIONS_SUCCESS = "FETCH_QUESTIONS_SUCCESS";
export const FETCH_QUESTIONS_FAILED = "FETCH_QUESTIONS_FAILED";

export const EDIT_QUESTION_REQUEST = "EDIT_QUESTION_REQUEST";
export const EDIT_QUESTION_SUCCESS = "EDIT_QUESTION_SUCCESS";
export const EDIT_QUESTION_FAILED = "EDIT_QUESTION_FAILED";

export const DELETE_QUESTION_REQUEST = "DELETE_QUESTION_REQUEST";
export const DELETE_QUESTION_SUCCESS = "DELETE_QUESTION_SUCCESS";
export const DELETE_QUESTION_FAILED = "DELETE_QUESTION_FAILED";

export const ADD_QUESTION_REQUEST = "ADD_QUESTION_REQUEST";
export const ADD_QUESTION_SUCCESS = "ADD_QUESTION_SUCCESS";
export const ADD_QUESTION_FAILED = "ADD_QUESTION_FAILED";

export const EMPTY_ALL_QUESTIONS = "EMPTY_ALL_QUESTIONS";

export const EDIT_QUESTION_STATUS = "EDIT_QUESTION_STATUS";
