import { Form, Formik } from "formik";
import { Button, Fade } from "reactstrap";
import React, { useEffect, useState } from "react";
import FormikControl from "views/pages/components/Formik/FormikControl";

import _ from "lodash";

import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import moment from "moment";

import EditAddPlanForm from "../SingleTest/EditCourse/EditAddPlanForm";
import { fetchTeacherCourses } from "store/actions/enrollmentActions";
import { courseTypeOptions } from "views/pages/components/courseTypeOptions";
import { fetchPlans } from "store/actions/planActions";
import { fetchSubjects } from "store/actions/subjectActions";
import { fetchGoals } from "../../../../store/actions/goalActions";
import { fetchTeachers } from "store/actions/teacherActions";
import urls from "shared/urls";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import { isFreeOptions } from "shared/constants";
import formDataAxios from "shared/formDataAxios";
import { useHistory, useRouteMatch } from "react-router";
import Axios from "axios";
import { addCourse } from "store/actions/enrollmentActions";

const Courses = (props) => {
  const { sendNotification, cancel, componentName } = props;
  const [showEndDate, setShowEndDate] = useState(false);
  const plans = useSelector((state) => Object.values(state.plans));
  const subjects = useSelector((state) => Object.values(state.subjects));
  const goals = useSelector((state) => Object.values(state.goals));
  const teachers = useSelector((state) => Object.values(state.teachers));

  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch();

  useEffect(() => {
    dispatch(fetchSubjects());
    dispatch(fetchGoals());
    dispatch(fetchTeachers());
    // dispatch(fetchPlans());
  }, [dispatch]);

  const initialValues = {
    course_type: "",
    course_introduction_text: "",
    name: "",
    preview_image: "",
    // expires: false,
    goal_ids: [],
    is_free: true,
    // plans: [],
    start_time: new Date(),
    subject_ids: [],
    teacher_ids: [],
  };

  // const dispatch = useDispatch();

  const onSubmit = async (values) => {
    // let values = { ...data };
    // console.log(values);
    if (!showEndDate) {
      values = _.omit(values, ["end_time"]);
    }
    const formData = new FormData();
    formData.append("preview_image", values.preview_image);
    formData.append("is_free", JSON.parse(values.is_free));
    formData.append("name", values.name);
    formData.append(
      "course_introduction_text",
      values.course_introduction_text
    );
    formData.append("course_type", values.course_type);
    formData.append(
      "start_time",
      moment(values.start_time).format("YYYY-MM-DD[T]HH:mm:ss")
    );
    values.goal_ids.map((item) => formData.append("goal_ids", item));
    values.subject_ids.map((item) => formData.append("subject_ids", item));
    values.teacher_ids.map((item) => formData.append("teacher_ids", item));

    try {
      const response = await Axios.post(urls.course.data, formData, {
        headers: {
          "Content-Type": "multipart/form",
        },
      });
      sendNotification("success", "Successfully Added");
      cancel();

      !JSON.parse(values.is_free || false) &&
        history.push(
          `${match.url}/?edit-data=true&edit-plan=true/#/${response.data.id}`
        );
      // console.log(response.data);
      dispatch(addCourse(response.data));
    } catch (error) {
      if (error.response) {
        sendNotification(
          "danger",
          Object.values(error.response.data).toString()
        );
      } else sendNotification();
    }
  };

  const validationSchema = Yup.object().shape({
    // course_id: Yup.string().required("Required"),
    course_type: Yup.string().required("Required"),
    course_introduction_text: Yup.string().required("Required"),
    name: Yup.string().required("Required"),
    preview_image: Yup.string().required("Required"),
    // expires: false,
    goal_ids: Yup.string().required("Required"),
    // is_free: false,
    // plans: Yup.string().required("Required"),
    // start_time: new Date(),
    subject_ids: Yup.string().required("Required"),
    teacher_ids: Yup.string().required("Required"),
  });

  const [modal, setModal] = useState(false);
  const [planFormData, setPlanFormData] = useState({});
  const [editPlanForm, setEditPlanForm] = useState(false);
  const handleEditPlan = (data) => {
    setEditPlanForm(true);
    setPlanFormData(data);
    setModal(true);
  };
  return (
    <>
      <EditAddPlanForm
        modalValue={modal}
        setModalValue={(val) => setModal(val)}
        sendNotification={sendNotification}
        planFormData={planFormData}
        editPlanForm={editPlanForm}
      />

      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {(formik) => {
          // console.log(formik);
          return (
            <Form>
              <FormikControl
                control="input"
                type="text"
                label={`${componentName} Name`}
                name="name"
                placeholder={`${componentName} Name`}
              />
              <FormikControl
                control="input"
                type="textarea"
                rows="6"
                label={`${componentName} Description`}
                placeholder={`${componentName} Description`}
                name="course_introduction_text"
              />
              <FormikControl
                control="checkbox"
                label="Teachers"
                name="teacher_ids"
                options={teachers}
              />
              <FormikControl
                control="checkbox"
                label="Goals"
                name="goal_ids"
                options={goals}
              />
              <FormikControl
                control="radio"
                label={"Course Type"}
                name="course_type"
                options={courseTypeOptions}
              />
              {/* <div className="d-flex align-items-center">
								<label htmlFor="plans" className="mb-0 d-block">
									Plans
								</label>
								<Button
									size="md"
									color="primary"
									className="ml-auto"
									onClick={() => setModal(true)}
								>
									Add New Plan
								</Button>
							</div>

							<FormikControl
								control="customCheckBox"
								label={null}
								name="plans"
								options={plans}
								editPlan={handleEditPlan}
							/> */}
              <FormikControl
                control="radio"
                label={"Type"}
                name="is_free"
                options={isFreeOptions}
              />

              <Row>
                {formik.values.preview_image && (
                  <img
                    src={
                      typeof formik.values.preview_image !== "string"
                        ? URL.createObjectURL(formik.values.preview_image)
                        : undefined
                    }
                    className="avatar avatar-xl bg-transparent mr-3 ml-3"
                    alt="course_image"
                  />
                )}
                <Col>
                  <FormikControl
                    control="file"
                    name="preview_image"
                    label="Add New Thumbnail"
                  />
                </Col>
              </Row>
              {/* <FormikControl
								control="input"
								type="text"
								label={null}
								name="preview_image"
								placeholder="Paste any url"
							/> */}

              <FormikControl
                control="date"
                label="Start Date"
                name="start_time"
              />
              <Button
                className="btn-icon btn-3 mb-3"
                color={showEndDate ? "danger" : "primary"}
                type="button"
                onClick={() => setShowEndDate((s) => !s)}
                aria-expanded={showEndDate}
              >
                <span className="btn-inner--text">
                  {showEndDate ? "Remove End Date" : "Add End Date"}
                </span>
                <span className="btn-inner--icon">
                  <i
                    className={`fas ${showEndDate ? "fa-minus" : "fa-plus"}`}
                  />
                </span>
              </Button>

              {showEndDate && (
                <Fade in={showEndDate}>
                  <FormikControl
                    control="date"
                    label="End Date"
                    name="end_time"
                  />
                </Fade>
              )}

              <FormikControl
                control="checkbox"
                label={"Subjects"}
                name="subject_ids"
                options={subjects}
              />

              <Button
                type="submit"
                className="w-50"
                color="primary"
                // onClick={formik.handleSubmit}
                disabled={formik.isSubmitting || !formik.dirty}
              >
                Submit
              </Button>
              <Button
                color="secondary"
                className="float-right"
                onClick={cancel}
              >
                Cancel
              </Button>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default Courses;
