import React, { useState } from "react";
import { Col, Row } from "reactstrap";
import SolutionBlock from "../SolutionBlock";
import QuestionBlock from "../QuestionBlock";
import { useDispatch } from "react-redux";
import { editQuestion } from "store/actions/questionActions";
import { editQuestionOption } from "store/actions/questionActions";
const TrueFalse = (props) => {
	const { id, question_text, choices, question_type } = props;

	//solution
	const { solution_text } = props;
	const dispatch = useDispatch();
	const handleChange = (e) => {
		dispatch(
			editQuestionOption({
				questionId: id,
				choiceId: e.target.id,
				is_answer: true,
			})
		);
	};

	return (
		<Row>
			<Col md={6}>
				<QuestionBlock
					question={question_text}
					id={id}
					question_type={question_type}
				/>
			</Col>
			<Col md={6}>
				<Row>
					<Col md={12} className="mx-0 px-0" onChange={handleChange}>
						{/* {choices.map((choice) => ( */}
						<div className="custom-control custom-radio custom-control-inline">
							<input
								type="radio"
								className="custom-control-input"
								checked={choices[0].is_answer === true}
								id={choices[0].id}
								name={"option" + choices[0].id}
							/>
							<label
								className="custom-control-label text-primary"
								htmlFor={choices[0].id}
							>
								{choices[0].choice_text}
							</label>
						</div>
						<div className="custom-control custom-radio custom-control-inline">
							<input
								type="radio"
								className="custom-control-input"
								checked={choices[1].is_answer === true}
								id={choices[1].id}
								name={"option" + choices[1].id}
							/>
							<label
								className="custom-control-label text-primary"
								htmlFor={choices[1].id}
							>
								{choices[1].choice_text}
							</label>
						</div>
						{/* ))} */}
						{/* <div className="custom-control custom-radio  custom-control-inline">
							<input
								type="radio"
								className="custom-control-input"
								value={false}
								id={id + "false"}
								name={"option" + id}
								checked={!isTrue}
							/>
							<label
								className="custom-control-label text-primary"
								htmlFor={id + "false"}
							>
								False
							</label>
						</div> */}

						{/* <AnswerBlock
							option={option_2}
							isCorrect={is_option_2_correct}
							optionNumber={"option_2"}
							id={id}
						/> */}
					</Col>

					<Col md={12} className="mx-0 px-0">
						<SolutionBlock
							option={solution_text}
							optionNumber={"solution"}
							checkboxHidden={true}
							id={id}
						/>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default TrueFalse;
