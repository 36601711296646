import { ErrorMessage, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, CardBody, Col, Form, Row } from "reactstrap";
import FormikControl from "../components/Formik/FormikControl";
import CustomReactSelect from "../components/CustomReactSelect";
import { couponTypes } from "shared/constants";
import { fetchPlans } from "store/actions/planActions";
import Axios from "axios";
import urls from "shared/urls";
import * as Yup from "yup";

const AddCoupon = ({ updateTable, initialValues }) => {
	const validationSchema = Yup.object().shape({
		code: Yup.string().required("Required"),
		// course: Yup.string().required("Required"),
		plan: Yup.string().required("Required"),
		value: Yup.string().required("Required"),
		// title: Yup.string().required("Required"),
		// description: Yup.string().required("Required"),
	});

	const dispatch = useDispatch();

	const teacherCourses = useSelector((state) =>
		Object.values(state.teacherCourses).map((item) => ({
			id: item.id,
			text: item.name,
		}))
	);
	const plans = useSelector((state) =>
		Object.values(state.plans).map((item) => ({
			id: item.id,
			text: item.name,
			...item,
		}))
	);
	const [selectedCourseId, setSelectedCourseId] = useState(null);

	const onSubmit = async (values, formik) => {
		let formData = { ...values, course: selectedCourseId };

		// remove falsy values
		formData = Object.entries(formData).reduce(
			(a, [k, v]) => (v ? ((a[k] = v), a) : a),
			{}
		);

		if (JSON.parse(values.isUnlimited || false)) {
			formData.max_uses = -1;
		}

		try {
			const response = await Axios.post(
				urls.discountCodes.update(values.course, values.plan),
				formData
			);
			updateTable(response.data);
			formik.resetForm();
		} catch (error) {
			console.error(error);
			formik.setErrors(error.response.data);
		}
	};

	const dispatchFetchPlans = (id) => {
		if (id) {
			if (plans.length > 0) {
				// console.log(plans[0].course !== id, plans[0]?.course, id);
				if (plans[0]?.course !== id) return dispatch(fetchPlans(id));
			} else dispatch(fetchPlans(id));
		}
	};

	return (
		<Card>
			<CardBody>
				<Formik
					initialValues={{
						code: "",
						course: teacherCourses,
						plan: "",
						coupon_type: couponTypes.percentage,
						value: "",
						title: "",
						description: "",
						...initialValues,
					}}
					enableReinitialize
					onSubmit={onSubmit}
					validationSchema={validationSchema}
				>
					{(formik) => (
						<Form>
							{/* <code>{JSON.stringify(formik.errors)}</code> */}
							<Row>
								<Col xs={12} md={6}>
									<FormikControl
										control="input"
										name="code"
										type="text"
										label="Code Name"
										placeholder="Enter Code that students will use for discount"
									/>
								</Col>
							</Row>
							<FormikControl
								control="input"
								name="max_uses"
								type="number"
								label="Maximum Usage"
								min="-1"
								placeholder="Maximum usage"
								disabled={JSON.parse(formik.values.isUnlimited || false)}
							/>
							<FormikControl
								control="checkBoxWithLabel"
								name="isUnlimited"
								label="Check this box if you want it to be used unlimited times"
							/>
							<Row>
								<Col xs={12} md={6}>
									<CustomReactSelect
										label="Course"
										dropDownArray={teacherCourses}
										select={formik.values.course}
										// select={}
										name="course"
										handleOnChange={(id) => {
											// setSelectedCourseId(null);
											// setSelectedCourseId(id);
											// fromik.values.course.includes("id") &&
											dispatchFetchPlans(id);
											formik.setFieldValue("course", null);
											formik.setFieldValue("course", id);
										}}
									/>
								</Col>
								<Col xs={12} md={6}>
									<CustomReactSelect
										label="Plan"
										name="plan"
										dropDownArray={plans}
										select={formik.values.plan}
										handleOnChange={(id) => {
											// id && formik.setFieldTouched("plan", true);
											formik.setFieldValue("plan", id);
										}}
									/>
								</Col>
							</Row>
							<Row>
								<Col xs={12} md={6}>
									<CustomReactSelect
										label="Type of Discount"
										select={formik.values.coupon_type}
										handleOnChange={(id) =>
											formik.setFieldValue("coupon_type", id)
										}
										dropDownArray={[
											{ id: couponTypes.amount, text: "Amount" },
											{ id: couponTypes.percentage, text: "Percentage" },
										]}
									/>
								</Col>
								<Col xs={12} md={6}>
									<FormikControl
										label={
											formik.values.coupon_type === couponTypes.amount
												? "Amount"
												: "Percentage"
										}
										name="value"
										type="number"
										control="customInput"
										addonType={"prepend"}
										addonText={
											formik.values.coupon_type === couponTypes.amount
												? "₹"
												: "%"
										}
									/>
								</Col>
							</Row>
							<FormikControl
								control="input"
								name="title"
								type="text"
								label="Code title"
							/>
							<FormikControl
								control="input"
								name="description"
								type="text"
								label="Code description"
							/>
							<Button
								type="submit"
								color="primary"
								block
								disabled={!formik.dirty || formik.isSubmitting}
								onClick={formik.handleSubmit}
							>
								Add Coupon
							</Button>
						</Form>
					)}
				</Formik>
			</CardBody>
		</Card>
	);
};

export default AddCoupon;
