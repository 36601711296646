import React from "react";

import ListQuestions from "./TestQuestions/ListQuestions";
import { Col, FormGroup, Input, Row } from "reactstrap";
import ListCourses from "../Courses/ListCourses";
import SingleCourse from "../Courses/SingleCourse";
import { useDispatch, useSelector } from "react-redux";
import { editTestMeta } from "store/actions/testMetaActions";
import { useState } from "react";
import { useEffect } from "react";
import { componentNames } from "shared/constants";
const TestSeries = (props) => {
	// let { id, testId } = useParams();
	const id = props.location.hash.split("/")[1];
	const testId = props.location.hash.split("/")[2];

	const handleRouting = () => {
		if (testId) {
			return <ListQuestions testId={testId} />;
		} else if (id) {
			return <SingleCourse id={id} {...props} />;
		} else {
			return (
				<ListCourses {...props} componentName={componentNames.testSeries} />
			);
		}
	};
	const { duration } = useSelector((state) => state.testMeta);

	const dispatch = useDispatch();
	const [durationData, setDurationData] = useState(duration);

	useEffect(() => {
		setDurationData(duration);
	}, [duration]);

	return (
		<Row>
			<Col sm={{ order: 2 }} lg={{ size: 9, order: 1 }}>
				{handleRouting()}
			</Col>
			{testId && (
				<Col sm={{ order: 1 }} lg={{ size: 3, order: 2 }}>
					<FormGroup>
						<label htmlFor="duration">Duration in minutes</label>
						<Input
							name="duration"
							value={durationData}
							type="number"
							onChange={(e) => setDurationData(e.target.value)}
							onBlur={(e) =>
								dispatch(editTestMeta({ testId, duration: e.target.value }))
							}
						/>
					</FormGroup>
				</Col>
			)}
		</Row>
	);
};

export default TestSeries;
