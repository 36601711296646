import React, { useEffect } from "react";
import { Button, Col, ListGroupItem, Row, ListGroup } from "reactstrap";
import moment from "moment";
import { Link } from "react-router-dom";
import CustomSpinner from "views/pages/components/CustomSpinner";
import { useDispatch, useSelector } from "react-redux";
import { fetchUpcomingEvents } from "store/actions/liveEventActions";
import { INSTITUTE_ID } from "shared/constants";
const ListUpcomingEvent = (props) => {
	const { course_name, id, title, start_time } = props;

	return (
		<ListGroupItem>
			<Row className="align-items-center">
				<Col className="d-flex align-items-center justify-items-end" xs={12}>
					<Link
						to={`/admin/live-events/#/u/${INSTITUTE_ID}/${id}`}
						className="text-primary h2"
					>
						{title}
					</Link>
					{/* <Button className="ml-auto" size="sm" outline color="danger">
						Delete
					</Button> */}
				</Col>
				<Col className="d-flex align-items-center justify-items-end" xs={12}>
					<div className="text-muted">{course_name}</div>
					<div className="ml-auto text-muted">
						{moment(start_time).format("D MMM YYYY, h:mm A")}
					</div>
				</Col>
			</Row>
		</ListGroupItem>
	);
	//  : (
	// 	<CustomSpinner className="d-flex justify-content-center w-100" size={20} />
	// );
};

const UpcomingEvents = () => {
	const upcomingEvents = useSelector((state) =>
		Object.values(state.upcomingEvents)
	);

	const renderUpcomingEvents = () =>
		upcomingEvents.map((event) => (
			<ListUpcomingEvent {...event} key={event.id} />
		));

	return (
		<ListGroup>
			{upcomingEvents.length === 0 ? (
				<ListGroupItem>No upcoming events!</ListGroupItem>
			) : (
				renderUpcomingEvents()
			)}
		</ListGroup>
	);
};

export default UpcomingEvents;
