import Axios from "axios";
import { getAuthInfo } from "./helpers";

const formDataAxios = Axios.create({
	headers: {
		"Content-Type": "multipart/form",
		Authorization: `Bearer ${getAuthInfo()?.access_token}`,
	},
});

export default formDataAxios;
