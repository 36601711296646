import { SET_TEST_META } from "store/constants/testMetaConstants";

const initialState = {
	id: null,
	institute: "",
	teachers: [],
	subjects: [],
	goals: [],
	sections: [],
	name: "",
	start_time: "",
	end_time: null,
	duration: 60,
	instructions: "",
	show_result_instantly: true,
	show_solution_instantly: true,
	result_time: null,
	attempts_allowed: null,
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case SET_TEST_META:
			return { ...state, ...payload };
		default:
			return state;
	}
};
