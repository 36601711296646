import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getAuthInfo } from "../../../shared/helpers";

function PrivateRoute({ component: Component, ...rest }) {
	const authInfo = getAuthInfo();
	return (
		<Route
			{...rest}
			render={(props) =>
				authInfo ? (
					<Component {...rest} {...props} />
				) : (
					<Redirect
						to={{
							pathname: "/auth/login",
							state: { from: props.location },
						}}
					/>
				)
			}
		/>
	);
}

export default PrivateRoute;
