import Axios from "axios";
import React, { useEffect, useState, useCallback } from "react";
import {
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Button,
  Collapse,
} from "reactstrap";
import EditAddBlockForm from "./EditAddBlockForm";
import EditChapterForm from "./EditChapterForm";
import NewChapter from "./NewChapter";
import moment from "moment";
import style from "./chapters.module.css";
import { useHistory, useLocation } from "react-router";
import { useSelector } from "react-redux";
import CustomSpinner from "../../../components/CustomSpinner";
import DeleteModal from "views/pages/components/DeleteModal";
import urls from "../../../../../shared/urls";
import keyBy from "lodash/keyBy";
import omit from "lodash/omit";
import UncontrolledTooltip from "reactstrap/lib/UncontrolledTooltip";
import { Link } from "react-router-dom";

const Chapters = ({ id: course_id, sendNotification }) => {
  const [chapters, setChapters] = useState({});
  const _isMounted = React.useRef(true);
  const [chaptersLoading, setChaptersLoading] = useState(false);

  const fetchChapters = useCallback(async () => {
    try {
      setChaptersLoading(true);
      const response = await Axios(urls.course.chapters(course_id));
      setChapters(keyBy(response.data.results, "id"));
    } catch (error) {
      console.error(error);
    } finally {
      setChaptersLoading(false);
    }
  }, [course_id]);

  const updateChapter = (newChapter) =>
    setChapters({
      ...chapters,
      [newChapter.id]: newChapter,
    });

  useEffect(() => {
    fetchChapters();

    return () => {
      _isMounted.current = false;
    };
  }, [fetchChapters]);

  const renderChapters = (course_id) => {
    return (
      _isMounted.current &&
      Object.values(chapters).map((chapter) => (
        <SingleChapter
          key={chapter.id}
          {...chapter}
          course_id={course_id}
          updateChapter={updateChapter}
          deleteChapter={(id) => setChapters((prevData) => omit(prevData, id))}
          sendNotification={sendNotification}
        />
      ))
    );
  };
  const teacherCourses = useSelector((state) => state.teacherCourses);
  const history = useHistory();
  console.log("teacherCoursesteacherCourses", teacherCourses);
  return (
    <div>
      <h2 className="text-primary mb-3">
        <i
          className="fas fa-chevron-circle-left mr-3 cursor-pointer"
          onClick={() => history.goBack(-1)}
          style={{ cursor: "pointer !important" }}
        />
        {teacherCourses[course_id]?.name || "Course name"}
      </h2>

      <NewChapter
        updateChapter={updateChapter}
        course_id={course_id}
        sendNotification={sendNotification}
      />
      {chaptersLoading ? (
        <CustomSpinner className="d-flex justify-content-center w-100 h-50 my-5" />
      ) : Object.values(chapters).length === 0 ? (
        <ListGroupItem>No Chapters added</ListGroupItem>
      ) : (
        <ListGroup>{renderChapters(course_id, sendNotification)}</ListGroup>
      )}
    </div>
  );
};

export default Chapters;

//single chapter
const SingleChapter = (props) => {
  const {
    course_id,
    sendNotification,
    name,
    id: chapter_id,
    updateChapter,
    deleteChapter,
  } = props;
  const [data, setData] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isAddBlockOpen, setIsAddBlockOpen] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Axios.get(
          urls.course.blocks(course_id, chapter_id)
        );

        setData(keyBy(response.data, "id"));
      } catch (error) {
        setData([]);
      }
    };
    isOpen && fetchData();
  }, [course_id, chapter_id, isOpen]);

  const updateBlocks = (blockData) => {
    setData((prevData) => ({ ...prevData, [blockData.id]: blockData }));
  };

  const toggle = () => {
    setIsEditOpen(false);
    setIsAddBlockOpen(false);
    return setIsOpen(!isOpen);
  };
  const toggleEdit = () => {
    setIsOpen(false);
    return setIsEditOpen(!isEditOpen);
  };
  const toggleAddBlock = () => setIsAddBlockOpen(!isAddBlockOpen);

  const toggleDelete = () => setModalDelete(!modalDelete);

  const handleChapterDelete = async () => {
    try {
      await Axios.delete(urls.course.block(course_id, chapter_id));
      deleteChapter(chapter_id);
      sendNotification("success", "Deleted successfully");
      toggleDelete();
    } catch (error) {
      sendNotification("danger", "Couldn't delete, try again ");
      console.log(error);
    }
  };

  const handleBlockDelete = async (blockId, toggleBlockDelete) => {
    try {
      await Axios.delete(
        urls.course.singleBlock(course_id, chapter_id, blockId)
      );
      setData(omit(data, blockId));
      sendNotification("success", "Deleted successfully");
      toggleBlockDelete();
    } catch (error) {
      sendNotification("danger", "Couldn't delete, try again ");
      console.log(error);
    }
  };

  return (
    <>
      <DeleteModal
        isOpen={modalDelete}
        toggle={toggleDelete}
        name={name}
        handleDelete={handleChapterDelete}
      />

      <ListGroupItem className="border border-primary">
        <Row className="align-items-center">
          <Col className="ml--2">
            <span
              className="mb-0 text-primary pl-2 text-uppercase font-weight-bold cursor-pointer"
              onClick={toggle}
            >
              <i
                className={
                  isOpen ? "fas fa-chevron-up mr-2" : "fas fa-chevron-down mr-2"
                }
              />
              {name}
            </span>
          </Col>

          <Col xs={2} className="d-flex justify-content-end">
            <Button
              color="primary"
              size="sm"
              type="button"
              onClick={toggleEdit}
            >
              Edit
            </Button>
            <Button
              color="danger"
              outline
              type="button"
              size="sm"
              onClick={toggleDelete}
            >
              Delete
            </Button>
          </Col>
        </Row>
        <Collapse isOpen={isOpen} className="mt-3">
          {data === null ? (
            // <ListGroupItem className="border border-primary p-1">
            <CustomSpinner
              className="d-flex justify-content-center w-100 mb-5"
              size={25}
              margin={5}
            />
          ) : // </ListGroupItem>
          data.length === 0 ? (
            <ListGroupItem className="border border-primary">
              No blocks for given chapter
            </ListGroupItem>
          ) : (
            <ListGroup>
              {Object.values(data).map((item) => {
                return (
                  <SingleBlock
                    {...item}
                    key={item.id}
                    chapter_id={chapter_id}
                    course_id={course_id}
                    updateBlocks={updateBlocks}
                    handleBlockDelete={handleBlockDelete}
                    sendNotification={sendNotification}
                    id={item.id}
                  />
                );
              })}
            </ListGroup>
          )}
          <Button
            block
            color="primary"
            className="mt-2"
            size="lg"
            type="button"
            onClick={toggleAddBlock}
          >
            Add Block
          </Button>
        </Collapse>
        <Collapse isOpen={isEditOpen} className="mt-3">
          <EditChapterForm
            updateChapter={updateChapter}
            course_id={course_id}
            chapter_id={chapter_id}
            sendNotification={sendNotification}
            toggleEdit={toggleEdit}
            name={name}
          />
        </Collapse>
        <Collapse isOpen={isAddBlockOpen} className="mt-3">
          <EditAddBlockForm
            modalValue={isAddBlockOpen}
            setModalValue={(val) => setIsAddBlockOpen(val)}
            course_id={course_id}
            chapter_id={chapter_id}
            updateBlocks={updateBlocks}
            {...props}
            is_free={false} // setting new block option to free
            addBlock
            // id={id}
          />
        </Collapse>
      </ListGroupItem>
    </>
  );
};

//single block for each test within chapter
const SingleBlock = (props) => {
  const {
    title,
    sendNotification,
    block_type,
    start_time,
    resource_id,
    updateBlocks,
    course_id,
    chapter_id,
    id: block_id,
    handleBlockDelete,
  } = props;
  // console.log({ course_id, chapter_id, block_id });

  const location = useLocation();
  const history = useHistory();
  const [readable, setReadAble] = useState(null);

  const [modal, setModal] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const toggleDelete = () => setModalDelete(!modalDelete);

  const getIconBasedOnBlockType = () => {
    console.log("block_type", block_type);
    switch (block_type) {
      case "youtube_video":
        return <i className="fab fa-youtube mr-2 text-danger"></i>;
      case "classcast_server_video":
        return (
          <i
            className="fas fa-play mr-2 "
            style={{ color: "rgb(244, 215, 138)" }}
          ></i>
        );
      case "readable":
        return <i className="fas fa-book mr-2 "></i>;
      case "video":
        return (
          <i className="fas fa-play mr-2 " style={{ color: "#11cdef" }}></i>
        );
      case "test":
        return <i className="fas fa-file-alt mr-2 text-success"></i>;
      case "live_session":
      case "zoom":
        return <i className="fas fa-video mr-2 text-info"></i>;
      default:
        return null;
    }
  };
  const getBorderBasedOnBlockType = () => {
    switch (block_type) {
      case "youtube_video":
        return style.red;
      case "classcast_server_video":
        return style.yellow;
      case "readable":
        return style.primary;
      case "test":
        return style.green;
      case "live_session":
      case "zoom":
        return style.blue;
      default:
        return style.blue;
    }
  };

  const fetchReadAbleFileForBlock = React.useCallback(async () => {
    try {
      const response = await Axios.get(
        urls.course.singleBlock(course_id, chapter_id, block_id)
      );
      // console.log(response.data);
      setReadAble(response.data);
    } catch (error) {
      console.error(error);
    }
  }, [course_id, chapter_id, block_id]);

  useEffect(() => {
    block_type === "readable" && fetchReadAbleFileForBlock();
  }, [block_type, fetchReadAbleFileForBlock]);

  console.log("propsssssssssssss", props);
  return (
    <>
      <DeleteModal
        isOpen={modalDelete}
        toggle={toggleDelete}
        name={title}
        handleDelete={() => handleBlockDelete(block_id, toggleDelete)}
      />

      <ListGroupItem
        className={`my-1 shadow-3 rounded ${getBorderBasedOnBlockType()} ${
          block_type === "test" &&
          location.pathname.includes("test-series") &&
          "cursor-pointer"
        }`}
      >
        <Row className="align-items-center">
          <Col className="d-flex flex-wrap">
            <div
              className="mb-0 text-primary pl-2 text-capitalize"
              onClick={() =>
                block_type === "test" &&
                location.pathname.includes("test-series") &&
                resource_id &&
                history.push(
                  location.pathname + location.hash + "/" + resource_id
                )
              }
              id={
                block_type === "test" &&
                location.pathname.includes("test-series")
                  ? "tooltip611234743"
                  : undefined
              }
            >
              {getIconBasedOnBlockType()}
              {/* <a target="_blank" href={props?.url}> */}
              {title}
              {/* </a> */}
            </div>
            <div className="text-muted ml-2 text-capitalize">
              - {moment(start_time).format("llll")}
            </div>
            {block_type === "test" &&
              location.pathname.includes("test-series") && (
                <UncontrolledTooltip
                  delay={0}
                  placement="bottom"
                  target="tooltip611234743"
                >
                  Click to add questions to this test
                </UncontrolledTooltip>
              )}
          </Col>
          <Col xs={2} className="d-flex justify-content-end">
            <Button
              color="primary"
              size="sm"
              type="button"
              onClick={() => setModal(!modal)}
            >
              Edit
            </Button>
            <Button
              color="danger"
              outline
              type="button"
              size="sm"
              onClick={toggleDelete}
            >
              Delete
            </Button>
          </Col>
        </Row>
        <Collapse isOpen={modal} className="mt-3">
          <EditAddBlockForm
            modalValue={modal}
            setModalValue={(val) => setModal(val)}
            updateBlocks={updateBlocks}
            {...props}
            duration={30}
            sendNotification={sendNotification}
            {...readable} // we are fetching data of block in which we get readable url which contains url which we don't get in list of blocks api
          />
        </Collapse>
      </ListGroupItem>
    </>
  );
};
