import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ListGroupItem, ListGroup, Row, Col, Button } from "reactstrap";
import moment from "moment";
import { useHistory, useRouteMatch } from "react-router-dom";
import CustomSpinner from "../components/CustomSpinner";
import AddNewCourseForm from "./AddNewCourse/AddNewCourseForm";
import CustomReactSelect from "../components/CustomReactSelect";
import { fetchTeacherCourses } from "store/actions/enrollmentActions";
import { courseTypeOptions } from "../components/courseTypeOptions";
import { useEffect } from "react";
import ReactPaginate from "react-paginate";
import axios from "axios";

const ListCourses = ({ sendNotification, componentName }) => {
  const [pageData, setPageData] = useState();
  const history = useHistory();
  const dispatch = useDispatch();

  const teacherCourses = useSelector((state) =>
    Object.values(state.teacherCourses)
  );
  console.log("teacherCourses", teacherCourses);
  const match = useRouteMatch();

  const [select, setSelect] = useState(null);
  //pagination
  const [pageSize, setPageSize] = useState();
  const [data, setData] = useState();
  const [currentItem, setCurrentItem] = useState();
  const [pageCount, setPageCount] = useState(0);
  const [item, setItem] = useState(0);
  const itemsPerPage = 10;
  useEffect(() => {
    const end = item + itemsPerPage;
    setCurrentItem(data?.slice(item, end));
    setPageCount(Math.ceil(data?.length / itemsPerPage));
  }, [item, itemsPerPage, data]);

  const handlePageClick = (e) => {
    const newItem = (e.selected * itemsPerPage) % data.length;
    setItem(newItem);
  };
  useEffect(() => {
    axios.get(`/courses/?page_size=${pageSize}`).then((res) => {
      setData(res?.data?.results);
      setPageData(res?.data?.count);
    });
  }, [pageSize]);
  const renderList = () => {
    return (
      teacherCourses &&
      currentItem?.map(({ name, start_time, teachers, id, preview_image }) => (
        <ListGroupItem
          className="list-group-item-action"
          onClick={() => history.push(`${match.url}/#/${id}`)}
          tag="a"
          key={id}
        >
          <div className="d-flex">
            <img
              src={preview_image}
              alt={name}
              // className="mw-100 mh-100 m-auto"
              className="avatar bg-transparent"
            />
            <Col>
              <Row>
                <Col xs={12}>
                  <span className="text-primary h2 cursor-pointer">{name}</span>
                </Col>
                <Col xs={12}>
                  <Row>
                    <Col xs={12} md={8}>
                      {teachers &&
                        teachers
                          .map((singleTeacher, i) =>
                            i > 1
                              ? `${singleTeacher.name},`
                              : singleTeacher.name
                          )
                          .toString()}
                    </Col>
                    <Col
                      xs={12}
                      md={4}
                      className="d-flex justify-content-end justify-content-xs-start"
                    >
                      {moment(start_time).format("Do MMMM YYYY, h:mm A")}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </div>
        </ListGroupItem>
      ))
    );
  };

  const teacherCoursesLoading = useSelector(
    (state) => state.isLoading.teacherCoursesLoading
  );
  const [showForm, setShowForm] = useState(false);
  const toggle = () => setShowForm(!showForm);

  useEffect(() => {
    select && dispatch(fetchTeacherCourses({ ct: select }));
    // return () => {
    //   dispatch(fetchTeacherCourses());
    // };
  }, [select, dispatch]);

  return (
    <>
      <Button
        block
        color="primary"
        onClick={toggle}
        size="lg"
        type="button"
        outline={showForm}
        className="mb-3"
      >
        {showForm ? `See All ${componentName}s` : `Add New ${componentName}`}
      </Button>

      {!showForm && (
        <CustomReactSelect
          dropDownArray={[
            { id: null, text: "All Categories" },
            ...courseTypeOptions.map((item) => ({
              id: item.id,
              text: item.display_name,
            })),
          ]}
          select={select}
          handleOnChange={(id) => setSelect(id)}
          placeholder={"Filter by type of course"}
        />
      )}

      {teacherCoursesLoading ? (
        <CustomSpinner className="d-flex justify-content-center w-100 h-50 my-5 ml-5" />
      ) : (
        <>
          {showForm ? (
            <AddNewCourseForm
              cancel={toggle}
              sendNotification={sendNotification}
              componentName={componentName}
            />
          ) : (
            <>
              <ListGroup>{renderList()}</ListGroup>
              <div style={{ marginTop: "10px" }}>
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=">"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={3}
                  pageCount={pageCount}
                  previousLabel="<"
                  renderOnZeroPageCount={null}
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  containerClassName="pagination"
                  activeClassName="acitve"
                />
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default ListCourses;
