import Axios from "axios";
import { BASE_URL } from "./constants";
export function saveAuthInfo(authInfo) {
	return localStorage.setItem(
		"auth",
		JSON.stringify({
			...authInfo,
		})
	);
}

export function getAuthInfo() {
	const authInfo = localStorage.getItem("auth");
	return authInfo ? JSON.parse(authInfo) : authInfo;
}

export function clearAuthInfo() {
	localStorage.clear();
	// sessionStorage.clear();
}

export function refreshToken() {
	Axios({
		method: "post",
		url: `${BASE_URL}/account/token/refresh/`,
		data: {
			refresh: getAuthInfo().refresh_token,
		},
	})
		.then((response) => {
			localStorage.removeItem("refresh_token");
			// sessionStorage.removeItem("refreshing_token");
			const authInfo = {
				...getAuthInfo(),
				access_token: response.data.data.access_token,
				refresh_token: response.data.data.refresh_token,
			};
			saveAuthInfo(authInfo);
		})
		.catch((e) => {
			console.warn("> Error Refreshing Token");
		});
}

// export function refreshSession() {
// 	Axios({
// 		method: "post",
// 		url: `${process.env.REACT_APP_API_ENDPOINT}${urls.authentication.refreshSession}`,
// 	}).then((response) => {
// 		// eslint-disable-next-line no-console
// 		console.log("Start session", response);
// 	});
// }
