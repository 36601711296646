import React, { useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import Button from "reactstrap/lib/Button";
import ListGroup from "reactstrap/lib/ListGroup";
import { addNewQuestion } from "store/actions/questionActions";
import { emptyAllQuestions } from "store/actions/questionActions";
import { fetchAllQuestions } from "store/actions/questionActions";
import SingleQuestion from "./SingleQuestion/SingleQuestion";

const ListQuestions = ({ testId }) => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchAllQuestions(testId));

		return () => dispatch(emptyAllQuestions());
	}, [testId, dispatch]);

	const questions = useSelector((state) => Object.values(state.questions));

	const renderQuestions = () => {
		return (
			questions &&
			questions.map((question, i) => (
				<SingleQuestion
					{...question}
					index={i}
					key={question.id}
					testId={testId}
				/>
			))
		);
	};

	const handleAddNewQuestion = () => dispatch(addNewQuestion({ testId }));

	return (
		<ListGroup>
			{renderQuestions()}
			<Button
				block
				color="primary"
				className="mt-3"
				size="lg"
				onClick={handleAddNewQuestion}
			>
				Add New Question
			</Button>
		</ListGroup>
	);
};

export default ListQuestions;
