import { FETCH_SUBJECTS_SUCCESS } from "../constants/subjectConstants";
import keyBy from "lodash/keyBy";
import { USER_LOGOUT } from "store/constants/authConstants";

const initialState = {};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case FETCH_SUBJECTS_SUCCESS:
			return keyBy(payload, "id");
		case USER_LOGOUT:
			return initialState;
		default:
			return state;
	}
};
