import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { Button, Modal, ModalHeader } from "reactstrap";
import FormikControl from "views/pages/components/Formik/FormikControl";
import Axios from "axios";
import { useSelector } from "react-redux";
import cx from "classnames";
import { categoryOptions } from "shared/constants";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import { isFreeOptions } from "shared/constants";
import urls from "shared/urls";

const Courses = ({
	modalValue = false,
	setModalValue,
	sendNotification,
	updateReadAbles,
	addReadAble,
	scrollIntoView,
	...rest
}) => {
	const subjects = useSelector((state) => Object.values(state.subjects));
	const teachers = useSelector((state) => Object.values(state.teachers));

	const initialValues = {
		name: "",
		description: "",
		preview_image: "",
		readable_category: "notes",
		file: "",
		price: "",
		subject: subjects[0]?.id ?? "",
		teachers: teachers[0]?.id ?? "",
		is_free: true,
		...rest,
	};

	const onSubmit = async (values) => {
		const formData = new FormData();
		formData.append("name", values.name);
		formData.append("description", values.description);
		if (values.preview_image !== initialValues.preview_image) {
			formData.append("preview_image", values.preview_image);
		}
		formData.append("readable_category", values.readable_category);
		if (values.file !== initialValues.file) {
			formData.append("file", values.file);
		}
		formData.append("subject", values.subject);
		formData.append("teachers", values.teachers);
		formData.append("is_free", JSON.parse(values.is_free));
		if (!JSON.parse(values.is_free)) {
			formData.append("price", values.price);
		}

		try {
			const response = addReadAble
				? await Axios.post(urls.readAbles.data, formData)
				: await Axios.put(urls.readAbles.singleReadable(rest.id), formData);
			if (addReadAble) {
				scrollIntoView();
			}
			updateReadAbles(response.data, addReadAble);
			toggle();
			sendNotification(
				"success",
				addReadAble ? "Readable added" : "Readable Edited"
			);
		} catch (error) {
			console.error(error);
			sendNotification();
		}
	};

	const toggle = () => setModalValue(!modalValue);

	return (
		<Modal
			isOpen={modalValue}
			toggle={toggle}
			className={cx("modal-dialog-centered", {
				"custom-dialog-for-adding-readable": true,
			})}
			contentClassName="full-size-modal"
		>
			<ModalHeader toggle={toggle}>
				{addReadAble ? "Add Readable" : `Edit ${rest.name}`}
			</ModalHeader>
			<div
				className="modal-body pt-0"
				style={{ maxHeight: "calc(100vh - 210px)", overflowY: "auto" }}
			>
				<Formik
					initialValues={initialValues}
					onSubmit={onSubmit}
					enableReinitialize
				>
					{(formik) => (
						<Form>
							<FormikControl
								control="input"
								type="text"
								label="Readable Name"
								name="name"
								placeholder="Readable Name"
							/>
							<FormikControl
								control="input"
								type="text"
								label="Readable Description"
								name="description"
								placeholder="Readable Description"
							/>

							<FormikControl control="file" name="file" label="Readable file" />
							{!addReadAble && (
								<div className="form-group">
									<a href={rest.file} rel="noopener noreferrer" target="_blank">
										<span className="btn-inner--icon">
											<i className="fas fa-book mr-2" />
										</span>
										Uploaded Readable
									</a>
								</div>
							)}

							<Row>
								{formik.values.preview_image && (
									<img
										src={
											typeof formik.values.preview_image !== "string"
												? URL.createObjectURL(formik.values.preview_image)
												: rest.preview_image ?? undefined
										}
										className="avatar avatar-xl bg-transparent mr-3 ml-3"
										alt="course_image"
									/>
								)}
								<Col>
									<FormikControl
										control="file"
										name="preview_image"
										label="Add New Thumbnail"
									/>
								</Col>
							</Row>

							<FormikControl
								control="radio"
								label={"Category"}
								name="readable_category"
								options={categoryOptions}
							/>

							<FormikControl
								control="radio"
								label={"Subjects"}
								name="subject"
								options={subjects}
							/>
							<FormikControl
								control="radio"
								label="Teachers"
								name="teachers"
								options={teachers}
								disabled
							/>
							<FormikControl
								control="radio"
								label={"Type"}
								name="is_free"
								options={isFreeOptions}
							/>
							<FormikControl
								control="input"
								type="number"
								label="Price"
								name="price"
								placeholder="Price of Readable"
								disabled={JSON.parse(formik.values.is_free)}
							/>
							<div className="modal-footer">
								<Button
									color="primary"
									type="submit"
									disabled={!formik.dirty || formik.isSubmitting}
								>
									{addReadAble ? "Add Readable" : "Edit Readable"}
								</Button>
								<Button
									className="ml-auto "
									color="outline-primary"
									data-dismiss="modal"
									type="button"
									onClick={() => toggle()}
								>
									Close
								</Button>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</Modal>
	);
};

export default Courses;
