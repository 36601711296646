import React, { useEffect } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import Axios from "axios";
import AdminLayout from "layouts/Admin.js";
import RTLLayout from "layouts/RTL.js";
import AuthLayout from "layouts/Auth.js";
import IndexView from "views/Index.js";
import history from "store/history";
import { useDispatch, useSelector } from "react-redux";
import { fetchTeacherCourses } from "store/actions/enrollmentActions";
import { clearAuthInfo, getAuthInfo, saveAuthInfo } from "shared/helpers";
import { BASE_URL } from "./shared/constants";
import urls from "shared/urls";

const App = (props) => {
	// console.log(props);
	const instituteId = useSelector((state) => state.instituteId);
	Axios.defaults.baseURL = `${BASE_URL}/institutes/${instituteId}`;

	Axios.interceptors.request.use((request) => {
		const authInfo = getAuthInfo();
		if (authInfo) {
			request.headers["Authorization"] = `Bearer ${authInfo.access_token}`;
			// request.headers["Content-Type"] = "application/json";
		}

		return request;
	});

	let tokenIsRefreshing = false;

	const interceptor = (error) => {
		if (error.response) {
			if (error.response.status === 500) {
				// alert("Server error");
				return;
			}
			if (error.response.status === 401) {
				if (tokenIsRefreshing) {
					return Promise.reject(error);
				}

				tokenIsRefreshing = true;
				refreshToken();


				// Reject the current request while the token refreshes
				return Promise.reject(error);
			} else {
				return Promise.reject(error);
			}
		}
		return Promise.reject(error);
	};

	// Reference: https://gist.github.com/Godofbrowser/bf118322301af3fc334437c683887c5f
	function refreshToken() {
		Axios({
			method: "post",
			url: `${BASE_URL}${urls.auth.refresh}`,
			data: {
				refresh: getAuthInfo()?.refresh_token,
			},
		})
			.then((response) => {
				const authInfo = {
					...getAuthInfo(),
					access_token: response.data.access,
					refresh_token: null,
				};
				saveAuthInfo(authInfo);

				// If refresh token call succeeds then resolve all the calls in the queue with the new token
				// processQueue(null, response.data.data.access_token)
				// resolve(axios(originalRequest))
				window.location.reload();
			})
			.catch((error) => {
				// alert(error);
				// if (error.response) {
				// if (error.response.status === 404) {
				// eslint-disable-next-line no-console
				console.log("> Refresh token expired. Logging out.");
				clearAuthInfo();
				history.push("/app/login");
				// }
				// }
				// If refresh token call fails then reject all the calls in the queue
				// processQueue(err)
				// reject(err)
			})
			.finally(() => {
				tokenIsRefreshing = false;
			});
	}

	Axios.interceptors.response.use(undefined, interceptor);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchTeacherCourses());
	}, [dispatch]);

	useEffect(() => {
		var inputTypeNumbers = document.querySelectorAll("input[type=number]");
		for (var a = 0; a < inputTypeNumbers.length; a++) {
			inputTypeNumbers[a].onwheel = function (event) {
				// console.log("ayush");
				event.target.blur();
			};
		}
	}, []);

	return (
		<Router history={history}>
			<Switch>
				<Route path="/admin" render={(props) => <AdminLayout {...props} />} />
				<Route path="/rtl" render={(props) => <RTLLayout {...props} />} />
				<Route path="/auth" render={(props) => <AuthLayout {...props} />} />
				<Route path="/" render={(props) => <IndexView {...props} />} />
				<Redirect from="*" to="/admin/courses" />
			</Switch>
		</Router>
	);
};

export default App;
