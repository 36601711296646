import React, { useState, useRef, useEffect, useCallback } from "react";
import ReactQuill, { Quill } from "react-quill";

import mathquill4quill from "mathquill4quill";
import debounce from "lodash/debounce";

// import "mathquill4quill/build/mathquill4quill.min.css";
import "./quillCustom.css";
import "mathquill4quill/build/mathquill4quill.min.js";
import "katex/dist/katex.min.css";
import "katex/dist/katex.min.js";
import { useDispatch } from "react-redux";
import katex from "katex";
import { editQuestion } from "store/actions/questionActions";
import { useIsMount } from "views/pages/components/hooks/useIsMount";
// import debounce from "lodash/debounce"

window.katex = katex;
const QuestionBlock = ({ question = "", id, question_type }) => {
  const [reactQuillText, setReactQuillText] = useState(question);
  const reactQuill = useRef();
  const dispatch = useDispatch();
  useEffect(() => {
    const enableMathQuillFormulaAuthoring = mathquill4quill({
      Quill,
      focus: true,
    });
    enableMathQuillFormulaAuthoring(reactQuill.current.editor, {
      operators: [
        ["\\pm", "\\pm"],
        ["\\sqrt{x}", "\\sqrt"],
        ["\\sqrt[n]{x}", "\\nthroot"],
        ["\\frac{x}{y}", "\\frac"],
        ["\\sum^{s}_{x}{d}", "\\sum"],
        ["\\prod^{s}_{x}{d}", "\\prod"],
        ["\\coprod^{s}_{x}{d}", "\\coprod"],
        ["\\int^{s}_{x}{d}", "\\int"],
        ["\\binom{n}{k}", "\\binom"],
        ["\\log{x}", "\\log"],
      ],

      displayHistory: true, // defaults to false
      historyCacheKey: "__my_app_math_history_cachekey_", // optional
      historySize: 20, // optional (defaults to 10)
    });
  }, []);
  const _isMount = useIsMount();

  const dispatchRequest = (value) => {
    dispatch(editQuestion({ id, question_text: value }));
  };

  const debounceQuery = useCallback(
    debounce((q) => dispatchRequest(q), 1000),
    []
  );

  const handleReactQuillChange = (value) => {
    setReactQuillText(value);
    debounceQuery(value);
  };

  const getClassNameBasedOnQuestionType = () => {
    // to customize the height of the question or react quill
    switch (question_type) {
      case "1":
        return "mcq-block-options";
      // case "2":
      // 	return null;
      case "3":
        return "true-false-block-options";
      case "4":
        return "";
      case "5":
        return "";
      case "6":
        return "subjective-block-options";
      default:
        return "question-block-options";
    }
  };

  return (
    <>
      <ReactQuill
        value={reactQuillText || ""}
        onChange={handleReactQuillChange}
        theme="snow"
        ref={reactQuill}
        placeholder="Type your question here..."
        className={getClassNameBasedOnQuestionType()}
        modules={{
          formula: true,
          toolbar: [
            // ["formula", "bold", "italic"],
            ["formula", "image"],
            // [
            // 	{
            // 		list: "ordered",
            // 	},
            // 	{
            // 		list: "bullet",
            // 	},
            // ],
          ],
        }}
        formats={["formula", "image"]}
      />
    </>
  );
};

export default QuestionBlock;
