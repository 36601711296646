import React from "react";
import { useHistory } from "react-router";

import Login from "views/pages/Auth/Login/Login";
const Index = (props) => {
	const history = useHistory();

	history.push("/auth/login");

	return <Login {...props} />;
};

export default Index;
