export const ONGOING_EVENTS_REQUESTED = "ONGOING_EVENTS_REQUESTED";
export const ONGOING_EVENTS_SUCCESS = "ONGOING_EVENTS_SUCCESS";
export const ONGOING_EVENTS_FAILED = "ONGOING_EVENTS_FAILED";
export const ONGOING_EVENT_ADDED = "ONGOING_EVENT_ADDED";

export const ONGOING_EVENT_REMOVED_REQUESTED =
	"ONGOING_EVENT_REMOVED_REQUESTED";
export const ONGOING_EVENT_REMOVED = "ONGOING_EVENT_REMOVED";

export const UPCOMING_EVENTS_REQUESTED = "UPCOMING_EVENTS_REQUESTED";
export const UPCOMING_EVENTS_SUCCESS = "UPCOMING_EVENTS_SUCCESS";
export const UPCOMING_EVENTS_FAILED = "UPCOMING_EVENTS_FAILED";

export const UPCOMING_EVENT_REMOVED_REQUESTED =
	"UPCOMING_EVENT_REMOVED_REQUESTED";
export const UPCOMING_EVENT_REMOVED = "UPCOMING_EVENT_REMOVED";
