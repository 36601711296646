import React, { useState } from "react";
import ReadablesForm from "./ReadablesForm";
import { ListGroupItem, Row, Col, Button } from "reactstrap";
import Axios from "axios";
import urls from "shared/urls";
import omit from "lodash/omit";
import DeleteModal from "views/pages/components/DeleteModal";

const SingleReadable = (props) => {
	const [modal, setModal] = useState(false);
	const [modalDelete, setModalDelete] = useState(false);

	const { name, id, setReadAbles, sendNotification } = props;
	const deleteReadable = async () => {
		try {
			const response = await Axios.delete(urls.readAbles.singleReadable(id));
			setReadAbles((prevState) => omit(prevState, id));
			sendNotification("success", response.data?.detail ?? "success");
		} catch (error) {
			console.error(error);
			sendNotification();
		}
	};

	return (
		<>
			<ReadablesForm
				modalValue={modal}
				setModalValue={(val) => setModal(val)}
				{...props}
				readableId={id}
			/>
			<DeleteModal
				isOpen={modalDelete}
				toggleDelete={() => setModalDelete(!modalDelete)}
				name={name}
				handleDelete={deleteReadable}
			/>
			<ListGroupItem key={id}>
				<Row className="align-items-center">
					<Col>
						<span className="mb-0 text-primary pl-2">{name}</span>
					</Col>
					<Col xs={2} className="d-flex justify-content-end">
						<Button
							color="primary"
							size="sm"
							type="button"
							onClick={() => setModal(true)}
						>
							Edit
						</Button>
						<Button
							color="danger"
							size="sm"
							type="button"
							outline
							onClick={() => setModalDelete(!modalDelete)}
						>
							Delete
						</Button>
					</Col>
				</Row>
			</ListGroupItem>
		</>
	);
};

export default SingleReadable;
