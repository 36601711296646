import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import * as yup from "yup";
import FormikControl from "../components/Formik/FormikControl";
import { Button } from "reactstrap";
import { addBook } from "store/actions/addBookAction";
import { useDispatch } from "react-redux";
import axios from "shared/Axios";
import { updateBook } from "store/actions/addBookAction";

const urlRegex =
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;

const validationSchema = yup.object().shape({
    title: yup.string().required("Required"),
    content: yup.string().required("Required"),
    url: yup.string().required("Required").matches(urlRegex, "Enter valid url"),
});


const EditRecommendedBook = ({ setShowRecommendedList, id }) => {
    const [editData, setEditData] = useState();
    const dispatch = useDispatch();
    useEffect(() => {
        axios.get(`/recommended-books/${id}/`).then(res => {
            console.log("ressss", res.data)
            setEditData({ title: res.data.title, content: res.data.content, url: res.data.url });
        })
    }, [])
    const cancelClickHandler = () => {
        setShowRecommendedList(true);
    };

    console.log("editdata", editData)
    let initialValues = {
        title: editData?.title,
        content: editData?.content,
        url: editData?.url
    };
    const onSubmit = (values) => {
        console.log(values);
        updateBook(values, id, () => {
            setShowRecommendedList(true);
        })(dispatch);
    };
    return (
        <>
            <div className="col-lg-9">
                {editData ? <Formik
                    initialValues={editData}
                    onSubmit={onSubmit}
                    validationSchema={validationSchema}
                >
                    {(formik) => {
                        console.log(formik)
                        return (
                            <Form>
                                <FormikControl
                                    control="input"
                                    type="text"
                                    label={"Title"}
                                    name="title"
                                    defaultValue={editData?.title}
                                    placeholder={"Add title"}
                                />
                                <FormikControl
                                    control="input"
                                    type="textarea"
                                    rows="6"
                                    label={"Content"}
                                    placeholder={"Add content"}
                                    defaultValue={editData?.content}
                                    name="content"
                                />
                                <FormikControl
                                    control="input"
                                    type="text"
                                    label={"URL"}
                                    name="url"
                                    placeholder={"Add url"}
                                    defaultValue={editData?.url}
                                />
                                <Button
                                    type="submit"
                                    className="w-50"
                                    color="primary"
                                    disabled={formik.isSubmitting || !formik.dirty}
                                >
                                    Submit
                                </Button>
                                <Button
                                    color="secondary"
                                    className="float-right"
                                    onClick={() => setShowRecommendedList(true)}
                                >
                                    Cancel
                                </Button>
                            </Form>
                        );
                    }}
                </Formik> : null}

            </div>
        </>
    );
};

export default EditRecommendedBook;
