import React, { useState } from "react";
import classnames from "classnames";

// reactstrap components
import {
	Card,
	CardBody,
	NavItem,
	NavLink,
	Nav,
	TabContent,
	TabPane,
} from "reactstrap";
// import Assignments from "./SingleTest/Assignments/Assignments";
import CoursesTest from "./SingleTest/EditCourse/CoursesTest";
import Chapters from "./SingleTest/Chapters/Chapters";
import useQuery from "hooks/useQuery";
import { useEffect } from "react";

const Navs = (props) => {
	const [tabs, setTabs] = useState(1);

	const toggleNavs = (e, index) => {
		e.preventDefault();
		setTabs(index);
	};

	const query = useQuery();

	useEffect(() => {
		if (query.get("edit-data")) {
			setTabs(2);
		}
	}, [query]);

	return (
		<>
			<div className="nav-wrapper">
				<Nav
					className="nav-fill flex-column flex-md-row"
					id="tabs-icons-text"
					pills
					role="tablist"
				>
					<NavItem>
						<NavLink
							aria-selected={tabs === 1}
							className={classnames("mb-sm-3 mb-md-0", {
								active: tabs === 1,
							})}
							onClick={(e) => toggleNavs(e, 1)}
							href="#chapters"
							role="tab"
						>
							<i className="fas fa-book-open mr-2" />
							Chapters
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink
							aria-selected={tabs === 2}
							className={classnames("mb-sm-3 mb-md-0", {
								active: tabs === 2,
							})}
							onClick={(e) => toggleNavs(e, 2)}
							href="#courses"
							role="tab"
						>
							<i className="fas fa-cube mr-2" />
							Course
						</NavLink>
					</NavItem>
					{/* <NavItem>
						<NavLink
							aria-selected={tabs === 3}
							className={classnames("mb-sm-3 mb-md-0", {
								active: tabs === 3,
							})}
							onClick={(e) => toggleNavs(e, 3)}
							href="#assignments"
							role="tab"
						>
							<i className="fas fa-stream mr-2" />
							Assignments
						</NavLink>
					</NavItem> */}
				</Nav>
			</div>
			<Card className="shadow">
				<CardBody>
					<TabContent activeTab={"tabs" + tabs}>
						<TabPane tabId="tabs1">
							<Chapters {...props} />
						</TabPane>
						<TabPane tabId="tabs2">
							{tabs === 2 && <CoursesTest {...props} />}
						</TabPane>
						{/* <TabPane tabId="tabs3">
							<Assignments {...props} />
						</TabPane> */}
					</TabContent>
				</CardBody>
			</Card>
		</>
	);
};

export default Navs;
