import { INSTITUTE_ID } from "shared/constants";
import { USER_LOGOUT } from "store/constants/authConstants";

export default (state = INSTITUTE_ID, { type, payload }) => {
	switch (type) {
		case "INSTITUTE_ID":
			return payload;
		case USER_LOGOUT:
			return null;
		default:
			return state;
	}
};
