import { Form, Formik } from "formik";
import { Button } from "reactstrap";
import React from "react";
import FormikControl from "views/pages/components/Formik/FormikControl";

import { useDispatch, useSelector } from "react-redux";
import { editOnGoingBlock } from "store/actions/liveEventActions";
import { useHistory } from "react-router";
import { liveBlockOptions } from "views/pages/components/blockTypeOptions";
const OnGoingEventForm = (props) => {
	const history = useHistory();
	const { blockId } = props;
	const formData = useSelector((state) => state.onGoingEvents[blockId]);

	const dispatch = useDispatch();
	const onSubmit = (values) => {
		dispatch(
			editOnGoingBlock({
				params: {
					op: "end",
				},
				blockId,
				...values,
			})
		);
	};

	return (
		<>
			<h2 className="text-primary mb-3">
				<i
					className="fas fa-chevron-circle-left mr-3 cursor-pointer"
					onClick={() => history.push("/admin/live-events/#/o")}
					style={{ cursor: "pointer !important" }}
				/>
				{formData && formData.title}
			</h2>
			<Formik
				initialValues={{
					title: "",
					url: "",
					block_type: "live_session",
					...formData,
					start_time: formData && new Date(formData.start_time),
				}}
				onSubmit={onSubmit}
				enableReinitialize
			>
				{(formik) => (
					<Form>
						<FormikControl
							control="input"
							type="text"
							label="Block Topic"
							name="title"
							placeholder="block topic"
							disabled={true}
						/>

						<FormikControl
							control="radio"
							label={"Block Type"}
							name="block_type"
							options={liveBlockOptions}
							disabled={true}
						/>

						{/* <FormGroup className="mb-0">
							<label>Video URL</label>
							<div className="custom-file">
								<input
									type="file"
									className="custom-file-input"
									onChange={(e) =>
										uploadFileToFireBase(e, formik.setFieldValue)
									}
									disabled={disableFileInput}
									disabled={true}
								/>
								<label className="custom-file-label">Browse</label>
							</div>
						</FormGroup> */}
						<FormikControl
							control="input"
							type="text"
							label={"URL"}
							name="url"
							disabled={true}
							placeholder="Paste url"
						/>

						<FormikControl
							control="date"
							label="Start Date and Time"
							name="start_time"
							disabled={true}
						/>

						<Button
							type="submit"
							className="w-50 text-uppercase"
							color="primary"
						>
							end
						</Button>
					</Form>
				)}
			</Formik>
		</>
	);
};

export default OnGoingEventForm;
