import React from "react";
import ListOnGoingEvent from "./ListOnGoingEvent";
import OnGoingEventForm from "./OnGoingEventForm";

const OnGoingEvents = (props) => {
	const event = props.location.hash.split("/")[1];
	const institute = props.location.hash.split("/")[2];
	const blockId = props.location.hash.split("/")[3];
	const { sendNotification } = props;

	const handleRouting = () => {
		if (event && institute && blockId) {
			return (
				<OnGoingEventForm
					blockId={blockId}
					institute={institute}
					sendNotification={sendNotification}
				/>
			);
		} else {
			return <ListOnGoingEvent sendNotification={sendNotification} />;
		}
	};

	return handleRouting();
};

export default OnGoingEvents;
