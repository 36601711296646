import React, { useState, useRef, useEffect, useCallback } from "react";
import ReactQuill, { Quill } from "react-quill";
import debounce from "lodash/debounce";

import mathquill4quill from "mathquill4quill";

// import "mathquill4quill/build/mathquill4quill.min.css";
import "./quillCustom.css";
import "mathquill4quill/build/mathquill4quill.min.js";
import "katex/dist/katex.min.css";
import "katex/dist/katex.min.js";
import katex from "katex";

import { useDispatch } from "react-redux";

import { editQuestionOption } from "store/actions/questionActions";
window.katex = katex;

const AnswerBlock = ({
  option,
  isCorrect,
  optionNumber,
  questionId,
  choiceId,
  checkboxHidden,
  readOnly, //read only is required only for True and False
}) => {
  const [reactQuillText, setReactQuillText] = useState(option);
  const dispatch = useDispatch();
  const reactQuill = useRef();

  useEffect(() => {
    const enableMathQuillFormulaAuthoring = mathquill4quill({
      Quill,
      focus: true,
    });
    enableMathQuillFormulaAuthoring(reactQuill.current.editor, {
      operators: [
        ["\\pm", "\\pm"],
        ["\\sqrt{x}", "\\sqrt"],
        ["\\sqrt[n]{x}", "\\nthroot"],
        ["\\frac{x}{y}", "\\frac"],
        ["\\sum^{s}_{x}{d}", "\\sum"],
        ["\\prod^{s}_{x}{d}", "\\prod"],
        ["\\coprod^{s}_{x}{d}", "\\coprod"],
        ["\\int^{s}_{x}{d}", "\\int"],
        ["\\binom{n}{k}", "\\binom"],
        ["\\log{x}", "\\log"],
      ],

      displayHistory: true, // defaults to false
      historyCacheKey: "__my_app_math_history_cachekey_", // optional
      historySize: 20, // optional (defaults to 10)
    });
  }, []);

  const dispatchRequest = (value) => {
    dispatch(
      editQuestionOption({
        questionId,
        choiceId,
        choice_text: value,
        is_answer: isCorrect,
      })
    );
  };

  const debounceQuery = useCallback(
    debounce((q) => dispatchRequest(q), 1000),
    []
  );

  const handleReactQuillChange = (value) => {
    setReactQuillText(value);
    debounceQuery(value);
  };

  const handleCheckBoxChange = (value) => {
    dispatch(editQuestionOption({ questionId, choiceId, is_answer: value }));
  };
  // console.log({ isCorrect, choiceId });
  return (
    <div className="d-flex">
      <input
        type="checkbox"
        className="ml-1 mr-3 my-auto"
        checked={isCorrect}
        onChange={(e) => handleCheckBoxChange(e.target.checked)}
        style={{ display: checkboxHidden && "none", transform: "scale(1.8)" }}
      />

      <ReactQuill
        value={reactQuillText || ""}
        onChange={handleReactQuillChange}
        placeholder={
          optionNumber.split("_")[0] + " " + optionNumber.split("_")[1]
        }
        readOnly={readOnly}
        theme="snow"
        ref={reactQuill}
        className="answer-block-options"
        modules={{
          formula: true,
          toolbar: {
            container: [["formula", "image"]],
          },
        }}
        formats={["formula", "image"]}
      />
    </div>
  );
};

export default AnswerBlock;
