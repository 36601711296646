import React, { createRef, useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  CardBody,
  Card,
  ListGroupItem,
  Collapse,
  Button,
} from "reactstrap";

import OptionsBlock from "./OptionsBlock";
import "./question.css";
import Mcq from "./QuestionTypes/Mcq";
import Smcq from "./QuestionTypes/Smcq";

// import Numeric from "./QuestionTypes/Numeric";
import TrueFalse from "./QuestionTypes/TrueFalse";
import Subjective from "./QuestionTypes/Subjective";
import FillAndNumeric from "./QuestionTypes/FillAndNumeric";
import { useIsMount } from "views/pages/components/hooks/useIsMount";
const SingleQuestion = (props) => {
  //question block
  const {
    question_text,
    index,
    question_type,
    isOpen: isNewBlockOpen,
    questionState,
  } = props;

  console.log(props);

  //all the logic for collapse
  const [isOpen, setIsOpen] = useState(isNewBlockOpen);
  const toggle = () => setIsOpen(!isOpen);
  const _isMounted = useIsMount();
  const questionRef = createRef();
  useEffect(() => {
    if (isNewBlockOpen && questionRef && _isMounted) {
      questionRef.current.scrollIntoView();
    }
  }, [questionRef]);

  const renderQuestionFromQuestionType = () => {
    switch (question_type) {
      case "mmcq":
        return <Mcq {...props} />;
      case "smcq":
        return <Smcq {...props} />;
      case "integer":
      case "fill_in_blank":
        return <FillAndNumeric {...props} />;
      case "true_false":
        return <TrueFalse {...props} />;
      case "subjective":
        return <FillAndNumeric {...props} />;
      default:
        break;
    }
  };

  return (
    <div ref={questionRef}>
      <Col md={12} className="mx-0 px-0">
        <ListGroupItem className={`p-0 pt-3 bg-white ${!isOpen && "pb-3"}`}>
          <Row>
            <Col xs={1} className="display-6 text-center ml-2">
              Q.{index + 1}
            </Col>
            <Col>
              <div
                dangerouslySetInnerHTML={{ __html: question_text }}
                className="text-left m-0 p-0 m-auto list-group-item-question"
              />
            </Col>

            <Col xs={3}>
              <div className="d-flex justify-content-center">
                <div className="mr-3">
                  <div
                    className={[
                      questionState == "Saved" || questionState == undefined
                        ? "text-success"
                        : questionState == "Saving"
                        ? "text-warning"
                        : "text-danger",
                      "font-weight-bold",
                    ].join(" ")}
                  >
                    {questionState != undefined ? questionState : "Saved"}
                  </div>
                </div>

                <Button onClick={toggle} size="sm" className="border-0">
                  <i
                    className={
                      isOpen ? "fas fa-chevron-up" : "fas fa-chevron-down"
                    }
                  />
                </Button>
              </div>
            </Col>
          </Row>

          <Collapse isOpen={isOpen} className="mt-2">
            <Card className="py-0 my-0 shadow-none">
              <CardBody className="">
                <OptionsBlock {...props} />

                {renderQuestionFromQuestionType()}
              </CardBody>
            </Card>
          </Collapse>
        </ListGroupItem>
      </Col>
    </div>
  );
};

export default SingleQuestion;
