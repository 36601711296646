// Don't forget the ending slash "/"
export default {
  auth: {
    login: "/account/teacher_login/",
    refresh: "/account/token/refresh/",
  },

  course: {
    data: `/courses/`,
    singleCourse: (courseId) => `/courses/${courseId}/`,
    chapters: (courseId) => `/courses/${courseId}/chapters/`,
    block: (courseId, chapterId) =>
      `/courses/${courseId}/chapters/${chapterId}/`,
    blocks: (courseId, chapterId) =>
      `/courses/${courseId}/chapters/${chapterId}/blocks/`,
    singleBlock: (courseId, chapterId, blockId) =>
      `/courses/${courseId}/chapters/${chapterId}/blocks/${blockId}/`,
  },

  discussion: {
    list: `/posts/`,
    singlePost: (postId) => `/posts/${postId}/`,
    postComments: (postId) => `/posts/${postId}/comments`,
    deleteSingleComment: (commentId) => `/comments/${commentId}/del/`,
  },

  discountCodes: {
    list: "/discount_codes/",
    update: (courseId, planId) =>
      `/courses/${courseId}/plans/${planId}/discount_codes/`,
    singleCoupon: (discountId) => `/discount_codes/${discountId}/`,
  },
  enrollments: {
    all: "/download_user_data",
    list: `/enrollments/`,
    singleEnrollment: (singleEnrollmentId) =>
      `/enrollments/${singleEnrollmentId}/`,
    create: `/create_enrollment/`,
  },

  goals: {
    fetchGoals: `/goals/`,
  },

  liveEvents: {
    list: `/live_sessions/`,
    singleLiveSession: (liveSessionId) => `/live_sessions/${liveSessionId}/`,
  },
  notification: {
    all: `/send_notification/`,
    course: (courseId) => `/courses/${courseId}/send_notification/`,
  },

  plans: {
    data: (courseId) => `/courses/${courseId}/plans/`,
    update: (courseId, planId) => `/courses/${courseId}/plans/${planId}/`,
    discountCodes: (courseId, planId) =>
      `/courses/${courseId}/plans/${planId}/discount_codes`,
  },

  readAbles: {
    data: `/readables/`,
    singleReadable: (readAbleId) => `/readables/${readAbleId}/`,
  },

  subjects: {
    fetchSubjects: `/subjects/`,
  },

  student: {
    create: `/create_student/`,
  },

  teachers: {
    data: `/teachers/`,
  },

  test: {
    singleTest: (testId) => `/tests/${testId}/`,
    questions: (testId) => `/tests/${testId}/questions/`,
    question: (questionId) => `/questions/${questionId}/`,
    choice: (questionId, choiceId) =>
      `/questions/${questionId}/choices/${choiceId}/`,
  },
};
