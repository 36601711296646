import { getAuthInfo } from "./helpers";
// export const BASE_URL = "https://classcast-dev.el.r.appspot.com";
export const INSTITUTE_ID = getAuthInfo()?.user.institute_id || null; // (BCD )

//export const BASE_URL = "https://classcast-red.el.r.appspot.com";
// export const INSTITUTE_ID = "51e6c708-05f1-4a86-ada2-b5c1f0e8ae3b";

export const BASE_URL =
  window.location.hostname === "app.vaidsics.com"
    ? "https://api.vaidsics.com/api/v1"
    : "https://api-vaidsics.uatsparxit.xyz/api/v1";

// export const BASE_URL = "https://api.vaidsics.com/api/v1";

export const categoryOptions = [
  { id: "sample_papers", name: "Sample Papers" },
  { id: "mind_maps", name: "Mind Maps" },
  { id: "past_year_papers", name: "Past Year Papers" },
  { id: "notes", name: "Notes" },
  { id: "mock_test", name: "Mock Test" },
  { id: "textbooks", name: "Textbooks" },
  { id: "ebook_samples", name: "Ebook Samples" },
  { id: "notice", name: "Notice" },
  { id: "revision_notes", name: "Revision Notes" },
  { id: "solutions", name: "Solutions" },
];

export const isFreeOptions = [
  { id: true, name: "Free" },
  { id: false, name: "Paid" },
];

export const componentNames = {
  testSeries: "Test Series",
  course: "Course",
};

export const couponTypes = {
  percentage: "percentage",
  amount: "amount",
};
