import React, { useState, useEffect } from "react";
// import ReactSelect from "../components/SelectCourse";

import omit from "lodash/omit";
import keyBy from "lodash/keyBy";
import isEmpty from "lodash/isEmpty";
import { Col } from "reactstrap";
import SinglePost from "./SinglePost";
import Axios from "axios";
import urls from "shared/urls";
import CustomSpinner from "../components/CustomSpinner";
import AddNewPost from "./AddNewPost";
import Collapse from "reactstrap/lib/Collapse";
import Button from "reactstrap/lib/Button";

const Discussion = ({ sendNotification, location: { pathname } }) => {
	const [posts, setPosts] = useState(null);
	const [openNewPostForm, setOpenNewPostForm] = useState(false);

	const [componentName, setComponentName] = useState("");

	useEffect(() => {
		let name = pathname;
		name = pathname.split("/").pop().toLowerCase();
		setComponentName(name);
	}, [pathname]);

	const listAllPosts = async () => {
		try {
			const response = await Axios.get(urls.discussion.list, {
				params: {
					pt: componentName.includes("notice") ? "notice" : undefined,
				},
			});

			setPosts(keyBy(response.data.results, "id"));
		} catch (error) {
			console.error(error);
			setPosts({});
		}
	};

	const deletePost = async (postId) => {
		try {
			await Axios.delete(urls.discussion.singlePost(postId));
			setPosts((prevData) => omit(prevData, postId));
			sendNotification("success", "Deleted successfully");
		} catch (error) {
			console.error(error);
			sendNotification("danger", "Unsuccessful, try again");
		}
	};

	const updatePosts = async (data) => {
		setPosts((prevData) => ({ [data.id]: data, ...prevData }));
	};
	useEffect(() => {
		componentName && listAllPosts();
		// eslint-disable-next-line
	}, [componentName]);
	return (
		<Col lg={9}>
			{!openNewPostForm && (
				<Button
					block
					onClick={() => setOpenNewPostForm(!openNewPostForm)}
					color="primary"
					className="text-capitalize"
				>
					Add New {componentName}
				</Button>
			)}

			<Collapse isOpen={openNewPostForm}>
				<AddNewPost
					closeForm={() => setOpenNewPostForm(!openNewPostForm)}
					updatePosts={updatePosts}
					componentName={componentName}
				/>
			</Collapse>
			{posts === null ? (
				<CustomSpinner className="d-flex justify-content-center w-100" />
			) : isEmpty(posts) ? (
				<h2 className="mt-5 text-center">No posts</h2>
			) : (
				<>
					<div className="mt-5"></div>
					{Object.values(posts).map((post) => (
						<SinglePost
							{...post}
							key={post.id}
							deletePost={deletePost}
							componentName={componentName}
						/>
					))}
				</>
			)}
		</Col>
	);
};

export default Discussion;
