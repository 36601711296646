import React, { useRef } from "react";

import { Field, ErrorMessage } from "formik";
import TextError from "./TextError";
import moment from "moment";
import Datetime from "react-datetime";
import {
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";

function DatePicker(props) {
  const { label, name, timeFormat, disabled, ...rest } = props;
  const yesterday = moment().subtract(1, "day");
  const disPastDate = (current) => {
    return current.isAfter(yesterday);
  };

  return (
    <FormGroup>
      <label htmlFor={name}>{label}</label>
      <Field name={name}>
        {({ form, field }) => {
          const { setFieldValue } = form;
          const { value } = field;
          console.log("valueeeee========", value);
          return (
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-calendar-grid-58" />
                </InputGroupText>
              </InputGroupAddon>
              <Datetime
                id={name}
                // {...field}
                // {...rest}
                value={value}
                onChange={(val) => {
                  setFieldValue(name, val);
                  rest.getDate(val._d);
                  console.log("val", val._d);
                }}
                closeOnCLickOutside={true}
                isValidDate={disPastDate}
                inputProps={{
                  disabled,
                }}
                timeFormat={!timeFormat}
                closeOnSelect
                // dateFormat="lll"
                // onFocus={
                // calendar.current &&
                // calendar.current.scrollIntoView({ behavior: "smooth" })
                // }
              />
            </InputGroup>
          );
        }}
      </Field>
      <ErrorMessage component={TextError} name={name} />
    </FormGroup>
  );
}

export default DatePicker;
