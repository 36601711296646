import React, { useState, useEffect } from "react";
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import AuthHeader from "components/Headers/AuthHeader.js";

import history from "store/history";
// import axios from "axios";
import { getAuthInfo, saveAuthInfo } from "../../../../shared/helpers";
import urls from "shared/urls";

import Axios from "axios";
import { fetchTeacherCourses } from "store/actions/enrollmentActions";
import { useDispatch } from "react-redux";
import { setInstituteId } from "store/actions/instituteActions";
import { BASE_URL } from "shared/constants";

const axios = Axios.create({
  baseURL: BASE_URL,
});

const Login = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [focusedEmail, setFocusedEmail] = useState(null);
  const [focusedPassword, setFocusedPassword] = useState(null);
  const [error, setError] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (getAuthInfo()) {
      // console.log("login");
      history.push(
        props.location.state
          ? props.location.state.from.pathname
          : "/admin/courses"
      );
      // history.push("/admin/enrollments");
    }
    // eslint-disable-next-line
  }, []);

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(urls.auth.login, {
        email,
        password,
      });
      // console.log(response.data.user.institute_id);
      dispatch(setInstituteId(response.data.user.institute_id));
      saveAuthInfo(response.data);
      dispatch(fetchTeacherCourses());
      history.push("/admin/courses");
    } catch (error) {
      console.error(error);
      setError(error.response?.data?.error?.message);
    }
    // dispatch(signIn(email, password));
  };
  return (
    <>
      <AuthHeader
        title="Welcome!"
        // lead="Use these awesome forms to login or create new account in your project for free."
      />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary border-0 mb-0">
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4">
                  {error ? (
                    <small className="text-danger text-capitalize">
                      {error}
                    </small>
                  ) : (
                    <small>Sign in with credentials</small>
                  )}
                </div>
                <Form role="form" onSubmit={handleOnSubmit}>
                  <FormGroup
                    className={classnames("mb-3", {
                      focused: focusedEmail,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Email"
                        type="email"
                        value={email}
                        onFocus={() => setFocusedEmail(true)}
                        onBlur={() => setFocusedEmail(false)}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup
                    className={classnames({
                      focused: focusedPassword,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Password"
                        type="password"
                        value={password}
                        onFocus={() => setFocusedPassword(true)}
                        onBlur={() => setFocusedPassword(false)}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                  {/* <div className="custom-control custom-control-alternative custom-checkbox">
										<input
											className="custom-control-input"
											id=" customCheckLogin"
											type="checkbox"
										/>
										<label
											className="custom-control-label"
											htmlFor=" customCheckLogin"
										>
											<span className="text-muted">Remember me</span>
										</label>
									</div> */}
                  <div className="text-center">
                    <Button className="my-4" color="info" type="submit">
                      Sign in
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>

            {/* <Row className="mt-3">
								<Col xs="6">
									<a
										className="text-light"
										href="#pablo"
										onClick={(e) => e.preventDefault()}
									>
										<small>Forgot password?</small>
									</a>
								</Col>
								<Col className="text-right" xs="6">
									<a
										className="text-light"
										href="#pablo"
										onClick={(e) => e.preventDefault()}
									>
										<small>Create new account</small>
									</a>
								</Col>
							</Row> */}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Login;
