export const FETCH_PLANS_SUCCESS = "FETCH_PLANS_SUCCESS";
export const FETCH_PLANS_FAILED = "FETCH_PLANS_FAILED";
export const FETCH_PLANS_REQUEST = "FETCH_PLANS_REQUEST";
export const EMPTY_ALL_PLANS = "EMPTY_ALL_PLANS";

export const ADD_PLAN_REQUEST = "ADD_PLAN_REQUEST";
export const ADD_PLAN_SUCCESS = "ADD_PLAN_SUCCESS";
export const ADD_PLAN_FAILED = "ADD_PLAN_FAILED";

export const EDIT_PLANS_REQUEST = "EDIT_PLANS_REQUEST";
export const EDIT_PLANS_SUCCESS = "EDIT_PLANS_SUCCESS";
export const EDIT_PLANS_FAILED = "EDIT_PLANS_FAILED";

export const DELETE_PLANS_REQUEST = "DELETE_PLANS_REQUEST";
export const DELETE_PLANS_SUCCESS = "DELETE_PLANS_SUCCESS";
export const DELETE_PLANS_FAILED = "DELETE_PLANS_FAILED";
