import React from "react";
import ListCourses from "./ListCourses";
import SingleCourse from "./SingleCourse";

// import ListQuestions from "./TestQuestions/ListQuestions";
import { Col } from "reactstrap";
import { componentNames } from "shared/constants";
const Courses = (props) => {
	// let { id, testId } = useParams();
	const id = props.location.hash.split("/")[1];
	const testId = props.location.hash.split("/")[2];

	const handleRouting = () => {
		if (testId) {
			// return <ListQuestions testId={testId} />;
		} else if (id) {
			return <SingleCourse id={id} {...props} />;
		} else {
			return <ListCourses {...props} componentName={componentNames.course} />;
		}
	};

	return <Col lg={9}>{handleRouting()}</Col>;
};

export default Courses;
