import React, { useState } from "react";
import { Button } from "reactstrap";
import FormikControl from "../components/Formik/FormikControl";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Axios from "axios";
import styles from "./editEnrollmentForm.module.css";
import urls from "shared/urls";
import moment from "moment";

const Enrollments = ({
	username = "",
	validity = new Date(),
	id,
	updateEnrollment,
	spinner,
	sendNotification,
}) => {
	const [loading, setLoading] = useState(false);
	const enrollmentSchema = Yup.object().shape({
		username: Yup.string()
			.min(2, "Too Short!")
			.max(50, "Too Long!")
			.required("Required"),
	});

	const initialValues = {
		username,
		validity: new Date(validity),
		id: id,
	};

	const onSubmit = async (values, submitProps) => {
		submitProps.setSubmitting(true);
		// setLoading(true);
		// if (parseInt(values.id) === 0) {
		// 	return notify.current.notificationAlert(
		// 		setOptions(
		// 			"danger",
		// 			"You can't edit when you have selected all courses, select a specific course",
		// 			15
		// 		)
		// 	);
		// }
		const formData = {
			due_date: moment(values.validity).format("YYYY-MM-DD"),
			// expired: true,
		};
		try {
			const response = await Axios.put(
				urls.enrollments.singleEnrollment(id),
				formData
			);
			console.log(response.data);
			updateEnrollment(response.data);

			return sendNotification("success", "Successfully Edited", 3);
		} catch (error) {
			console.error(error);
			sendNotification();
		}
		setLoading(false);
		submitProps.setSubmitting(false);
	};
	return loading ? (
		<div className="d-flex justify-content-center align-items-center w-100 mt-4">
			{spinner}
		</div>
	) : (
		<>
			<Formik
				initialValues={initialValues}
				validationSchema={enrollmentSchema}
				onSubmit={onSubmit}
			>
				{(formik) => {
					return (
						<Form>
							<FormikControl
								control="input"
								type="text"
								label="Username (Phone number)"
								name="username"
								placeholder="Username (Phone number)"
								disabled
							/>
							<div className={styles.datePicker}>
								<FormikControl
									control="date"
									label="Valid upto"
									name="validity"
								/>
							</div>

							{/* <FormikControl
								control="radio"
								label={null}
								name="radioOption2"
								options={radioOptions}
							/>
					 */}

							<Button
								color="primary"
								className={`text-uppercase d-block mt-3`}
								type="submit"
								disabled={!formik.dirty || formik.isSubmitting}
							>
								save
							</Button>
						</Form>
					);
				}}
			</Formik>
		</>
	);
};

export default Enrollments;
