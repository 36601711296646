import { USER_LOGOUT } from "store/constants/authConstants";
import { TEACHER_COURSES_SUCCESS } from "store/constants/teacherCourses";
import keyBy from "lodash/keyBy";
import { ADD_NEW_COURSE } from "store/constants/teacherCourses";
const initialState = [];

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case TEACHER_COURSES_SUCCESS:
			return keyBy(payload, "id");
		case ADD_NEW_COURSE:
			return { ...state, [payload.id]: payload };
		case USER_LOGOUT:
			return initialState;
		default:
			return state;
	}
};
