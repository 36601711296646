import React, { useState, useEffect } from "react";
import AddNewLibrary from "./AddNewLibrary";
import { viewLibraryList, deleteLibrary } from "store/actions/addLibrary";
import { useSelector, useDispatch } from "react-redux";
import ViewSingleRecord from "./ViewSingleRecord";
import CustomPagination from "shared/CustomPagination";
import ReactPaginate from "react-paginate";

const ViewList = () => {
  const [showAddLibrary, setShowAddLibrary] = useState(false);
  const [showEditLibrary, setShowEditLibrary] = useState(false);
  const [showList, setShowList] = useState(true);
  const dispatch = useDispatch();
  const librabyList = useSelector((state) => state.libraryList);
  const [showReadMore, setShowReadMore] = useState(false);
  const [records, setRecords] = useState();
  const [pageCount, setPageCount] = useState(2);
  const [data, setData] = useState();
  const [item, setItem] = useState(0);
  const [currentItem, setCurrentItem] = useState([]);
  const itemsPerPage = 12;

  const addLibraryHandler = () => {
    setShowAddLibrary(true);
    setShowList(false);
  };

  useEffect(() => {
    dispatch(viewLibraryList());
  }, []);

  const deleteRecord = (id) => {
    dispatch(
      deleteLibrary(id, () => {
        setTimeout(() => {
          dispatch(viewLibraryList());
        }, 500);
      })
    );
  };

  const showEditPage = (item) => {
    setShowEditLibrary(true);
    setShowList(false);
    setRecords(item);
  };

  const handlePageClick = (e) => {
    const newItem = (e.selected * itemsPerPage) % data?.length;
    setItem(newItem);
  };

  useEffect(() => {
    setData(librabyList?.results);
  }, [librabyList]);

  useEffect(() => {
    const end = item + itemsPerPage;
    setCurrentItem(data?.slice(item, end));
    setPageCount(Math.ceil(data?.length / itemsPerPage));
  }, [item, itemsPerPage, data]);

  return (
    <>
      {showList ? (
        <>
          <div className="col-lg-9">
            <button
              type="button"
              onClick={addLibraryHandler}
              className="mb-3 btn btn-primary btn-lg btn-block"
            >
              Add Record
            </button>
            <ul className="list-group">
              {currentItem?.map((item, key) => (
                <div
                  key={key}
                  className="list-group-item-action list-group-item"
                >
                  <div className="d-flex">
                    <div className="col">
                      <div className="row">
                        <div className="col-12">
                          <span className="text-primary h2">
                            {item.heading}
                          </span>
                        </div>
                        <div className="col-12">
                          <div className="row">
                            <div className="col-12 col-md-8">
                              {item?.discription?.length > 150 ? (
                                <>
                                  <span>
                                    {showReadMore
                                      ? item?.discription
                                      : item?.discription?.slice(0, 150)}
                                  </span>
                                  <span
                                    className="read-more"
                                    onClick={() =>
                                      setShowReadMore((prev) => !prev)
                                    }
                                  >
                                    &nbsp;...
                                    {!showReadMore ? "read more" : "read less"}
                                  </span>{" "}
                                </>
                              ) : (
                                item?.discription
                              )}
                            </div>
                            <div className="d-flex align-items-end justify-content-end justify-content-xs-start col-12 col-md-4 flex-column ">
                              <div className="buttons-wrap mitemsPerPaget-3">
                                <button
                                  className="edit-btn"
                                  onClick={() => showEditPage(item)}
                                >
                                  edit
                                </button>
                                <button
                                  className="delete-btn edit-btn"
                                  onClick={() => deleteRecord(item.id)}
                                >
                                  delete
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </ul>
            {librabyList?.count > itemsPerPage ? (
              <div style={{ marginTop: "10px" }} className="pagination-wrap">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=">"
                  onPageChange={handlePageClick}
                  pageCount={pageCount}
                  previousLabel="<"
                  renderOnZeroPageCount={null}
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  containerClassName="pagination"
                  activeClassName="acitve"
                />
              </div>
            ) : null}
          </div>
          {/* {librabyList?.count > 0 && (
            <CustomPagination
              page={page}
              setPage={(value) => setPage(value)}
              count={librabyList?.count}
              rowLibrary={2}
              />
            )} */}
        </>
      ) : null}
      {showAddLibrary ? (
        <AddNewLibrary
          setShowList={setShowList}
          setShowAddLibrary={setShowAddLibrary}
        />
      ) : null}
      {showEditLibrary ? (
        <ViewSingleRecord
          setShowList={setShowList}
          setShowEditLibrary={setShowEditLibrary}
          records={records}
        />
      ) : null}
    </>
  );
};

export default ViewList;
