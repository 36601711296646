import _ from "lodash";
import { USER_LOGOUT } from "store/constants/authConstants";
import { DELETE_QUESTION_SUCCESS } from "store/constants/questionConstants";
import { EMPTY_ALL_QUESTIONS } from "store/constants/questionConstants";
import { ADD_QUESTION_SUCCESS } from "store/constants/questionConstants";
import { EDIT_QUESTION_SUCCESS } from "store/constants/questionConstants";
import { EDIT_QUESTION_STATUS } from "store/constants/questionConstants";
// import { FETCH_QUESTIONS_FAILED } from "store/constants/questionConstants";
import { FETCH_QUESTIONS_SUCCESS } from "store/constants/questionConstants";

const initialState = [];
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_QUESTIONS_SUCCESS:
      return _.keyBy(payload, "id");
    // case FETCH_QUESTIONS_FAILED:
    // return payload;
    case EDIT_QUESTION_STATUS:
      return {
        ...state,
        [payload.id]: {
          ...state[payload.id],
          questionState: payload.questionState,
        },
      };
    case EDIT_QUESTION_SUCCESS:
      return { ...state, [payload.id]: payload };
    case DELETE_QUESTION_SUCCESS:
      return _.omit(state, [payload]);
    case ADD_QUESTION_SUCCESS:
      return { ...state, [payload.id]: payload };
    case EMPTY_ALL_QUESTIONS:
      return initialState;
    case USER_LOGOUT:
      return initialState;
    default:
      return state;
  }
};
