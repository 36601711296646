import React, { useState } from "react";
import { Button, Collapse } from "reactstrap";
import FormikControl from "../../../components/Formik/FormikControl";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import Axios from "axios";
import urls from "../../../../../shared/urls";

const EnrollStudent = ({ updateChapter, course_id, sendNotification }) => {
	const enrollmentSchema = Yup.object().shape({
		name: Yup.string()
			.min(2, "Too Short!")
			.max(50, "Too Long!")
			.required("Required"),
	});

	const initialValues = {
		name: "",
	};

	const onSubmit = async (values, submitProps) => {
		// submitProps.setSubmitting(true);
		try {
			const response = await Axios.post(
				urls.course.chapters(course_id),
				values
			);
			updateChapter(response.data);
			submitProps.resetForm();
			toggle();
			sendNotification("success", "Successfully added ");
		} catch (error) {
			if (error.response) {
				sendNotification("error", error.response.detail);
			} else {
				sendNotification();
			}
		}
		// submitProps.setSubmitting(false);
	};

	const [isOpen, setIsOpen] = useState(false);

	const toggle = () => setIsOpen(!isOpen);
	return (
		<>
			<Button
				block
				color="primary"
				onClick={toggle}
				size="lg"
				type="button"
				className="mb-3"
			>
				New Chapter
			</Button>
			<Collapse isOpen={isOpen} className="mb-3">
				<Formik
					initialValues={initialValues}
					validationSchema={enrollmentSchema}
					onSubmit={onSubmit}
					enableReinitialize
				>
					{(formik) => (
						<Form>
							<p className="lead text-dark mt-0">Create Chapter</p>
							<FormikControl
								control="input"
								type="text"
								label="Chapter Name"
								name="name"
								placeholder="Chapter Name"
							/>

							<Button
								color="primary"
								type="submit"
								disabled={!formik.dirty || formik.isSubmitting}
							>
								Save Changes
							</Button>
							<Button
								className="ml-auto "
								color="outline-primary"
								data-dismiss="modal"
								type="button"
								onClick={toggle}
							>
								Close
							</Button>
						</Form>
					)}
				</Formik>
			</Collapse>
		</>
	);
};

export default EnrollStudent;
