import React, { useEffect } from "react";

import FormikControl from "../../../components/Formik/FormikControl";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import Axios from "axios";
import moment from "moment";
import { Button, Col, Row } from "reactstrap";
import { blockTypeOptions } from "views/pages/components/blockTypeOptions";
import urls from "shared/urls";
import formDataAxios from "shared/formDataAxios";
import { useState } from "react";
import { useLocation, useParams } from "react-router";

const EditAddBlockForm = ({
  modalValue,
  setModalValue,
  sendNotification,
  start_time,
  end_time,
  updateBlocks,
  is_free,
  duration,
  addBlock, //for adding new block
  // course_id,
  chapter_id,
  id: block_id,
  ...rest
}) => {
  const toggle = () => setModalValue(!modalValue);
  const [showEndDate, setShowEndDate] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [dateError, setDateError] = useState(false);
  const [startdateError, setStartDateError] = useState(false);
  const [timeError, setTimeError] = useState(false);

  const location = useLocation();
  const course_id = location.hash.split("/")[1];

  const isTestSeries = location.pathname.includes("test-series");

  const validationSchema = Yup.object().shape({
    title: Yup.string().min(2, "Too Short!").required("Required"),
  });

  const initialValues = {
    title: "",
    block_type: isTestSeries ? "test" : "youtube_video", // setting it as first option for add block
    url: "",
    ...rest, // for editing value
    test_duration: duration,
    is_freeBlock: is_free,
    start_time: start_time ? new Date(start_time) : "", //new Date()
    // start_time_only: start_time
    // 	? moment(new Date(start_time)).format("YYYY-MM-DD[T]HH:mm:ss")
    // 	: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
    // : new Date(new Date().setHours(0, 0, 0, 0)), // set time 12 am
    end_time: end_time ? new Date(end_time) : "", //new Date()
    // end_time_only: end_time
    // 	? moment(new Date(end_time)).format("YYYY-MM-DD[T]HH:mm:ss")
    // 	: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
    // : new Date(new Date().setHours(0, 0, 0, 0)), // set time 12 am
  };

  const is_freeOptions = [
    { id: true, name: "Free" },
    { id: false, name: "Paid" },
  ];

  const getInputBasedOnCourseType = (courseType, setFieldValue) => {
    if (courseType === "test") {
      // return null;
      return (
        addBlock && (
          <FormikControl
            placeholder="Duration(number of minutes)"
            label="Duration(number of minutes)"
            type="number"
            name="test_duration"
            control="input"
            // onChange={(e)=>console.log(e.target.value)}
          />
        )
      );
    }
    if (courseType === "readable" || courseType === "video") {
      return (
        <>
          <FormikControl
            control="file"
            type="file"
            label={`${!addBlock ? "Edit" : ""} ${
              courseType === "readable"
                ? "readable"
                : courseType === "video"
                ? "video"
                : ""
            }  ${
              !addBlock ? "(previously uploaded readable will be lost)" : ""
            }`}
            name="block_file"
            required={addBlock}
          />

          {!addBlock && rest.readable && (
            <div className="form-group">
              <a href={rest.readable} rel="noopener noreferrer" target="_blank">
                <span className="btn-inner--icon">
                  <i className="fas fa-book mr-2" />
                </span>
                Uploaded Readable
              </a>
            </div>
          )}
        </>
      );
    }
    return (
      <>
        <FormikControl
          control="input"
          type="text"
          label="URL (Paste url)"
          name="url"
          // required
          placeholder="Paste url"
        />
      </>
    );
  };

  const timeVal = new Date().getTime();
  const timeVal2 = startDate && startDate.getTime();
  const timeVal3 = endDate ? endDate.getTime() : "";
  const timeDiff = endDate ? endDate - startDate : "";
  const minutes = Math.floor(timeDiff / 60000);
  const hours = Math.round(minutes / 60);

  const onSubmit = async (values, submitProps) => {
    console.log("block type", values.block_type);
    if (endDate && endDate < startDate) {
      setStartDateError(true);
      return;
    } else if (values.block_type === "zoom" && hours > 48) {
      setDateError(true);
      return;
    } else if (
      (timeVal2 && timeVal2 < timeVal) ||
      (timeVal3 && timeVal3 < timeVal)
    ) {
      setTimeError(true);
      return;
    } else {
      submitProps.setSubmitting(true);
    }

    try {
      const isReadable =
        values.block_type === "readable" || values.block_type === "video";
      let formData = {
        title: values.title,
        block_type: values.block_type,
        // start_time: new Date(
        // 	`${moment(values.start_time).format("YYYY-MM-DD")} ${
        // 		values.start_time_only
        // 	}`
        // ),
        // start_time:moment(new Date("2022-01-01")).format("YYYY-MM-DD"),
        start_time: moment(new Date(values.start_time)).format(
          "YYYY-MM-DD[T]HH:mm"
        ), //amit

        // start_time: new Date("2022-05-31 05:20:00"),
        //moment(new Date(values.start_time)).format("YYYY-MM-DD HH:mm:ss[+01:00]"),

        is_free: JSON.parse(values.is_freeBlock),
        test_duration: values.test_duration,
      };

      if (showEndDate) {
        formData.end_time = moment(new Date(values.end_time)).format(
          "YYYY-MM-DD[T]HH:mm"
        ); //amit
      } else {
        formData.end_time = null; //amit
      }
      if (values.block_type !== "test") {
        formData = {
          ...formData,
          url: values.url,
        };
      }
      // In case block type is readable you need to send data as form data by appending readable file
      if (isReadable) {
        formData = new FormData();
        formData.append("title", values.title);
        formData.append("block_type", values.block_type);
        // formData.append(
        // 	"start_time",
        // 	moment(
        // 		new Date(
        // 			`${moment(values.start_time).format("YYYY-MM-DD")} ${
        // 				values.start_time_only
        // 			}`
        // 		)
        // 	).format("YYYY-MM-DD[T]HH:mm:ss")
        // );
        formData.append(
          "start_time",
          // "2022-06-01 12:55:17")
          moment(new Date(values.start_time)).format("YYYY-MM-DD[T]HH:mm")
        ); //amit

        // formData.append("start_time",moment(new Date("2022-01-01")).format("YYYY-MM-DD"));
        console.log(formData);
        showEndDate
          ? // ? formData.append(
            // 		"end_time",
            // 		moment(
            // 			new Date(
            // 				`${moment(values.end_time).format("YYYY-MM-DD")} ${
            // 					values.end_time_only
            // 				}`
            // 			)
            // 		).format("YYYY-MM-DD[T]HH:mm:ss")
            //   )
            // : formData.append("end_time", 0);
            formData.append(
              "end_time",
              moment(new Date(values.end_time)).format("YYYY-MM-DD[T]HH:mm") //amit
            )
          : formData.append("end_time", ""); //amit

        values.block_file && formData.append("block_file", values.block_file);
        formData.append("is_free", JSON.parse(values.is_freeBlock));
        // console.log(formData)
      }

      const addUrl = urls.course.blocks(course_id, chapter_id);
      const editUrl = urls.course.singleBlock(course_id, chapter_id, block_id);
      const response = isReadable
        ? addBlock
          ? await Axios.post(addUrl, formData) // add
          : await Axios.put(editUrl, formData) // edit
        : addBlock
        ? await Axios.post(addUrl, formData) //add
        : await Axios.put(editUrl, formData); // edit

      submitProps.resetForm();
      updateBlocks(response.data);
      sendNotification(
        "success",
        `Successfully ${addBlock ? "Added" : "Edited"}`
      );
      toggle();
    } catch (error) {
      console.error(error);
      if (error.response) {
        sendNotification(
          "danger",
          Object.values(error.response.data).toString()
        );
      } else sendNotification();
    } finally {
      submitProps.setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize={!addBlock}
    >
      {(formik) => (
        <Form>
          <FormikControl
            control="input"
            label="Block Topic"
            name="title"
            placeholder="Block Topic"
          />
          {/* <p className="mb--3 pb-0 text-darker">Start Date and Time</p> */}
          <Row>
            <Col>
              <FormikControl
                getDate={(e) => {
                  setStartDate(e);
                  setStartDateError(false);
                  setTimeError(false);
                }}
                control="date"
                label="Start Date and Time"
                name="start_time"
                // timeFormat={true}
              />
            </Col>
            {/* <Col xs={6}>
							<FormikControl control="time" name="start_time_only" label=" " />
						</Col> */}
          </Row>
          {startdateError ? (
            <p style={{ color: "red" }}>
              Start date cannot be greater than end date
            </p>
          ) : null}
          {timeError ? (
            <p style={{ color: "red" }}>Time cannot be less than curren time</p>
          ) : null}
          <Button
            className="btn-icon btn-3 mb-3"
            color={showEndDate ? "danger" : "primary"}
            type="button"
            onClick={() => setShowEndDate((s) => !s)}
          >
            <span className="btn-inner--text">
              {showEndDate ? "Remove End Date" : "Add End Date"}
            </span>
            <span className="btn-inner--icon">
              <i className={`fas ${showEndDate ? "fa-minus" : "fa-plus"}`} />
            </span>
          </Button>

          {showEndDate && (
            <>
              {/* <p className="mb--3 pb-0 text-darker">End Date and Time</p> */}
              <Row>
                <Col>
                  <FormikControl
                    getDate={(e) => {
                      setEndDate(e);
                      setDateError(false);
                      setTimeError(false);
                    }}
                    control="date"
                    label="End Date and Time"
                    name="end_time"
                    // timeFormat={true}
                  />
                </Col>
                {/* <Col xs={6}>
									<FormikControl
										control="time"
										name="end_time_only"
										label=" "
									/>
								</Col> */}
              </Row>
              {dateError ? (
                <p style={{ color: "red" }}>
                  Meeting time cannot exceed more than 48 hours
                </p>
              ) : null}
            </>
          )}

          <FormikControl
            control="radio"
            label={"Block Type"}
            name="block_type"
            options={blockTypeOptions}
            onClick={() => setDateError(false)}
          />

          {getInputBasedOnCourseType(
            formik.values.block_type,
            formik.setFieldValue
          )}
          <FormikControl
            control="radio"
            label={"Type"}
            name="is_freeBlock"
            options={is_freeOptions}
          />

          <div className="modal-footer">
            <Button
              color="primary"
              type="submit"
              disabled={formik.isSubmitting}
            >
              Save Changes
            </Button>
            <Button
              className="ml-auto "
              color="outline-primary"
              type="button"
              onClick={() => {
                formik.resetForm();
                toggle();
              }}
            >
              Cancel
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default EditAddBlockForm;
