export const courseTypeOptions = [
	// 	recorded = 'recorded'
	// live = 'live'
	// static = 'static'
	// offline = 'offline'
	// practice = 'practice'
	{ id: "live", display_name: "Live" },
	{ id: "recorded", display_name: "Recorded" },
	{ id: "test", display_name: "Test" },
	{ id: "static", display_name: "Static" },
	// { id: 5, display_name: "TBD" },
	{ id: "practice", display_name: "Practice" },
	{ id: "Offline", display_name: "Offline" },
];
