import axios from 'shared/Axios';
import React, { useEffect, useState } from 'react'
import "../../../assets/css/argon-dashboard-pro-react.css";
import moment from 'moment';
import ReactPaginate from 'react-paginate';
const UserLogs = () => {
    const [data, setData] = useState();
    const [currentItem, setCurrentItem] = useState();
    const [pageCount, setPageCount] = useState([]);
    const [item, setItem] = useState();
    const itemsPerPage = 15
    const [currentPage, setCurrentPage] = useState(1);
    useEffect(() => {
        const end = item + itemsPerPage
        setCurrentItem(data?.slice(item, end))
        // setPageCount(Math.ceil(data?.length / itemsPerPage))
    }, [item, itemsPerPage, data])
    const handlePageClick = (e) => {
        const newItem = (e.selected * itemsPerPage) % data.count
        setCurrentPage(e.selected + 1)
        setItem(newItem);
    };
    useEffect(() => {
        axios.get(`/user-logs/list/?page=${currentPage}`).then((res) => {
            setData(res?.data?.results)
            setPageCount(Math.ceil(res?.data?.count / itemsPerPage))

        });
    }, [currentPage])

    return (
        <>
            <div className="col-lg-9">
                {data?.map((item, index) => {
                    return <div key={index} className="mb-2 list-group-item-action list-group-item">
                        <div>
                            <span className="text-primary h2 logs-head cursor-pointer">Name: </span>
                            <span>{item.user.name} </span>
                        </div>
                        <div>
                            <span className="text-primary h2 logs-head cursor-pointer">Activity: </span>
                            <span>{item.message} </span>

                        </div>
                        <div>
                            <span className="text-primary h2 logs-head cursor-pointer">Platform: </span>
                            <span>{item.platform} </span>

                        </div>
                        <div>
                            <span className="text-primary h2 logs-head cursor-pointer">Date: </span>
                            <span>{
                                moment(item.updated).format(' Do MMMM  YYYY, h:mm a')
                            }
                            </span>
                        </div>
                    </div>
                })}
            </div>
            <nav>
                <ul className='pagination'>
                </ul>
            </nav>
            <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                pageCount={pageCount}
                previousLabel="<"
                renderOnZeroPageCount={null}
                pageClassName="page-item"
                pageLinkClassName='page-link'
                previousClassName='page-item'
                previousLinkClassName='page-link'
                nextClassName="page-item"
                nextLinkClassName='page-link'
                breakClassName='page-item'
                breakLinkClassName='page-link'
                containerClassName='pagination'
                activeClassName='acitve'
            />
        </>
    )
}

export default UserLogs