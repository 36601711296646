//This answer block is also used as solution block because all we need is to not have any checkbox

import React, { useState, useRef, useEffect, useCallback } from "react";
import ReactQuill, { Quill } from "react-quill";
import debounce from "lodash/debounce";
import mathquill4quill from "mathquill4quill";

// import "mathquill4quill/build/mathquill4quill.min.css";
import "./quillCustom.css";
import "mathquill4quill/build/mathquill4quill.min.js";
import "katex/dist/katex.min.css";
import "katex/dist/katex.min.js";
import katex from "katex";

import { useDispatch } from "react-redux";

import { editQuestion } from "store/actions/questionActions";
window.katex = katex;

const SolutionBlock = ({ option, id }) => {
  const [reactQuillText, setReactQuillText] = useState(option);
  const dispatch = useDispatch();
  const reactQuill = useRef();

  useEffect(() => {
    const enableMathQuillFormulaAuthoring = mathquill4quill({
      Quill,
      focus: true,
    });
    enableMathQuillFormulaAuthoring(reactQuill.current.editor, {
      operators: [
        ["\\pm", "\\pm"],
        ["\\sqrt{x}", "\\sqrt"],
        ["\\sqrt[n]{x}", "\\nthroot"],
        ["\\frac{x}{y}", "\\frac"],
        ["\\sum^{s}_{x}{d}", "\\sum"],
        ["\\prod^{s}_{x}{d}", "\\prod"],
        ["\\coprod^{s}_{x}{d}", "\\coprod"],
        ["\\int^{s}_{x}{d}", "\\int"],
        ["\\binom{n}{k}", "\\binom"],
        ["\\log{x}", "\\log"],
      ],

      displayHistory: true, // defaults to false
      historyCacheKey: "__my_app_math_history_cachekey_", // optional
      historySize: 20, // optional (defaults to 10)
    });
  }, []);

  const dispatchRequest = (value) => {
    dispatch(editQuestion({ id, solution_text: value }));
  };

  const debounceQuery = useCallback(
    debounce((q) => dispatchRequest(q), 1000),
    []
  );

  const handleReactQuillChange = (value) => {
    setReactQuillText(value);
    debounceQuery(value);
  };

  return (
    <>
      <div className="mb-1 mt-2 text-dark">Solution</div>
      <div className="d-flex">
        <ReactQuill
          value={reactQuillText || ""}
          onChange={handleReactQuillChange}
          placeholder="Write solution here"
          theme="snow"
          ref={reactQuill}
          className="answer-block-options"
          modules={{
            formula: true,
            toolbar: {
              container: [["formula", "image"]],
            },
          }}
          formats={["formula", "image"]}
        />
      </div>
    </>
  );
};

export default SolutionBlock;
