import React, { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { Col, Row, Input } from "reactstrap";
import { editQuestion } from "store/actions/questionActions";
import SolutionBlock from "../SolutionBlock";
import QuestionBlock from "../QuestionBlock";
import debounce from "lodash/debounce";
const FillAndNumeric = (props) => {
  //options block
  const { id, question_text, question_type, correct_answer } = props;

  //solution
  const { solution_text } = props;
  const [value, setValue] = useState(correct_answer);
  const dispatch = useDispatch();

  const dispatchRequest = (value) => {
    dispatch(
      editQuestion({
        id,
        correct_answer: value,
      })
    );
  };

  const debounceQuery = useCallback(
    debounce((q) => dispatchRequest(q), 1000),
    []
  );

  const handleOnChange = (e) => {
    setValue(e.target.value);
    debounceQuery(e.target.value);
  };

  return (
    <Row>
      <Col md={12}>
        <QuestionBlock
          question={question_text}
          id={id}
          question_type={question_type}
        />
      </Col>
      <Col md={12} className="mt-2">
        <div className="mb-1 text-dark">Answer</div>
        <Input
          placeholder={
            question_type === "integer"
              ? "Enter only numbers"
              : "Write your answer here"
          }
          value={value || ""}
          onChange={handleOnChange}
          type={question_type === "integer" ? "number" : "text"}
        />
      </Col>
      <Col md={12}>
        <SolutionBlock
          option={solution_text}
          optionNumber={"solution"}
          checkboxHidden={true}
          id={id}
        />
      </Col>
    </Row>
  );
};

export default FillAndNumeric;
