import Axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import {
	ListGroup,
	Button,
	Col,
	FormGroup,
	Input,
	InputGroupAddon,
	InputGroupText,
	Row,
} from "reactstrap";
import SingleReadable from "./SingleReadable";
import ReadablesForm from "./ReadablesForm";
import CustomSpinner from "../components/CustomSpinner";
import "./readablesForm.css";
import { useIsMount } from "../components/hooks/useIsMount";
import urls from "shared/urls";

import keyBy from "lodash/keyBy";
import { useDispatch } from "react-redux";
import { fetchTeachers } from "store/actions/teacherActions";
import { fetchSubjects } from "store/actions/subjectActions";
import InputGroup from "reactstrap/lib/InputGroup";
import CustomPagination from "shared/CustomPagination";

import CustomReactSelect from "../components/CustomReactSelect";
import { categoryOptions } from "shared/constants";
import ListGroupItem from "reactstrap/lib/ListGroupItem";

const readableCategoryOptions = categoryOptions.map((item) => ({
	id: item.id,
	text: item.name,
}));

const Readables = ({ sendNotification }) => {
	const [readAbles, setReadAbles] = useState({});
	const [isLoading, setIsLoading] = useState(false);
	const [modal, setModal] = useState(false);

	const [searchReadable, setSearchReadable] = useState("");
	const [page, setPage] = useState(1);
	const [select, setSelect] = useState(null);
	const [count, setCount] = useState(null);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchSubjects());
		dispatch(fetchTeachers());
	}, [dispatch]);

	const _isMounted = useIsMount();
	const readRef = useRef();

	const scrollIntoView = () => {
		if (readRef && !_isMounted) {
			readRef.current.scrollIntoView();
		}
	};

	const fetchReadAbles = async (params) => {
		try {
			setIsLoading(true);
			const response = await Axios(urls.readAbles.data, { params });
			setReadAbles(keyBy(response.data.results, "id"));
			setCount(response.data.count);
			// console.log(response.data.count);
		} catch (error) {
			console.error();
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchReadAbles();
	}, []);

	const updateReadAbles = (data, increaseCountOnAddingNewReadable) => {
		if (increaseCountOnAddingNewReadable) {
			setCount((s) => s + 1);
		}
		return setReadAbles((prevState) => ({ ...prevState, [data.id]: data }));
	};

	useEffect(() => {
		const params = {
			page,
		};
		if (select) {
			params.rc = select;
		}
		fetchReadAbles(params);
	}, [page, select]);

	// useEffect(() => {
	// 	setCount(Object.keys(readAbles).length);
	// }, [readAbles]);

	const listReadAbles = () => {
		return Object.values(readAbles).length > 0 ? (
			<>
				{Object.values(readAbles).map((read) => (
					<SingleReadable
						{...read}
						key={read.id}
						sendNotification={sendNotification}
						updateReadAbles={updateReadAbles}
						setReadAbles={setReadAbles}
					/>
				))}
			</>
		) : (
			<ListGroupItem>No Readables</ListGroupItem>
		);
	};

	return (
		<Col lg={9}>
			<div ref={readRef}>
				<Button
					block
					color="primary"
					onClick={() => setModal(!modal)}
					size="lg"
					type="button"
					className="mb-3"
				>
					New Readable
				</Button>
			</div>
			<ReadablesForm
				modalValue={modal}
				setModalValue={(val) => setModal(val)}
				sendNotification={sendNotification}
				updateReadAbles={updateReadAbles}
				addReadAble
				scrollIntoView={scrollIntoView}
			/>
			<Row>
				<Col xs={12} md={8}>
					<FormGroup>
						<InputGroup>
							<InputGroupAddon addonType="prepend">
								<InputGroupText>
									<i className="fas fa-search" />
								</InputGroupText>
							</InputGroupAddon>
							<Input
								type="search"
								value={searchReadable}
								onChange={(e) => {
									fetchReadAbles({ name: e.target.value });
									return setSearchReadable(e.target.value);
								}}
								placeholder="Type readable name here"
							/>
						</InputGroup>
					</FormGroup>
				</Col>
				<Col xs={12} md={4}>
					<CustomReactSelect
						dropDownArray={[
							{ id: null, text: "All Categories" },
							...readableCategoryOptions,
						]}
						select={select}
						handleOnChange={(id) => setSelect(id)}
						placeholder={"Filter by category"}
					/>
				</Col>
			</Row>
			{isLoading ? (
				<div
					className="d-flex justify-content-center align-items-center"
					style={{ height: "60vh", width: "100%" }}
				>
					<CustomSpinner />
				</div>
			) : (
				<>
					<ListGroup>{listReadAbles()}</ListGroup>
					{Object.values(readAbles).length > 0 && (
						<CustomPagination
							page={page}
							setPage={(value) => setPage(value)}
							count={count}
						/>
					)}
				</>
			)}
		</Col>
	);
};

export default Readables;
