import axios from "shared/Axios";
import { FREE_LIBRARY } from "store/constants/addLibraryConstants";

export const addLibrary = (data, callback) => (dispatch) => {
  axios.post("/bulk/FreeLibrary/", data).then((res) => {
    if (res.status === 200) {
      callback();
    }
  });
};

export const viewLibraryList = (data, callback) => (dispatch) => {
  axios.get("/bulk/FreeLibrary/", data).then((res) => {
    dispatch({ type: FREE_LIBRARY, payload: res.data });
  });
};

export const deleteLibrary = (id, callback) => (dispatch) => {
  axios.delete(`/bulk/FreeLibrary/${id}/`).then((res) => {
    if (res.status === 200) {
      callback();
    }
  });
};

export const editLibrary = (data, id, callback) => (dispatch) => {
  axios.put(`/bulk/FreeLibrary/${id}/`, data).then((res) => {
    if (res.status === 200) {
      callback();
    }
  });
};
