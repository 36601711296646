import React from "react";
import { Col, ListGroupItem, Row, ListGroup } from "reactstrap";
import moment from "moment";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { INSTITUTE_ID } from "shared/constants";

const ListOnGoingEvent = (props) => {
	const { course_name, id, title, start_time } = props;

	return (
		<ListGroupItem>
			<Row className="align-items-center">
				<Col className="d-flex align-items-center justify-items-end" xs={12}>
					<Link
						to={`/admin/live-events/#/o/${INSTITUTE_ID}/${id}`}
						className="text-primary h2"
					>
						{title}
					</Link>
					{/* <Button className="ml-auto" size="sm" outline color="danger">
						Delete
					</Button> */}
				</Col>
				<Col className="d-flex align-items-center justify-items-end" xs={12}>
					<div className="text-muted">{course_name}</div>
					<div className="ml-auto text-muted">
						{moment(start_time).format("D MMM YYYY, h:mm A")}
					</div>
				</Col>
			</Row>
		</ListGroupItem>
	);
};

const OnGoingEvents = () => {
	const onGoingEvents = useSelector((state) =>
		Object.values(state.onGoingEvents)
	);

	const renderOnGoingEvents = () => {
		return onGoingEvents.map((event) => {
			return <ListOnGoingEvent {...event} key={event.id} />;
		});
	};

	return (
		<ListGroup>
			{onGoingEvents.length === 0 ? (
				<ListGroupItem>No ongoing events!</ListGroupItem>
			) : (
				renderOnGoingEvents()
			)}
		</ListGroup>
	);
};

export default OnGoingEvents;
