import React from "react";
import Input from "./Input";
import Textarea from "./Textarea";
import Select from "./Select";
import RadioButtons from "./RadioButtons";
import CheckboxGroup from "./CheckboxGroup";
import DatePicker from "./DatePicker";
import FilePicker from "./FilePicker";
import CustomCheckBoxGroup from "./CustomCheckBoxGroup";
import InputWithAddOn from "./InputWithAddOn";
import TimePicker from "./TimePicker";
import CheckBoxWithLabel from "./CheckBoxWithLabel";

function FormikControl(props) {
  const { control, ...rest } = props;
  switch (control) {
    case "input":
      return <Input {...rest} />;
    case "customInput":
      return <InputWithAddOn {...rest} />;
    case "textarea":
      return <Textarea {...rest} />;
    case "select":
      return <Select {...rest} />;
    case "radio":
      return <RadioButtons {...rest} />;
    case "checkbox":
      return <CheckboxGroup {...rest} />;
    case "checkBoxWithLabel":
      return <CheckBoxWithLabel {...rest} />;
    case "customCheckBox":
      return <CustomCheckBoxGroup {...rest} />;
    case "date":
      return <DatePicker {...rest} />;
    case "time":
      return <TimePicker {...rest} />;
    case "file":
      return <FilePicker {...rest} />;
    default:
      return "No input";
  }
}

export default FormikControl;
