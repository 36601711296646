import _ from "lodash";
import { USER_LOGOUT } from "store/constants/authConstants";
import {
	// ONGOING_EVENTS_REQUESTED,
	ONGOING_EVENTS_SUCCESS,
	// ONGOING_EVENTS_FAILED,
	// ONGOING_EVENT_REMOVED_REQUESTED,
	ONGOING_EVENT_REMOVED,
	ONGOING_EVENT_ADDED,
	// UPCOMING_EVENTS_REQUESTED,
	UPCOMING_EVENTS_SUCCESS,
	// UPCOMING_EVENTS_FAILED,
	// UPCOMING_EVENT_REMOVED_REQUESTED,
	UPCOMING_EVENT_REMOVED,
} from "../constants/liveEventConstants";

const initialState = {};
export const onGoingEvents = (state = initialState, { type, payload }) => {
	switch (type) {
		case ONGOING_EVENTS_SUCCESS:
			return _.keyBy(payload, "id");
		case ONGOING_EVENT_REMOVED:
			return _.omit(state, [payload]);
		case ONGOING_EVENT_ADDED:
			return { ...state, ...payload };
		case USER_LOGOUT:
			return initialState;
		default:
			return state;
	}
};
export const upcomingEvents = (state = {}, { type, payload }) => {
	switch (type) {
		case UPCOMING_EVENTS_SUCCESS:
			return _.keyBy(payload, "id");
		case UPCOMING_EVENT_REMOVED:
			return _.omit(state, [payload]);
		default:
			return state;
	}
};
