import { USER_LOGOUT } from "../constants/authConstants";
import _ from "lodash";
import {
	FETCH_PLANS_SUCCESS,
	EMPTY_ALL_PLANS,
	DELETE_PLANS_SUCCESS,
	EDIT_PLANS_SUCCESS,
	ADD_PLAN_SUCCESS,
} from "../constants/planConstants";
export default (state = {}, { type, payload }) => {
	switch (type) {
		case FETCH_PLANS_SUCCESS:
			return _.keyBy(payload, "id");
		case DELETE_PLANS_SUCCESS:
			return _.omit(state, payload);
		case ADD_PLAN_SUCCESS:
			return { ...state, ...payload };
		case EDIT_PLANS_SUCCESS:
			return { ...state, ...payload };
		case USER_LOGOUT:
		case EMPTY_ALL_PLANS:
			return {};
		default:
			return state;
	}
};
