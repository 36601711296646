import { Col, Row } from "reactstrap";
import QuestionBlock from "../QuestionBlock";
import SolutionBlock from "../SolutionBlock";
import React, { useState, useRef, useEffect, useCallback } from "react";
import ReactQuill, { Quill } from "react-quill";
import debounce from "lodash/debounce";
import mathquill4quill from "mathquill4quill";

// import "mathquill4quill/build/mathquill4quill.min.css";
import "../quillCustom.css";
import "mathquill4quill/build/mathquill4quill.min.js";
import "katex/dist/katex.min.css";
import "katex/dist/katex.min.js";
import katex from "katex";

import { useDispatch } from "react-redux";

import { editQuestionOption } from "store/actions/questionActions";
window.katex = katex;

const Mcq = (props) => {
  //options block
  const { id, question_text, question_type, choices, solution_text } = props;
  //solution

  return (
    <Row>
      <Col md={6}>
        <QuestionBlock
          question={question_text}
          id={id}
          question_type={question_type}
        />
      </Col>
      <Col md={6}>
        <Row>
          {choices &&
            choices.map((choice, index) => (
              <Col md={12} className="mx-0 px-0">
                <AnswerBlock
                  option={choice.choice_text}
                  isCorrect={choice.is_answer}
                  optionNumber={`option_${index + 1}`}
                  choiceId={choice.id}
                  questionId={id}
                />
              </Col>
            ))}
          <Col md={12} className="mx-0 px-0">
            <SolutionBlock
              option={solution_text}
              optionNumber={"solution"}
              checkboxHidden={true}
              id={id}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Mcq;

const AnswerBlock = ({
  option,
  isCorrect,
  optionNumber,
  questionId,
  choiceId,
}) => {
  const [reactQuillText, setReactQuillText] = useState(option);
  const dispatch = useDispatch();
  const reactQuill = useRef();

  useEffect(() => {
    const enableMathQuillFormulaAuthoring = mathquill4quill({
      Quill,
      focus: true,
    });
    enableMathQuillFormulaAuthoring(reactQuill.current.editor, {
      operators: [
        ["\\pm", "\\pm"],
        ["\\sqrt{x}", "\\sqrt"],
        ["\\sqrt[n]{x}", "\\nthroot"],
        ["\\frac{x}{y}", "\\frac"],
        ["\\sum^{s}_{x}{d}", "\\sum"],
        ["\\prod^{s}_{x}{d}", "\\prod"],
        ["\\coprod^{s}_{x}{d}", "\\coprod"],
        ["\\int^{s}_{x}{d}", "\\int"],
        ["\\binom{n}{k}", "\\binom"],
        ["\\log{x}", "\\log"],
      ],

      displayHistory: true, // defaults to false
      historyCacheKey: "__my_app_math_history_cachekey_", // optional
      historySize: 20, // optional (defaults to 10)
    });
  }, []);
  if (questionId === 21657) {
    console.log({ isCorrect, questionId, choiceId });
  }

  const dispatchRequest = (value) => {
    dispatch(
      editQuestionOption({
        questionId,
        choiceId,
        choice_text: value,
        is_answer: isCorrect,
      })
    );
  };

  const debounceQuery = useCallback(
    debounce((q) => dispatchRequest(q), 1000),
    []
  );

  const handleReactQuillChange = (value) => {
    setReactQuillText(value);
    debounceQuery(value);
  };

  const handleRadioChange = (e) => {
    dispatch(
      editQuestionOption({
        questionId,
        choiceId: e.target.id,
        is_answer: true,
      })
    );
    // console.log(e.target.name, e.target.id, e.target.value);
  };
  return (
    <div className="d-flex">
      <input
        type="radio"
        className="ml-1 mr-3 my-auto"
        defaultChecked={isCorrect}
        checked={isCorrect}
        onChange={handleRadioChange}
        id={choiceId}
        name="smcq"
        style={{ transform: "scale(1.8)" }}
      />

      <ReactQuill
        value={reactQuillText || ""}
        onChange={handleReactQuillChange}
        placeholder={
          optionNumber.split("_")[0] + " " + optionNumber.split("_")[1]
        }
        theme="snow"
        ref={reactQuill}
        className="answer-block-options"
        modules={{
          formula: true,
          toolbar: {
            container: [["formula", "image"]],
          },
        }}
        formats={["formula", "image"]}
      />
    </div>
  );
};
