import Enrollments from "views/pages/Enrollments/Enrollments";
import Courses from "views/pages/Courses/Courses";
import LiveEvents from "views/pages/LiveEvents/LiveEvents";

import Discussion from "views/pages/Discussion/Discussion";
import Readables from "views/pages/Readables/Readables";
import TestSeries from "views/pages/TestSeries/TestSeries";
// import Books from "views/pages/Books/Books";
import Notifications from "views/pages/Notifications/Notifications";

//Auth
import Login from "views/pages/Auth/Login/Login";
import Coupons from "views/pages/Coupons/Coupons";
import Recommended from "views/pages/Recommended/Recommended";
import UserLogs from "views/pages/UserLogs/UserLogs";
import ViewList from "views/pages/FreeLibrary/ViewList";

const routes = [
  {
    path: "/enrollments",
    name: "Enrollments",
    icon: "fas fa-list",
    component: Enrollments,
    layout: "/admin",
  },
  {
    path: "/courses",
    name: "Courses",
    icon: "fas fa-layer-group",
    component: Courses,
    layout: "/admin",
  },
  {
    // invisible: true,
    path: "/live-events",
    name: "Live Events",
    icon: "ni ni-tv-2",
    component: LiveEvents,
    layout: "/admin",
  },
  {
    // invisible: true,
    path: "/discussion",
    name: "Discussion",
    icon: "ni ni-chat-round",
    component: Discussion,
    layout: "/admin",
  },
  {
    // invisible: true,
    path: "/notice",
    name: "Notice",
    icon: "fas fa-chalkboard-teacher",
    component: Discussion,
    layout: "/admin",
  },
  {
    path: "/readables",
    name: "Readables",
    icon: "ni ni-book-bookmark",
    component: Readables,
    layout: "/admin",
  },
  {
    path: "/test-series",
    name: "Test Series",
    icon: "ni ni-single-copy-04",
    component: TestSeries,
    layout: "/admin",
  },
  {
    path: "/coupons",
    name: "Coupons",
    icon: "fas fa-money-bill-alt",
    component: Coupons,
    layout: "/admin",
  },
  {
    // invisible: true,
    path: "/notifications",
    name: "Notifications",
    icon: "ni ni-bell-55",
    component: Notifications,
    layout: "/admin",
  },
  {
    invisible: true,
    path: "/login",
    name: "Login",

    component: Login,
    layout: "/auth",
  },
  {
    path: "/recommended",
    name: "Recommended books",
    icon: "ni ni-books",
    component: Recommended,
    layout: "/admin",
  },
  {
    path: "/user-logs",
    name: "User logs",
    icon: "ni ni-circle-08",
    component: UserLogs,
    layout: "/admin",
  },
  {
    path: "/free-library",
    name: "Free Library",
    icon: "ni ni-books",
    component: ViewList,
    layout: "/admin",
  },

  // {
  // 	path: "/login",
  // 	name: "Login",
  // 	miniName: "L",
  // 	component: Login,
  // 	layout: "/auth",
  // },
];

export default routes;
