import { RECOMMENDED_BOOKS } from "store/constants/addBooksConstants";

const initialState = {};

const recommendedBookReducer = (state = initialState, action) => {
  switch (action.type) {
    case RECOMMENDED_BOOKS:
      console.log("recommendedBooks", action.payload);
      return action.payload;
    default:
      return state;
  }
};

export default recommendedBookReducer;
