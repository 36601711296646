import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  addLibrary,
  viewLibraryList,
  editLibrary,
} from "store/actions/addLibrary";

const ViewSingleRecord = ({ setShowList, setShowEditLibrary, records }) => {
  const [heading, setHeading] = useState("");
  const [description, setDescription] = useState("");
  const [url, setUrl] = useState("");
  const [file, setFile] = useState("");
  const [fileValue, setFileValue] = useState("");
  const [headingError, setHeadingError] = useState(false);
  const [descError, setDescError] = useState(false);
  const [typeError, setTypeError] = useState(false);
  const [urlError, setUrlError] = useState(false);
  const [fileError, setFileError] = useState(false);
  const [image, setImage] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (records) {
      setHeading(records?.heading);
      setDescription(records?.discription);
      setFileValue(records?.filetype);
      setUrl(records?.youtube_url);
      setImage(records?.file);
      setFile(records?.file);
    }
  }, [records]);

  const handleCancel = (e) => {
    e.preventDefault();
    setShowList(true);
    setShowEditLibrary(false);
  };

  const handleFileType = (e) => {
    setFileValue(e);
    setFile("");
    setUrl("");
    setImage("");
    setFileError(false);
    setUrlError(false);
    setTypeError(false);
  };

  const handleHeading = (e) => {
    setHeading(e.target.value);
    setHeadingError(false);
  };

  const handleDescription = (e) => {
    setDescription(e.target.value);
    setDescError(false);
  };

  const handleUrl = (e) => {
    setUrl(e.target.value);
    setUrlError(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const urlRegex =
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;

    if (heading === "") {
      setHeadingError(true);
    } else if (description === "") {
      setDescError(true);
    } else if (fileValue === "youtube" && (url === "" || !urlRegex.test(url))) {
      setUrlError(true);
    } else if ((fileValue === "image" || fileValue === "pdf") && file === "") {
      setFileError(true);
    } else if (fileValue === "") {
      setTypeError(true);
    } else {
      const payload = new FormData();
      if (file === "") {
        payload.append("heading", heading);
        payload.append("discription", description);
        payload.append("filetype", fileValue);
        payload.append("youtube_url", url);
        dispatch(
          editLibrary(payload, records?.id, () => {
            setTimeout(() => {
              dispatch(viewLibraryList());
            }, 1000);
            setTimeout(() => setShowEditLibrary(false), 1500);
            setTimeout(() => setShowList(true), 1500);
          })
        );
      } else {
        payload.append("file", file);
        payload.append("heading", heading);
        payload.append("discription", description);
        payload.append("filetype", fileValue);
        payload.append("youtube_url", url);
        dispatch(
          editLibrary(payload, records?.id, () => {
            setTimeout(() => {
              dispatch(viewLibraryList());
            }, 1000);
            setTimeout(() => setShowEditLibrary(false), 1500);
            setTimeout(() => setShowList(true), 1500);
          })
        );
      }
    }
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setImage(URL.createObjectURL(e.target.files[0]));
    setFileError(false);
  };

  return (
    <div className="col-md-12">
      <div className="col-lg-9">
        <form action="#">
          <div className="form-group">
            <label for="title">Heading</label>
            <input
              id="title"
              placeholder="Add heading"
              name="title"
              type="text"
              className="form-control"
              value={heading}
              onChange={(e) => handleHeading(e)}
            />
          </div>
          {headingError ? (
            <span className="error">Please provide heading</span>
          ) : null}
          <div className="form-group">
            <label for="content">Description</label>
            <textarea
              id="content"
              rows="6"
              placeholder="Add description"
              name="content"
              className="form-control"
              value={description}
              onChange={(e) => handleDescription(e)}
            ></textarea>
          </div>
          {descError ? (
            <span className="error">Please write decription</span>
          ) : null}
          <div className="form-group">
            <label className="d-block">File Type</label>
            <div class="d-flex flex-wrap">
              <input
                checked={fileValue === "youtube"}
                type="radio"
                id="block_typeyoutube_videof77c8b-026b-5a3-b5a6-5a86ff75a5d4"
                name="block_type"
                className="radioButton_radio__15aDr"
                value="youtube"
                onChange={(e) => handleFileType(e.target.value)}
              />
              <label
                for="block_typeyoutube_videof77c8b-026b-5a3-b5a6-5a86ff75a5d4"
                className="m-2 btn btn-secondary radioButton_label__3xNmN"
              >
                Youtube Video
              </label>
              <input
                type="radio"
                checked={fileValue === "pdf"}
                id="block_typereadablef77c8b-026b-5a3-b5a6-5a86ff75a5d4"
                name="block_type"
                className="radioButton_radio__15aDr"
                value="pdf"
                onChange={(e) => handleFileType(e.target.value)}
              />
              <label
                for="block_typereadablef77c8b-026b-5a3-b5a6-5a86ff75a5d4"
                className="m-2 btn btn-secondary radioButton_label__3xNmN"
              >
                Pdf
              </label>
              <input
                type="radio"
                checked={fileValue === "image"}
                id="block_typetestf77c8b-026b-5a3-b5a6-5a86ff75a5d4"
                name="block_type"
                className="radioButton_radio__15aDr"
                value="image"
                onChange={(e) => handleFileType(e.target.value)}
              />
              <label
                for="block_typetestf77c8b-026b-5a3-b5a6-5a86ff75a5d4"
                className="m-2 btn btn-secondary radioButton_label__3xNmN"
              >
                Image
              </label>
            </div>
          </div>
          {typeError ? (
            <span className="error">Please select file type</span>
          ) : null}
          <div className="form-group">
            <label for="url">URL</label>
            <input
              id="url"
              placeholder="Add url"
              name="url"
              type="url"
              className="form-control"
              value={url}
              onChange={(e) => handleUrl(e)}
            />
          </div>
          {urlError ? (
            <span className="error">Please enter a valid url</span>
          ) : null}

          <div className="form-group">
            <label for="url">File</label>
            <input
              name="file"
              type="file"
              accept={
                fileValue === "pdf"
                  ? "application/pdf,application/vnd.ms-excel"
                  : "image/png, image/jpeg"
              }
              className="form-control"
              multiple
              onChange={handleFileChange}
            />
            <img style={{ width: "140px" }} alt="" src={image} />
          </div>
          {fileError ? <span className="error">Please choose file</span> : null}
          <button
            type="submit"
            className="w-50 btn btn-primary"
            onClick={handleSubmit}
          >
            Submit
          </button>
          <button
            className="float-right btn btn-secondary"
            onClick={handleCancel}
          >
            Cancel
          </button>
        </form>
      </div>
    </div>
  );
};

export default ViewSingleRecord;
