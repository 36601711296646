// import React from "react";
// import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

// const CustomPagination = ({
// 	count = 50,
// 	page,
// 	rowsPerPage = 5,
// 	onNext,
// 	onPrevious,
// 	setPage,
// }) => {
// 	const handleBackButtonClick = (event) => {
// 		onPrevious(event, page - 1);
// 	};

// 	const handleNextButtonClick = (event) => {
// 		onNext(event, page + 1);
// 	};
// 	return (
// 		<Pagination
// 			aria-label="Page navigation example"
// 			className="pagination justify-content-center"
// 			listClassName="justify-content-center mt-3"
// 		>
// 			{/* <PaginationItem>
// 				<PaginationLink first href="#" />
// 			</PaginationItem> */}
// 			<PaginationItem>
// 				<PaginationLink
// 					previous
// 					href="#"
// 					onClick={handleBackButtonClick}
// 					disabled={page === 1}
// 				/>
// 			</PaginationItem>
// 			{Array.from(Array(Math.ceil(count / rowsPerPage)).keys()).map((row) => (
// 				<PaginationItem key={row}>
// 					<PaginationLink onClick={() => setPage(row + 1)}>
// 						{row + 1}
// 					</PaginationLink>
// 				</PaginationItem>
// 			))}
// 			<PaginationItem>
// 				<PaginationLink
// 					next
// 					href="#"
// 					onClick={handleNextButtonClick}
// 					disabled={page >= Math.ceil(count / rowsPerPage)}
// 					aria-label="next page"
// 				/>
// 			</PaginationItem>
// 			{/* <PaginationItem>
// 				<PaginationLink last href="#" />
// 			</PaginationItem> */}
// 		</Pagination>
// 	);
// };

// export default CustomPagination;

import React from "react";
import { Button } from "reactstrap";

const ROWS_PER_PAGE = 20;
const CustomPagination = ({
  count,
  page = 1,
  rowLibrary,
  rowsPerPage = rowLibrary || ROWS_PER_PAGE,
  setPage,
}) => {
  // console.log(count);
  return (
    count && (
      <div className="d-flex justify-content-between align-items-center mt-4">
        <Button
          className="btn-icon btn-3 py-1 px-2 "
          color="primary"
          type="button"
          onClick={() => setPage(page - 1)}
          disabled={page === 1}
        >
          <span className="btn-inner--icon">
            <i className="fas fa-angle-left" />
          </span>
          <span className="btn-inner--text">Previous</span>
        </Button>
        <div>
          {/* {count} */}
          {rowsPerPage * page > count ? count : rowsPerPage * page} of {count}{" "}
          Entries
        </div>
        <Button
          className="btn-icon btn-3 py-1 px-2 "
          color="primary"
          type="button"
          onClick={() => setPage(page + 1)}
          disabled={page >= Math.ceil(count / rowsPerPage)}
        >
          <span className="btn-inner--text">Next</span>
          <span className="btn-inner--icon">
            <i className="fas fa-angle-right" />
          </span>
        </Button>
      </div>
    )
  );
};

export default CustomPagination;
