export const blockTypeOptions = [
	{ id: "youtube_video", display_name: "Youtube Video" },
	// { id: "classcast_server_video", display_name: "ClassCast Server Video" },
	{ id: "readable", display_name: "Readable" },
	{
		id: "test",
		display_name: "Test",
	},
	{ id: "zoom", display_name: "Zoom" },
	{ id: "live_session", display_name: "Live Session" },
	{ id: "video", display_name: "Video" }
];

export const liveBlockOptions = [
	{ id: "youtube_video", display_name: "Youtube Video" },
	{ id: "zoom", display_name: "Zoom" },
	{ id: "live_session", display_name: "Live Session" },
	//{ id: "video", display_name: "Video" }
];
