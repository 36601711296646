import Axios from "axios";
import { TEACHER_COURSES_FAIL } from "store/constants/teacherCourses";
import { TEACHER_COURSES_SUCCESS } from "store/constants/teacherCourses";
import { TEACHER_COURSES_REQUEST } from "store/constants/teacherCourses";
import { ADD_NEW_COURSE } from "store/constants/teacherCourses";

import urls from "../../shared/urls";

export const fetchTeacherCourses = (params) => async (dispatch) => {
  dispatch({
    type: TEACHER_COURSES_REQUEST,
    payload: { teacherCoursesLoading: true },
  });
  try {
    const response = await Axios.get(urls.course.data, {
      params,
    });
    dispatch({ type: TEACHER_COURSES_SUCCESS, payload: response.data.results });
    // dispatch({
    // 	type: "INSTITUTE_ID",
    // 	payload: response.data[0].teacherDetails[0].institute,
    // });
  } catch (error) {
    console.error(error);
  } finally {
    dispatch({
      type: TEACHER_COURSES_FAIL,
      payload: { teacherCoursesLoading: false },
    });
  }
};

export const addCourse = (courseData) => async (dispatch) => {
  dispatch({ type: ADD_NEW_COURSE, payload: courseData });
};
