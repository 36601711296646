import React from "react";
import { Field, ErrorMessage } from "formik";
import { Input, FormGroup } from "reactstrap";
import TextError from "./TextError";

function ChakraInput(props) {
	const { label, name, ...rest } = props;
	return (
		<Field name={name}>
			{({ field, form }) => {
				return (
					<>
						<FormGroup>
							<label htmlFor={name}>{label}</label>
							<Input
								id={name}
								{...rest}
								{...field}
								// onKeyDown={(e) => e.preventDefault()}
								className={
									form.errors[name] && form.touched[name] && "border-danger"
								}
								// className="form-control-alternative"
							/>
							<ErrorMessage component={TextError} name={name} />
						</FormGroup>
					</>
				);
			}}
		</Field>
	);
}

export default ChakraInput;
