import React, { useState, useRef, useEffect } from "react";
// react plugin that prints a given react component
import ReactToPrint from "react-to-print";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
	Search,
	CSVExport,
} from "react-bootstrap-table2-toolkit";
import cellEditFactory from "react-bootstrap-table2-editor";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
// reactstrap components
import {
	Button,
	ButtonGroup,
	Badge,
	Container,
	Row,
	Col,
	UncontrolledTooltip,
} from "reactstrap";

//
import Axios from "axios";
import urls from "shared/urls";
import EditCoupon from "./EditCoupon";

const { ExportCSVButton } = CSVExport;

const pagination = paginationFactory({
	page: 1,
	alwaysShowAllBtns: true,
	showTotal: true,
	withFirstAndLast: false,
	sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
		<div className="dataTables_length" id="datatable-basic_length">
			<label>
				Show{" "}
				{
					<select
						name="datatable-basic_length"
						aria-controls="datatable-basic"
						className="form-control form-control-sm"
						onChange={(e) => onSizePerPageChange(e.target.value)}
					>
						<option value="10">10</option>
						<option value="25">25</option>
						<option value="50">50</option>
						<option value="100">100</option>
					</select>
				}{" "}
				entries.
			</label>
		</div>
	),
});

const { SearchBar } = Search;

const CouponsTable = ({
	select,
	updateTable,
	dataTable,
	loading,
	spinner,

	deleteCoupon,
}) => {
	const componentRef = useRef();
	const [rowsToBeDeleted, setRowsToBeDeleted] = useState([]);

	useEffect(() => {
		setRowsToBeDeleted([]);
	}, [select]);

	const deleteSingleRow = async (rowId) => {
		try {
			await Axios.delete(urls.discountCodes.singleCoupon(rowId));
			deleteCoupon(rowId);
			setRowsToBeDeleted((state) =>
				state.filter((rowData) => rowData.id !== rowId)
			);
		} catch (error) {
			console.error(error);
			// sendNotification("danger", "Couldn't dele")
		}
	};

	const handleDelete = () => {
		rowsToBeDeleted.map((row) => deleteSingleRow(row.id));
	};

	const showDeleteButton = () => {
		if (rowsToBeDeleted.length !== 0) {
			return (
				<>
					<Button
						className="btn-icon btn-3"
						color="danger"
						type="button"
						id="delete-tooltip"
						onClick={handleDelete}
					>
						<span className="btn-inner--icon">
							<i className="fas fa-trash" aria-hidden="true" />
						</span>
						<span className="btn-inner--text">Delete</span>
						<Badge
							color="danger"
							className="badge-md badge-circle badge-floating border-white "
						>
							{rowsToBeDeleted.length}
						</Badge>
					</Button>
					<UncontrolledTooltip placement="top" target="delete-tooltip">
						This will delete all selected rows from the table.
					</UncontrolledTooltip>
				</>
			);
		}
	};

	// this function will copy to clipboard an entire table,
	// so you can paste it inside an excel or csv file
	const copyToClipboardAsTable = (el) => {
		var body = document.body,
			range,
			sel;
		if (document.createRange && window.getSelection) {
			range = document.createRange();
			sel = window.getSelection();
			sel.removeAllRanges();
			try {
				range.selectNodeContents(el);
				sel.addRange(range);
			} catch (e) {
				range.selectNode(el);
				sel.addRange(range);
			}
			document.execCommand("copy");
		} else if (body.createTextRange) {
			range = body.createTextRange();
			range.moveToElementText(el);
			range.select();
			range.execCommand("Copy");
		}
		// setAlert(
		// 	<ReactBSAlert
		// 		success
		// 		style={{ display: "block", margin: "auto" }}
		// 		title="Good job!"
		// 		onConfirm={() => setAlert(null)}
		// 		onCancel={() => setAlert(null)}
		// 		confirmBtnBsStyle="info"
		// 		btnSize=""
		// 	>
		// 		Copied to clipboard!
		// 	</ReactBSAlert>
		// );
	};

	const selectRow = {
		mode: "checkbox",
		clickToSelect: true,
		clickToEdit: true, // Click to edit cell also
		hideEditAll: true,
		// hideSelectAll: true,
		style: { backgroundColor: "#c8e6c9" },
		onSelect: (row, isSelect, rowIndex, e) => {
			if (isSelect) {
				setRowsToBeDeleted((state) => [...state, row]);
				// return setSelected((state) => [...state, row.id]);
			} else {
				setRowsToBeDeleted((state) =>
					state.filter((rowData) => rowData.id !== row.id)
				);
				// setSelected((state) => state.filter((id) => id !== row.id));
			}
		},

		onSelectAll: (isSelect, rows, e) => {
			const ids = rows.map((r) => r.id);

			if (!isSelect) {
				// setSelected([]);
				return setRowsToBeDeleted([]);
			} else {
				// setSelected(ids);
				setRowsToBeDeleted(rows);
			}
		},
	};

	// const cellEdit = cellEditFactory({ mode: "click" });

	const expandRow = {
		parentClassName: "expanding-table-row-header",
		className: "expanding-table-row-body",
		onlyOneExpanding: true,
		showExpandColumn: true,
		nonExpandable: [0],

		renderer: (row) => (
			<EditCoupon updateTable={updateTable} initialValues={{ ...row }} />
		),

		expandHeaderColumnRenderer: () => (
			<b onClick={(e) => e.stopPropagation()}>Edit</b>
		),
		expandColumnRenderer: ({ expanded }) => {
			if (expanded) {
				return (
					<i
						className="fa fa-angle-up text-dark cursor-pointer"
						aria-hidden="true"
					></i>
				);
			}
			return (
				<i
					className="fas fa-edit text-dark cursor-pointer"
					aria-hidden="true"
				></i>
			);
		},
	};
	return (
		<>
			<ToolkitProvider
				data={dataTable}
				keyField="id"
				columns={[
					{
						dataField: "id",
						text: "id",
						sort: true,
						hidden: true,
					},
					{
						dataField: "code",
						text: "Code",
						sort: true,
					},

					{
						dataField: "course.name",
						text: "Course Name",
						sort: true,
					},
					{
						dataField: "plan.name",
						text: "Plan Name",
						sort: true,
					},
					{
						dataField: "valueWithTag",
						text: "Value",
						sort: false,
					},
					{
						dataField: "maxUsage",
						text: "Max uses",
						sort: true,
					},
					{
						dataField: "created",
						text: "Created",
						sort: true,
					},
				]}
				search
				exportCSV
			>
				{(props) => (
					<div className="py-4 overflow-auto">
						<Container fluid>
							<Row>
								<Col xs={12} sm={6}>
									<ButtonGroup>
										<Button
											className="buttons-copy buttons-html5"
											color="info"
											id="copy-tooltip"
											onClick={() =>
												copyToClipboardAsTable(
													document.getElementById("react-bs-table")
												)
											}
										>
											<span>Copy</span>
										</Button>
										<ExportCSVButton
											className="buttons-copy buttons-html5 "
											id="csv-tooltip"
											{...props.csvProps}
										>
											CSV
										</ExportCSVButton>

										<ReactToPrint
											trigger={() => (
												<Button
													href="#"
													color="primary"
													className="buttons-copy buttons-html5"
													id="print-tooltip"
												>
													Print
												</Button>
											)}
											content={() => componentRef.current}
										/>
										{showDeleteButton()}
									</ButtonGroup>
									<UncontrolledTooltip placement="top" target="print-tooltip">
										This will open a print page with the visible rows of the
										table.
									</UncontrolledTooltip>
									<UncontrolledTooltip placement="top" target="csv-tooltip">
										This will download a .csv file with the visible rows of the
										table.
									</UncontrolledTooltip>
									<UncontrolledTooltip placement="top" target="copy-tooltip">
										This will copy to your clipboard the visible rows of the
										table.
									</UncontrolledTooltip>
								</Col>
								<Col xs={12} sm={6}>
									<div
										id="datatable-basic_filter"
										className="dataTables_filter px-4 pb-1 float-right"
									>
										<label>
											Search:
											<SearchBar
												className="form-control-sm"
												placeholder=""
												{...props.searchProps}
											/>
										</label>
									</div>
								</Col>
							</Row>
						</Container>
						{loading ? (
							<div className="d-flex justify-content-center align-items-center w-100 mt-6">
								{spinner}
							</div>
						) : (
							<BootstrapTable
								ref={componentRef}
								{...props.baseProps}
								bootstrap4={true}
								pagination={pagination}
								bordered={false}
								selectRow={selectRow}
								// cellEdit={cellEdit}
								expandRow={expandRow}
								id="react-bs-table"
							/>
						)}
					</div>
				)}
			</ToolkitProvider>
		</>
	);
};

export default CouponsTable;
