import React from "react";
import {
	Card,
	CardHeader,
	CardBody,
	// CardTitle,
	CardText,
	CardFooter,
	Row,
	Col,
	ListGroup,
	Badge,
} from "reactstrap";
import moment from "moment";
import Button from "reactstrap/lib/Button";
import ListGroupItem from "reactstrap/lib/ListGroupItem";
import { useState } from "react";
import Axios from "axios";
import { useEffect } from "react";
import CustomSpinner from "../components/CustomSpinner";
import Collapse from "reactstrap/lib/Collapse";

import { UncontrolledCarousel } from "reactstrap";
import Input from "reactstrap/lib/Input";
import InputGroupAddon from "reactstrap/lib/InputGroupAddon";
import InputGroup from "reactstrap/lib/InputGroup";
import { getAuthInfo } from "shared/helpers";
import { BASE_URL } from "shared/constants";
import urls from "shared/urls";

const Avatar = ({ user = "" }) => {
	return (
		// <img
		// 	className="avatar rounded-circle mx-3"
		// 	alt="..."
		// 	// src={require("assets/img/theme/team-1.jpg")}
		// />
		<span className="avatar rounded-circle mx-3 text-capitalize">
			{user &&
				user
					.split(" ")
					.map((n) => n[0])
					.join("")}
		</span>
	);
};
const SinglePost = ({
	user,
	updated,
	text_content,
	id: postId,
	comments: commentsUrl,
	is_notice,
	post_files,
	deletePost,
}) => {
	const [comments, setComments] = useState(null);
	const [showComments, setShowComments] = useState(false);
	const [textMessage, setTextMessage] = useState("");
	const [attachments, setAttachments] = useState([]);
	const [images, setImages] = useState([]);

	const axios = Axios.create({
		baseURL: BASE_URL,
		headers: {
			Authorization: `Bearer ${getAuthInfo()?.access_token}`,
		},
	});

	useEffect(() => {
		const fetchComments = async () => {
			try {
				const response = await axios.get(commentsUrl);
				setComments(response.data.results);
			} catch (error) {
				console.error(error);
				setComments([]);
			}
		};
		if (showComments) {
			fetchComments();
		}
		// eslint-disable-next-line
	}, [showComments]);

	useEffect(() => {
		if (post_files.length !== 0) {
			setImages(
				post_files
					.filter(
						(file) => file.mime_type !== "pdf"
						// ||
						// file.mime_type !== "epub" ||
						// file.mime_type !== "mobi"
					)
					.map((image) => ({ src: image.url, caption: "" }))
			);
			setAttachments(
				post_files.filter(
					(file) => file.mime_type === "pdf"
					// ||
					// file.mime_type === "epub" ||
					// file.mime_type === "mobi"
				)
			);
		}
	}, [post_files]);

	const sendComment = async () => {
		try {
			const response = await axios.post(commentsUrl, { text: textMessage });
			setComments((prevData) => [response.data, ...prevData]);
			setTextMessage("");
		} catch (error) {
			console.error(error);
			// sendNotification();
		}
	};

	const deleteComment = async (commentId) => {
		try {
			await axios.delete(urls.discussion.deleteSingleComment(commentId));
			setComments((prevData) => prevData.filter(({ id }) => id !== commentId));
			// sendNotification("success", "Deleted Comment successfully");
		} catch (error) {
			console.error(error);
			// sendNotificatison();
		}
	};

	return (
		<>
			<Card>
				<CardHeader className="border-0 pb-0">
					<Row>
						{/* <Col xs={1}> */}
						<Avatar user={user} />
						{/* </Col> */}
						<Row>
							<Col xs={12} className="text-dark text-bold h3 mb-0">
								{user}
							</Col>
							<Col className="text-muted h5">
								{moment(updated).format("DD MMM YYYY")}

								{is_notice ? (
									<span className="text-danger">
										<span className="text-muted">
											{" "}
											&nbsp;&nbsp;•&nbsp;&nbsp;
										</span>
										Notice
									</span>
								) : (
									<span className="text-info">
										<span className="text-muted">
											{" "}
											&nbsp;&nbsp;•&nbsp;&nbsp;
										</span>
										Post
									</span>
								)}
							</Col>
						</Row>
					</Row>
				</CardHeader>
				<CardBody>
					{/* <CardTitle tag="h5">Special Title Treatment</CardTitle> */}
					<CardText>{text_content}</CardText>
					{attachments.length !== 0 && (
						<>
							<h5>Attachments</h5>
							{attachments.map((attachment, i) => (
								<Badge
									color="primary"
									className="badge-default"
									pill
									href={attachment.url}
									target="_blank"
									key={i}
								>
									Attachment {i + 1}
								</Badge>
							))}
							<div className="mb-3"></div>
						</>
					)}
					{images.length > 0 && <UncontrolledCarousel items={images} />}
				</CardBody>
				<CardFooter className="py-2">
					<Row>
						{/* <Col>
							<Button
								className="btn-icon btn-3 border-0"
								color="primary"
								outline
								type="button"
							>
								<span className="btn-inner--icon">
									<i className="ni ni-like-2" />
								</span>
								<span className="btn-inner--text">Like</span>
							</Button>
						</Col> */}
						<Col xs={6}>
							<Button
								className="btn-icon btn-3 border-0 "
								color="info"
								outline
								type="button"
								onClick={() => setShowComments(!showComments)}
							>
								<span className="btn-inner--icon">
									<i className="ni ni-chat-round" />
								</span>
								<span className="btn-inner--text">
									{showComments ? "Hide Comments" : "Show Comments"}
								</span>
							</Button>
						</Col>
						<Col className="d-flex justify-content-center">
							<Button
								className="btn-icon btn-3 border-0"
								color="danger"
								outline
								type="button"
								onClick={() => deletePost(postId)}
							>
								<span className="btn-inner--icon">
									<i className="fas fa-trash" />
								</span>
								<span className="btn-inner--text ">Delete</span>
							</Button>
						</Col>
					</Row>
					<Collapse isOpen={showComments}>
						<ListGroup className="mt-3">
							{comments === null ? (
								<CustomSpinner className="d-flex w-100 justify-content-center" />
							) : comments.length === 0 ? (
								<ListGroupItem className="bg-transparent">
									No comments
								</ListGroupItem>
							) : (
								comments.map(({ user, text, timestamp, id }) => (
									<ListGroupItem className="bg-transparent" key={id}>
										{/* <Row className="align-items-center"> */}
										{/* <Col className="col-auto">
											<img
												alt="avatar"
												className="avatar rounded-circle"
												// src={user.avatar}
											/>
										</Col> */}
										{/* <div className="col"> */}
										<div>
											<div className="d-flex justify-content-between align-items-center">
												<div>
													<h4 className="mb-0 text-sm">{user}</h4>
												</div>
												<div className="text-right text-muted">
													<Button
														color="danger"
														outline
														size="sm"
														type="button"
														onClick={() => deleteComment(id)}
													>
														<span className="btn-inner--icon m-2">
															<i className="fas fa-trash" />
														</span>
														<span className="btn-inner--text ">Delete</span>
													</Button>
													<small>
														{moment(timestamp).startOf("second").fromNow()}
													</small>
												</div>
											</div>
											<p className="text-sm mb-0">{text}</p>
										</div>
										{/* </Row> */}
									</ListGroupItem>
								))
							)}
						</ListGroup>
						<InputGroup className="py-3">
							<Input
								type="textarea"
								placeholder="Write your comment here"
								rows="1"
								value={textMessage}
								onChange={(e) => setTextMessage(e.target.value)}
							/>

							<InputGroupAddon addonType="append">
								<Button
									className="btn-icon btn-2"
									color="info"
									type="button"
									disabled={textMessage.length === 0}
									onClick={sendComment}
								>
									<span className="btn-inner--icon">
										<i className="fab fa-telegram-plane" />
									</span>
								</Button>
							</InputGroupAddon>
						</InputGroup>
					</Collapse>
				</CardFooter>
			</Card>
		</>
	);
};

export default SinglePost;
