import { Form, Formik } from "formik";
import { Button, ModalHeader, Modal } from "reactstrap";
import React from "react";
import FormikControl from "views/pages/components/Formik/FormikControl";
// import Axios from "axios";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import {
	addPlan,
	editPlan,
	// fetchPlans,
} from "store/actions/planActions";

const Courses = ({
	modalValue,
	setModalValue,
	sendNotification,
	planFormData,
	editPlanForm,
	courseId,
}) => {
	const dispatch = useDispatch();

	const onSubmit = async (values) => {
		let formData = { ...values };
		formData.validity_days = values.validity_days.toString();
		formData.price = values.price.toString();

		try {
			if (editPlanForm) {
				return dispatch(
					editPlan(
						formData,
						sendNotification,
						toggle,
						courseId,
						planFormData.id
					)
				);
			} else dispatch(addPlan(formData, sendNotification, toggle, courseId));
		} catch (error) {
			console.error(error);
			sendNotification();
		}
	};
	const toggle = () => setModalValue(!modalValue);

	const initialValues = {
		validity_days: editPlanForm && planFormData.validity_days,
		...planFormData,
	};

	const validationSchema = Yup.object().shape({
		name: Yup.string().min(2, "Too Short!").required("Required"),
		description: Yup.string().min(2, "Too Short!").required("Required"),
		price: Yup.number().min(0).required("Required"),
		validity_days: Yup.number().min(0).required("Required"),
	});
	return (
		<Modal
			isOpen={modalValue}
			toggle={toggle}
			className="modal-dialog-centered"
		>
			<ModalHeader toggle={toggle}>
				{editPlanForm ? "Edit Plan" : "Add New Plan"}
			</ModalHeader>
			<div className="modal-body pt-0">
				<Formik
					initialValues={initialValues}
					onSubmit={onSubmit}
					validationSchema={validationSchema}
					// enableReinitialize
				>
					{(formik) => (
						<Form>
							<FormikControl
								control="input"
								type="text"
								label="Plan Name"
								name="name"
								placeholder="Type Your Plan Name"
							/>
							<FormikControl
								control="input"
								type="text"
								label="Plan Description"
								name="description"
								placeholder="Type Your Plan Description"
							/>
							<FormikControl
								control="customInput"
								type="number"
								label="Price"
								name="price"
								placeholder="199"
								addonType={"prepend"}
								addonText={"₹"}
							/>
							<FormikControl
								control="input"
								type="number"
								label="Validity"
								name="validity_days"
								placeholder="No. of days course will be valid"
							/>

							<div className="modal-footer">
								<Button
									color="primary"
									type="submit"
									disabled={!formik.dirty || formik.isSubmitting}
								>
									Save
								</Button>
								<Button
									className="ml-auto "
									color="outline-primary"
									data-dismiss="modal"
									type="button"
									onClick={() => toggle()}
								>
									Close
								</Button>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</Modal>
	);
};

export default Courses;
