import React, { useState } from "react";
import { Card, CardBody, CardTitle, Button } from "reactstrap";
import FormikControl from "../components/Formik/FormikControl";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Axios from "axios";
import EnrollStudent from "./EnrollStudent";
import urls from "shared/urls";
import moment from "moment";

const Enrollments = ({
	username = "",
	validity = new Date(
		new Date().setFullYear(new Date().getFullYear() + 1)
	).setHours(0, 0, 0, 0),
	radioOption = "",
	radioOptions = [],
	updateEnrollment,
	sendNotification,
}) => {
	const enrollmentSchema = Yup.object().shape({
		username: Yup.string()
			.min(2, "Too Short!")
			.max(50, "Too Long!")
			.required("Required"),
		radioOption: Yup.string().required("Required"),
	});

	const initialValues = {
		username,
		validity,
		radioOption,
	};

	const [modal, setModal] = useState(false);
	const onSubmit = async (values, submitProps) => {
		submitProps.setSubmitting(true);

		const formData = {
			user_phone_number: values.username,
			due_date: moment(values.validity).format("YYYY-MM-DD"),
			course: values.radioOption,
		};
		try {
			const response = await Axios.post(urls.enrollments.create, formData);
			submitProps.resetForm();
			sendNotification("success", "Enrollment added successfully");
			return updateEnrollment(response.data);
		} catch (error) {
			if (error.response) {
				// sendNotification("danger", Object.values(error.response.data));
			}
			// console.log("hello");
			return setModal(true);
		} finally {
			submitProps.setSubmitting(false);
		}
		//  submitProps.resetForm()
	};

	//modal

	return (
		<>
			<Formik
				initialValues={initialValues}
				validationSchema={enrollmentSchema}
				onSubmit={onSubmit}
			>
				{(formik) => {
					return (
						<Form>
							<EnrollStudent
								modalValue={modal}
								phone_number={formik.values.username}
								addEnrollment={formik.handleSubmit}
								setModalValue={(val) => setModal(val)}
								sendNotification={sendNotification}
							/>
							<Card>
								<CardBody>
									<CardTitle className="text-primary text-center display-4 mb-3">
										Add Enrollments
									</CardTitle>

									<FormikControl
										control="input"
										type="text"
										label="Username (Phone number)"
										name="username"
										placeholder="Username (Phone number)"
									/>

									<FormikControl
										control="date"
										label="Valid upto"
										name="validity"
									/>

									<FormikControl
										control="radio"
										label="Course"
										name="radioOption"
										options={radioOptions}
									/>

									<Button
										color="primary"
										className={`text-uppercase d-block mt-3`}
										type="submit"
										block
										disabled={!formik.dirty || formik.isSubmitting}
									>
										Add
									</Button>
								</CardBody>
							</Card>
						</Form>
					);
				}}
			</Formik>
		</>
	);
};

export default Enrollments;
