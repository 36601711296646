import React, { createRef, forwardRef, useEffect, useState } from "react";
import { useFirestore } from "react-redux-firebase";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Input,
  ListGroupItem,
  ListGroup,
  Row,
  Col,
  Button,
  InputGroupAddon,
  InputGroup,
} from "reactstrap";
import _ from "lodash";
import PerfectScrollbar from "react-perfect-scrollbar";
import moment from "moment";
import uuid from "react-uuid";
import { useSelector } from "react-redux";
import CustomSpinner from "views/pages/components/CustomSpinner";
const LiveChat = (props) => {
  const institute = props.location.hash.split("/")[2];
  const blockId = props.location.hash.split("/")[3];
  const firestore = useFirestore();
  const [messages, setMessages] = useState([]);
  const onGoingEvent = useSelector((state) => state.onGoingEvents[blockId]);
  // const [database,setDataMessage] = useState();
  // const database = firestore
  // 	.collection("instiMeta")
  // 	.doc(institute)
  // 	.collection("blockMeta")
  // 	.doc(blockId);

  useEffect(() => {
    if (institute && blockId)
      firestore
        .collection("instiMeta")
        .doc(institute)
        .collection("blockMeta")
        .doc(blockId)
        .onSnapshot((querySnapshot) => {
          if (querySnapshot.data()) {
            setMessages(_.orderBy(querySnapshot.data().comments, "createdAt"));
            const message = querySnapshot.data().comments.map((data, i) => ({
              ...data,
              createdAt:
                typeof data.createdAt == "object"
                  ? moment(data.createdAt.seconds * 1000).format()
                  : data.createdAt,
            }));
            console.log("message", message);
            setMessages(_.orderBy(message, "createdAt"));
          }

          scrollToLatestMessage();
          // chatRef.current && scrollToLatestMessage();

          //console.log(_.orderBy(querySnapshot.data().comments, "createdAt"));
        });
  }, [institute, blockId, firestore]);

  const [chatRef, setChatRef] = useState();
  useEffect(() => {
    scrollToLatestMessage();
  }, [messages, chatRef]);
  const scrollToLatestMessage = () => {
    if (chatRef) {
      chatRef.scrollTop = Number(chatRef.scrollHeight);
    }
  };
  const [textMessage, setTextMessage] = useState("");

  const sendMessage = () => {
    const updatedMessages = [...messages];
    updatedMessages.unshift({
      _id: uuid(),
      createdAt: moment().format(),
      text: textMessage,
      user: {
        _id: 0,
        name: "Teacher",
        avatar: "https://i.ibb.co/TKRMRvF/teacher-Pic.png",
      },
    });

    firestore
      .collection("instiMeta")
      .doc(institute)
      .collection("blockMeta")
      .doc(blockId)
      .set({ comments: updatedMessages }, { merge: true });
    setTextMessage("");
  };

  return (
    <Card
      className="my-3 d-flex"
      style={{
        height: "calc(100vh - 9rem)",
      }}
    >
      <CardHeader>{`Live Chat - ${onGoingEvent?.title ?? ""}`}</CardHeader>
      <PerfectScrollbar
        containerRef={(ref) => {
          setChatRef(ref);
        }}
      >
        <CardBody className="bg-secondary overflow-auto m-0 p-0">
          <ListGroup flush>
            {messages.length === 0 ? (
              <ListGroupItem className="text-center text-primary display-4">
                No messages to show
              </ListGroupItem>
            ) : (
              messages.map((message) => (
                <Message {...message} key={message._id} />
              ))
            )}
          </ListGroup>
        </CardBody>
      </PerfectScrollbar>
      <CardFooter>
        <InputGroup>
          <Input
            type="textarea"
            placeholder="Write your message here"
            rows="1"
            value={textMessage}
            onChange={(e) => setTextMessage(e.target.value)}
          />

          <InputGroupAddon addonType="append">
            <Button
              className="btn-icon btn-2"
              color="primary"
              type="button"
              disabled={textMessage.length === 0}
              onClick={sendMessage}
            >
              <span className="btn-inner--icon">
                <i className="fab fa-telegram-plane" />
              </span>
            </Button>
          </InputGroupAddon>
        </InputGroup>
      </CardFooter>
    </Card>
  );
};

export default LiveChat;

const Message = (props) => {
  console.log("live chat messgaes", props);
  const { text, user, createdAt } = props;

  const getInitials = (name) => {
    if (name) {
      return name
        .match(/(^\S\S?|\b\S)?/g)
        .join("")
        .match(/(^\S|\S$)?/g)
        .join("")
        .toUpperCase();
    }
  };

  const stringToHslColor = (str, s, l) => {
    var hash = 0;
    if (str && str.length > 0) {
      for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }

      var h = hash % 360;
      return "hsl(" + h + ", " + s + "%, " + l + "%)";
    }
  };

  return (
    <ListGroupItem className="">
      <Row className="align-items-center">
        <Col className="col-auto">
          <div
            className="avatar rounded-circle avatar-sm"
            style={{ backgroundColor: stringToHslColor(user.name, 60, 60) }}
          >
            {getInitials(user.name)}
          </div>
        </Col>
        <div className="col ml--3">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h4 className="mb-0 text-sm text-primary text-capitalize">
                {user && user.name && user.name.toLowerCase()}
              </h4>
            </div>
            <div className="text-right text-muted">
              <small className="">
                {createdAt && typeof createdAt == "string"
                  ? moment(createdAt).startOf("second").fromNow()
                  : createdAt && typeof createdAt == "object"
                  ? moment(createdAt.seconds * 1000)
                      .startOf("second")
                      .fromNow(true)
                  : "--"}
              </small>
            </div>
          </div>
          <p className="text-md mb-0 font-weight-bold text-darker">{text}</p>
        </div>
      </Row>
    </ListGroupItem>
  );
};
