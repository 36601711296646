import { combineReducers } from "redux";
import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore";
import enrollmentReducer from "./enrollmentReducer";
import instituteReducer from "./instituteReducer";
import questionReducer from "./questionReducer";
import { onGoingEvents, upcomingEvents } from "./liveEventReducer";
import isLoadingReducer from "./isLoadingReducer";
import plansReducer from "./plansReducer";
import subjectReducer from "./subjectReducer";
import goalsReducer from "./goalsReducer";
import teacherReducer from "./teacherReducer";
import testMetaReducer from "./testMetaReducer";
import recommendedBookReducer from "./recommendedBookReducer";
import libraryListReducer from "./libraryListReducer";

export const rootReducer = combineReducers({
  // auth: authReducer,
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  isLoading: isLoadingReducer,
  teacherCourses: enrollmentReducer,
  subjects: subjectReducer,
  goals: goalsReducer,
  plans: plansReducer,
  teachers: teacherReducer,
  instituteId: instituteReducer,
  testMeta: testMetaReducer,
  questions: questionReducer,
  onGoingEvents: onGoingEvents,
  upcomingEvents: upcomingEvents,
  // accessToken: authReducer,
  recommendedBooks: recommendedBookReducer,
  libraryList: libraryListReducer,
});
