import Axios from "axios";
import urls from "../../shared/urls";
import {
	FETCH_GOALS_FAILED,
	FETCH_GOALS_REQUEST,
	FETCH_GOALS_SUCCESS,
} from "../constants/goalsConstants";
import keyBy from "lodash/keyBy";

const GOALS = keyBy(
	[
		{ id: "school_exams", value: "School Exams" },
		{ id: "engineering_entrance_exams", value: "Engineering Entrance Exams" },
		{ id: "medical_entrance_exams", value: "Medical Entrance Exams" },
		{ id: "ssc_and_railway_exams", value: "SSC & Railway Exams" },
		{ id: "bank_and_insurance_exams", value: "Bank & Insurance Exams" },
		{ id: "police_exams", value: "Police Exams" },
		{ id: "ctet_and_teaching_jobs", value: "CTET & Teaching Jobs" },
		{ id: "cat_and_mba_exams", value: "CAT & MBA Exams" },
		{ id: "upsc_state_services", value: "UPSC & State Services" },
		{ id: "net", value: "NET" },
		{ id: "clat_and_other_law_exams", value: "CLAT and other law Exams" },
		{ id: "cds_and_defence_exams", value: "CDS & Defence Exams" },
		{ id: "sslc_kannada_medium", value: "SSLC Kannada Medium" },
		{ id: "sslc_english_medium", value: "SSLC English Medium" },
	],
	"id"
);

export const fetchGoals = (payload) => async (dispatch) => {
	try {
		dispatch({ type: FETCH_GOALS_REQUEST });
		const response = await Axios(urls.goals.fetchGoals);
		dispatch({
			type: FETCH_GOALS_SUCCESS,
			payload: response.data.results.map((item) => ({
				...item,
				name: GOALS[item.name]?.value ?? item.name.split("_").join(" "),
			})),
		});
	} catch (error) {
		dispatch({ type: FETCH_GOALS_FAILED });
	}
};
