import React from "react";
import { Button } from "reactstrap";
import FormikControl from "../components/Formik/FormikControl";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Modal from "reactstrap/lib/Modal";
import ModalHeader from "reactstrap/lib/ModalHeader";
import Axios from "axios";
import urls from "shared/urls";

const EnrollStudent = ({
	modalValue,
	setModalValue,
	sendNotification,
	phone_number,
	addEnrollment,
}) => {
	const phoneRegex = RegExp(
		/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
	);
	const enrollmentSchema = Yup.object().shape({
		first_name: Yup.string()
			.min(2, "Too Short!")
			.max(50, "Too Long!")
			.required("Required"),
		last_name: Yup.string()
			.min(2, "Too Short!")
			.max(50, "Too Long!")
			.required("Required"),
		// email: Yup.string().email("Invalid email").required("Required"),
		// gender: Yup.string().required("Required"),
		phone_number: Yup.string()
			.matches(phoneRegex, "Phone number is not valid")
			.required("Required"),
	});
	const initialValues = {
		first_name: "",
		last_name: "",
		// email: "",
		phone_number,
		// gender: "Male",
	};

	const onSubmit = async (values, submitProps) => {
		submitProps.setSubmitting(true);
		try {
			await Axios.post(urls.student.create, values);
			submitProps.resetForm();
			sendNotification("success", "Successfully added ");
			toggle(false);
			addEnrollment();
		} catch (error) {
			console.error(error.message, "failed");
			if (error.response) {
				sendNotification(
					"danger",
					Object.values(error.response.data?.error?.message)
				);
			} else sendNotification();
		}
		submitProps.setSubmitting(false);
	};
	const toggle = () => setModalValue(!modalValue);

	return (
		<Modal
			isOpen={modalValue}
			toggle={toggle}
			className="modal-dialog-centered"
		>
			<ModalHeader toggle={toggle}>Add Student</ModalHeader>
			<div className="modal-body pt-0">
				<Formik
					initialValues={initialValues}
					validationSchema={enrollmentSchema}
					onSubmit={onSubmit}
				>
					{(formik) => (
						<Form>
							<p className="lead text-dark mt-0">
								Student does not exist! Please add the student details.
							</p>
							<FormikControl
								control="input"
								type="text"
								label="First Name"
								name="first_name"
								placeholder="First Name"
							/>
							<FormikControl
								control="input"
								type="text"
								label="Last Name"
								name="last_name"
								placeholder="Last Name"
							/>
							{/* <FormikControl
								control="input"
								type="email"
								label="Email"
								name="email"
								placeholder="Email"
							/> */}
							<FormikControl
								control="input"
								type="tel"
								label="Phone Number"
								name="phone_number"
								placeholder="Phone Number"
							/>

							{/* <FormikControl
								control="radio"
								label="Gender"
								name="gender"
								options={genderOptions}
							/> */}
							<div className="modal-footer">
								<Button
									color="primary"
									type="submit"
									disabled={!formik.dirty || formik.isSubmitting}
								>
									Add Student
								</Button>
								<Button
									className="ml-auto "
									color="outline-primary"
									data-dismiss="modal"
									type="button"
									onClick={() => toggle(false)}
								>
									Close
								</Button>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</Modal>
	);
};

export default EnrollStudent;
