import React, { useState, useEffect } from "react";
import { Button, Card, CardBody, CardTitle, Col, Container } from "reactstrap";
import EnrollmentForm from "./EnrollmentForm";
import EnrolledStudentsTable from "./EnrolledStudentsTable";
import ReactSelect from "../components/SelectCourse";
import _ from "lodash";
import { useSelector } from "react-redux";

import Axios from "axios";
import axios from "shared/Axios";
import moment from "moment";

import "./enrollments.css";
import CustomSpinner from "../components/CustomSpinner";
import urls from "shared/urls";

import keyBy from "lodash/keyBy";
import omit from "lodash/omit";
import { BASE_URL } from "shared/constants";
import { getAuthInfo } from "shared/helpers";

import NotificationAlert from "react-notification-alert";

const Enrollments = ({ sendNotification }) => {
  //dropdown select option
  const [select, setSelect] = useState(0);
  const [dropDownArray, setDropDownArray] = useState([]);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(0);
  const [totalSize, setTotalSize] = useState(0);
  const instituteId = useSelector((state) => state.instituteId);

  const teacherCourses = useSelector((state) =>
    Object.values(state.teacherCourses)
  );
  const teacherCoursesLoading = useSelector(
    (state) => state.isLoading.teacherCoursesLoading
  );
  const dropDownModelArray = (array) => {
    const data = array.map((item) => {
      return _.mapKeys(_.pick(item, ["id", "name"]), (value, key) => {
        if (key.includes("name")) {
          return "text";
        }
        return key;
      });
    });

    return setDropDownArray([...data, { id: 0, text: "All courses" }]);
  };

  const modelTableEntries = (data) => {
    return {
      username: data.user.phone_number, //phone number
      name: data.user.name,
      start_date: moment(data.timestamp).format("DD MMM YYYY"),
      end_date:
        data.due_date == null
          ? moment().format("DD MMM YYYY")
          : moment(data.due_date).format("DD MMM YYYY"),
      id: data.id,
      course: data.course,
    };
  };

  const fetchEnrollments = React.useCallback(
    async (page, searchText) => {
      setLoadingTable(true);
      const params = { page };

      if (searchText) {
        params.q = searchText;
      }
      if (!searchText) {
        params.q = "";
      }
      if (select !== "0") {
        params.c_id = select; // select all courses has id:0 for all
      }

      try {
        const response = await Axios.get(urls.enrollments.list, {
          params,
        });
        const table = response.data.results.map((data1) =>
          modelTableEntries(data1)
        );
        table.sort((a, b) => moment(a.start_date).isBefore(b.start_date));
        setTotalSize(response.data.count);
        setLoadingTable(false);
        page && setPage(page);
        setDataTable(keyBy(table, "id"));
      } catch (error) {
        console.error(error);
      }
    },
    [select]
  );

  const onTableChange = (type, { page, sizePerPage, filters, searchText }) => {
    // console.log(type, { page, sizePerPage, filters, searchText });
    if (page && type.includes("search")) return fetchEnrollments(1, searchText);
    if (type.includes("pagination")) fetchEnrollments(page);
    else return;
  };

  useEffect(() => {
    select && fetchEnrollments(1);
  }, [select, fetchEnrollments]);

  useEffect(() => {
    dropDownModelArray(teacherCourses);
    //setting first course as default option in select
    teacherCourses[0] && setSelect(teacherCourses[0].id);
    // eslint-disable-next-line
  }, [teacherCoursesLoading]);

  //sorting the data and setting table
  const [loadingTable, setLoadingTable] = useState(false);
  const [dataTable, setDataTable] = useState([]);

  const deleteEnrollment = (id) => {
    setDataTable((prevData) => omit(prevData, id));
  };

  const updateEnrollment = (data) => {
    setDataTable({
      ...dataTable,
      [data.id]: modelTableEntries(data),
    });
  };

  const csvFileChangeHandler = (e) => {
    // console.log(e.target.files[0]);
    const csvFile = new FormData();
    csvFile.append("csv_file", e.target.files[0]);
    axios
      .post("/bulk/upload-csv/", csvFile)
      .then((res) => {
        sendNotification("success", res.data.message);

        // if (res.status) {
        //   setTimeout(() => {
        //     window.location.reload();
        //   }, 1000)
        // }
        return true;
      })
      .catch((e) =>
        sendNotification(
          "danger",
          `Something went wrong. 
      Please check the date format, course ID, and mobile number. 
      Ensure that the mobile number does not exceed 10 digits.`
        )
      );
  };

  return teacherCoursesLoading ? (
    <Container
      fluid
      className="d-flex justify-content-center align-items-center  w-100"
      style={{ height: "80vh" }}
    >
      <CustomSpinner />
    </Container>
  ) : (
    <Col xs={12} lg={9}>
      {/* <NotificationAlert ref={notify} /> */}

      <EnrollmentForm
        radioOptions={teacherCourses}
        sendNotification={sendNotification}
        updateEnrollment={updateEnrollment}
      />
      <Card className="mt-3">
        <CardBody>
          <CardTitle className="text-primary text-center display-4 position-relative">
            <div className="header-btns left position-relative upload-csv-wrapper position-md-absolute d-flex align-items-center p-0">
              <input
                id="upload-csv-btn"
                type="file"
                onChange={csvFileChangeHandler}
                className="choose-file-csv btn btn-primary btn-md"
                accept={".csv"}
              />
              <label
                htmlFor="upload-csv-btn"
                size="md"
                color="primary"
                className="choose-file-csv w-100 h-100"
              ></label>
              <Button
                size="md"
                color="primary"
                className="upload-csv-btn w-100"
              >
                Upload csv
              </Button>
            </div>
            <Button
              className="header-btns right position-md-absolute right-3 top-0 d-flex align-items-center justify-content-center p-0 pr-2"
              size="md"
              color="primary"
              href={`${BASE_URL}/institutes/${instituteId}${
                urls.enrollments.all
              }/?authToken=${getAuthInfo().access_token}`}
              disabled={!getAuthInfo().access_token || !instituteId}
              target="_blank"
            >
              <span className="btn-inner--icon m-2">
                <i className="ni ni-cloud-download-95 " />
              </span>
              <span className="btn-inner--text ">Download all users</span>
            </Button>
            View Enrollments
          </CardTitle>
          <ReactSelect
            dropDownArray={dropDownArray}
            select={select}
            handleOnChange={(id) => setSelect(id)}
            label={"Select Course"}
          />

          <EnrolledStudentsTable
            select={select}
            updateEnrollment={updateEnrollment}
            instituteId={instituteId}
            dataTable={Object.values(dataTable)}
            loading={loadingTable}
            spinner={<CustomSpinner />}
            sendNotification={sendNotification}
            deleteEnrollment={deleteEnrollment}
            totalSize={totalSize}
            onTableChange={onTableChange}
            page={page}
          />
        </CardBody>
      </Card>
    </Col>
  );
};

export default Enrollments;
