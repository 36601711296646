import keyBy from "lodash/keyBy";
import { USER_LOGOUT } from "store/constants/authConstants";
import { FETCH_GOALS_SUCCESS } from "../constants/goalsConstants";

const initialState = {};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case FETCH_GOALS_SUCCESS:
			return keyBy(payload, "id");
		case USER_LOGOUT:
			return initialState;

		default:
			return state;
	}
};
