import React from "react";
import { Field, ErrorMessage } from "formik";

import TextError from "./TextError";
import {
	FormGroup,
	InputGroup,
	InputGroupAddon,
	Input,
	InputGroupText,
} from "reactstrap";

function ChakraInput(props) {
	const { label, name, addonType, addonText, ...rest } = props;
	return (
		<Field name={name}>
			{({ field, form }) => (
				<>
					<FormGroup>
						<label htmlFor={name}>{label}</label>
						<InputGroup>
							<InputGroupAddon addonType={addonType}>
								<InputGroupText>{addonText}</InputGroupText>
							</InputGroupAddon>
							<Input
								id={name}
								{...rest}
								{...field}
								className={
									form.errors[name] && form.touched[name] && "border-danger"
								}
							/>
						</InputGroup>
						<ErrorMessage component={TextError} name={name} />
					</FormGroup>
				</>
			)}
		</Field>
	);
}

export default ChakraInput;
