import Axios from "axios";
import urls from "../../shared/urls";
import {
	FETCH_TEACHERS_FAILED,
	FETCH_TEACHERS_REQUEST,
	FETCH_TEACHERS_SUCCESS,
} from "../constants/teacherConstants";

export const fetchTeachers = (payload) => async (dispatch) => {
	try {
		dispatch({ type: FETCH_TEACHERS_REQUEST });
		const response = await Axios(urls.teachers.data);
		dispatch({ type: FETCH_TEACHERS_SUCCESS, payload: response.data.results });
	} catch (error) {
		dispatch({ type: FETCH_TEACHERS_FAILED });
	}
};
