import { clearAuthInfo } from "shared/helpers";
import { USER_LOGIN_REQUEST } from "store/constants/authConstants";
import { USER_LOGOUT } from "store/constants/authConstants";
import { USER_LOGIN_FAIL } from "store/constants/authConstants";
import { USER_LOGIN_SUCCESS } from "store/constants/authConstants";
import history from "store/history";

export const signIn = (email, password) => {
	return (dispatch, getState, getFirebase) => {
		const firebase = getFirebase();

		dispatch({
			type: USER_LOGIN_REQUEST,
		});
		firebase
			.auth()
			.signInWithEmailAndPassword(email, password)
			.then(() => {
				dispatch({
					type: USER_LOGIN_SUCCESS,
				});
				history.push("/admin/enrollments");
			})
			.catch((err) => {
				dispatch({ type: USER_LOGIN_FAIL, payload: err.message });
			});
	};
};

export const signOut = () => {
	return (dispatch) => {
		history.push("/auth");
		clearAuthInfo();
		dispatch({ type: USER_LOGOUT });
	};
};
