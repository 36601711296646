import React from "react";
import { css } from "@emotion/core";
import SyncLoader from "react-spinners/SyncLoader";

const CustomSpinner = ({ size, margin, color, className }) => {
	const override = css`
		display: block;
		margin: 10px;
		border-color: red;
	`;

	return (
		<div className={className}>
			<SyncLoader
				css={override}
				size={size || 50}
				margin={margin || 10}
				color={color || "#5e72e4"}
				loading={true}
			/>
		</div>
	);
};

export default CustomSpinner;
