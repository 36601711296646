import React from "react";
import axios from "shared/Axios";
import { RECOMMENDED_BOOKS } from "store/constants/addBooksConstants";
import { DELETE_RECOMMENDED_BOOKS } from "store/constants/deleteBooksConstants";
import { GETUSERDETAILS } from "store/constants/getUserDeatils";

export const addBook = (data, callback) => (dispatch) => {
  axios.post("/recommended-books/", data).then((res) => {
    if (res.status === 200) {
      callback()
      getRecommendedBooks()(dispatch)
    }
  });
};
export const updateBook = (data, id, callback) => (dispatch) => {
  axios.put(`/recommended-books/${id}/`, data).then((res) => {
    if (res.status === 200) {
      callback()
      getRecommendedBooks()(dispatch)
    }
  });
};

export const getRecommendedBooks = () => (dispatch) => {
  axios.get("/recommended-books/").then((res) => {
    console.log(res);
    dispatch({ type: RECOMMENDED_BOOKS, payload: res.data });
  });
};
export const deleteRecommendedBooks = (id, callback) => (dispatch) => {
  axios.delete(`/recommended-books/${id}/`).then((res) => {
    if (res.status === 200) {
      callback();
    }
  });
}
export const userDetailsData = (payload) => (dispatch) => {
  axios.get('/user-logs/list/').then((res) => {
    console.log("userDeatilsData", res);
    dispatch({ type: GETUSERDETAILS, payload: res.data });

  });
};



