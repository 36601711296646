import React, { useState, useEffect } from "react";
// react plugin used to create DropdownMenu for selecting items
import Select2 from "react-select2-wrapper";
// reactstrap components
import { FormGroup } from "reactstrap";
const Dropdown = ({ dropDownArray, select, handleOnChange, label }) => {
	return (
		<>
			<FormGroup className="my-2 mt-4">
				<label htmlFor={label}>{label}</label>
				<Select2
					className="form-control"
					value={select}
					data={dropDownArray}
					options={{
						placeholder: "Select Course",
					}}
					onChange={(e) => handleOnChange(e.target.value)}
				/>
			</FormGroup>
		</>
	);
};

Dropdown.defaultProps = {
	dropDownArray: [],
	select: null,
};
export default Dropdown;
