import React from "react";
import ListUpcomingEvent from "./ListUpcomingEvent";
import UpcomingEventForm from "./UpcomingEventForm";

const UpcomingEvents = (props) => {
	const event = props.location.hash.split("/")[1];
	const institute = props.location.hash.split("/")[2];
	const blockId = props.location.hash.split("/")[3];
	const { sendNotification } = props;

	const handleRouting = () => {
		if (event && institute && blockId) {
			return (
				<UpcomingEventForm
					blockId={blockId}
					institute={institute}
					sendNotification={sendNotification}
				/>
			);
		} else {
			return <ListUpcomingEvent sendNotification={sendNotification} />;
		}
	};

	return handleRouting();
};

export default UpcomingEvents;
