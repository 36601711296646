import { Form, Formik } from "formik";
import React from "react";
import Button from "reactstrap/lib/Button";
import FormikControl from "../components/Formik/FormikControl";
// import { useDropzone } from "dropzone";
// import Dropzones from "./Dropzones";
import urls from "shared/urls";
import Axios from "axios";
const AddNewPost = ({ closeForm, updatePosts, componentName }) => {
	const onSubmit = async (values, submitProps) => {
		const formData = new FormData();
		formData.append("text_content", values.text_content);
		formData.append("is_notice", JSON.parse(values.is_notice));

		Object.values(values.files).map((file) => formData.append("files", file));

		try {
			const response = await Axios.post(urls.discussion.list, formData);
			updatePosts(response.data);
			// sendNotification("success", "Post Added");
			closeForm();
		} catch (error) {
			// sendNotification();
		} finally {
			submitProps.setSubmitting(false);
			submitProps.resetForm();
		}
	};

	return (
		<Formik
			initialValues={{
				text_content: "",
				files: [],
				is_notice: componentName.includes("notice"),
			}}
			enableReinitialize
			onSubmit={onSubmit}
		>
			{(formik) => (
				<Form className="mt-3">
					<FormikControl
						name="text_content"
						type="textarea"
						control="input"
						label="Content"
					/>
					<FormikControl
						name="files"
						control="file"
						label="Files (Only Images and PDF)"
						multiple
						accept="image/jpeg,image/png,application/pdf"
						onChange={(e) =>
							formik.setFieldValue("files", e.currentTarget.files)
						}
					/>

					<div className="d-flex justify-content-between">
						<Button
							type="submit"
							className="mt-4"
							color="primary"
							disabled={formik.isSubmitting || !formik.dirty}
						>
							Submit
						</Button>
						<Button
							type="button"
							className="mt-4"
							color="primary"
							outline
							onClick={closeForm}
						>
							Cancel
						</Button>
					</div>
				</Form>
			)}
		</Formik>
	);
};

// const UploadComponent = (props) => {
// 	const { setFieldValue } = props;
// 	const { getRootProps, getInputProps, isDragActive } = useDropzone({
// 		accept: "image/*",
// 		onDrop: (acceptedFiles) => {
// 			setFieldValue("files", acceptedFiles);
// 		},
// 	});
// 	return (
// 		<div>
// 			{}
// 			<div {...getRootProps({ className: "dropzone" })}>
// 				<input {...getInputProps()} />
// 				{isDragActive ? (
// 					<p>Drop the files here ...</p>
// 				) : (
// 					<p>Drag 'n' drop some files here, or click to select files</p>
// 				)}
// 			</div>
// 		</div>
// 	);
// };

export default AddNewPost;
