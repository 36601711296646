import Axios from "axios";
import { INSTITUTE_ID } from "shared/constants";
import urls from "shared/urls";
import history from "store/history";
import {
	ONGOING_EVENTS_REQUESTED,
	ONGOING_EVENTS_SUCCESS,
	ONGOING_EVENTS_FAILED,
	ONGOING_EVENT_REMOVED_REQUESTED,
	ONGOING_EVENT_REMOVED,
	ONGOING_EVENT_ADDED,
	UPCOMING_EVENTS_REQUESTED,
	UPCOMING_EVENTS_SUCCESS,
	UPCOMING_EVENTS_FAILED,
	UPCOMING_EVENT_REMOVED_REQUESTED,
	UPCOMING_EVENT_REMOVED,
} from "../constants/liveEventConstants";

export const fetchOngoingEvents = () => async (dispatch) => {
	dispatch({ type: ONGOING_EVENTS_REQUESTED });

	try {
		const response = await Axios.get(urls.liveEvents.list, {
			params: {
				st: "current",
			},
		});
		dispatch({ type: ONGOING_EVENTS_SUCCESS, payload: response.data.results });
	} catch (error) {
		dispatch({ type: ONGOING_EVENTS_FAILED });
	}
};

export const editOnGoingBlock = (data) => async (dispatch) => {
	const { blockId, params, ...formData } = data;

	dispatch({ type: ONGOING_EVENT_REMOVED_REQUESTED });
	try {
		await Axios.post(urls.liveEvents.singleLiveSession(blockId), formData, {
			params,
		});
		// console.log(response);

		dispatch({ type: ONGOING_EVENT_REMOVED, payload: blockId });
		history.push("/admin/live-events");
	} catch (error) {
		console.error(error);
	}
};

export const fetchUpcomingEvents = () => async (dispatch) => {
	dispatch({ type: UPCOMING_EVENTS_REQUESTED });

	try {
		const response = await Axios.get(urls.liveEvents.list, {
			params: {
				st: "upcoming",
			},
		});
		dispatch({ type: UPCOMING_EVENTS_SUCCESS, payload: response.data.results });
	} catch (error) {
		dispatch({ type: UPCOMING_EVENTS_FAILED });
	}
};

export const editUpcomingBlock = (data) => async (dispatch, getState) => {
	dispatch({ type: UPCOMING_EVENT_REMOVED_REQUESTED });
	const upcoming = getState().upcomingEvents;
	const { blockId, params, ...formData } = data;
	try {
		await Axios.post(urls.liveEvents.singleLiveSession(blockId), formData, {
			params,
		});
		const eventToBeSent = {
			...upcoming[blockId],
			...formData,
		};
		eventToBeSent.start_time = new Date();
		dispatch({
			type: ONGOING_EVENT_ADDED,
			payload: { [blockId]: eventToBeSent },
		});
		dispatch({
			type: UPCOMING_EVENT_REMOVED,
			payload: blockId,
		});
		history.push(`/admin/live-events/#/o/${INSTITUTE_ID}/${blockId}`);
	} catch (error) {}
};
