import { USER_LOGOUT } from "store/constants/authConstants";
import { USER_LOGIN_REQUEST } from "store/constants/authConstants";
import { UPCOMING_EVENTS_REQUESTED } from "store/constants/liveEventConstants";
import { ONGOING_EVENT_REMOVED_REQUESTED } from "store/constants/liveEventConstants";
import { UPCOMING_EVENT_REMOVED_REQUESTED } from "store/constants/liveEventConstants";
import { ONGOING_EVENTS_REQUESTED } from "store/constants/liveEventConstants";
import { ADD_QUESTION_REQUEST } from "store/constants/questionConstants";
import { DELETE_QUESTION_REQUEST } from "store/constants/questionConstants";
import { FETCH_QUESTIONS_REQUEST } from "store/constants/questionConstants";
import { TEACHER_COURSES_FAIL } from "store/constants/teacherCourses";
import { TEACHER_COURSES_REQUEST } from "store/constants/teacherCourses";

const initialState = {
	teacherCoursesLoading: false,
	login: false,
	questions: false,
	onGoingEvents: false,
	upcomingEvents: false,
};
export default (state = initialState, { type, payload }) => {
	switch (type) {
		case TEACHER_COURSES_REQUEST:
		case TEACHER_COURSES_FAIL:
		case USER_LOGIN_REQUEST:
		case FETCH_QUESTIONS_REQUEST:
		case DELETE_QUESTION_REQUEST:
		case ADD_QUESTION_REQUEST:
		case ONGOING_EVENTS_REQUESTED:
		case ONGOING_EVENT_REMOVED_REQUESTED:
		case UPCOMING_EVENTS_REQUESTED:
		case UPCOMING_EVENT_REMOVED_REQUESTED:
			return { ...state, ...payload };
		case USER_LOGOUT:
			return initialState;
		default:
			return state;
	}
};
