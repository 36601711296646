import { Form, Formik } from "formik";
import { Button } from "reactstrap";
import React, { useEffect, useState } from "react";
import FormikControl from "views/pages/components/Formik/FormikControl";
import Axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import moment from "moment";

import EditAddPlanForm from "./EditAddPlanForm";
import { fetchTeacherCourses } from "store/actions/enrollmentActions";
import { courseTypeOptions } from "views/pages/components/courseTypeOptions";
import { fetchPlans } from "store/actions/planActions";
import { fetchSubjects } from "store/actions/subjectActions";
import { fetchGoals } from "store/actions/goalActions";
import { fetchTeachers } from "store/actions/teacherActions";
import urls from "shared/urls";

import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import DeleteModal from "views/pages/components/DeleteModal";
import { useHistory, useLocation } from "react-router";
import { emptyPlans } from "store/actions/planActions";
import { deletePlan } from "store/actions/planActions";
import useQuery from "hooks/useQuery";

const EditCourse = (props) => {
  const { id, sendNotification } = props;
  const teacherCourse = useSelector((state) => state.teacherCourses[id]);
  const [showEndDate, setShowEndDate] = useState(teacherCourse.end_time);
  const plans = useSelector((state) => Object.values(state.plans));
  const subjects = useSelector((state) => Object.values(state.subjects));
  const goals = useSelector((state) => Object.values(state.goals));
  const teachers = useSelector((state) => Object.values(state.teachers));

  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(fetchSubjects());
    dispatch(fetchGoals());
    dispatch(fetchTeachers());
    dispatch(fetchPlans(id));
    return () => {
      dispatch(emptyPlans());
    };
  }, [dispatch, id]);

  const initialValues = {
    course_type: "",
    name: "",
    preview_image: "",
    // expires: false,
    plans: [],
    ...teacherCourse,
    course_introduction_text:
      teacherCourse?.course_introduction?.text_content ?? "",
    course_intro_url: teacherCourse?.course_introduction?.intro_url ?? "",

    start_time: new Date(teacherCourse?.start_time ?? new Date()),
    end_time: new Date(teacherCourse?.end_time ?? new Date()),

    goal_ids: teacherCourse?.goals.map(({ id }) => id) ?? [],
    teacher_ids: teacherCourse?.teachers.map(({ id }) => id.toString()) ?? [],
    subject_ids: teacherCourse?.subjects.map(({ id }) => id) ?? [],
  };

  const is_freeOptions = [
    { id: true, name: "Free" },
    { id: false, name: "Paid" },
  ];

  // const dispatch = useDispatch();
  const [modalDelete, setModalDelete] = useState(false);
  const toggleDelete = () => setModalDelete(!modalDelete);

  const onSubmit = async (values) => {
    const formData = new FormData();
    typeof values.preview_image !== "string" &&
      formData.append("preview_image", values.preview_image);

    formData.append("is_free", JSON.parse(values.is_free));
    formData.append("name", values.name);
    formData.append(
      "course_introduction_text",
      values.course_introduction_text
    );
    formData.append("course_type", values.course_type);
    formData.append("start_time", moment(values.start_time).toISOString());
    if (showEndDate) {
      formData.append("end_time", moment(values.end_time).toISOString());
    }
    formData.append("course_intro_url", values.course_intro_url);
    values.goal_ids.map((item) => formData.append("goal_ids", item));
    values.subject_ids.map((item) => formData.append("subject_ids", item));
    values.teacher_ids.map((item) => formData.append("teacher_ids", item));

    try {
      await Axios.put(urls.course.singleCourse(id), formData);
      sendNotification("success", "Success");
      dispatch(fetchTeacherCourses());
    } catch (error) {
      console.error(error);
      if (error.response) {
        sendNotification(
          "danger",
          Object.values(error.response.data).toString()
        );
      } else sendNotification();
    }
  };

  const validationSchema = Yup.object().shape({
    // course_id: Yup.string().required("Required"),
    course_type: Yup.string().required("Required"),
    course_introduction_text: Yup.string().required("Required"),
    name: Yup.string().required("Required"),
    preview_image: Yup.string().nullable().required("Required"),
    // expires: false,
    goal_ids: Yup.string().required("Required"),
    // is_free: false,
    // plans: Yup.string().required("Required"),
    // start_time: new Date(),
    subject_ids: Yup.string().required("Required"),
    teacher_ids: Yup.string().required("Required"),
  });

  const [modal, setModal] = useState(false);
  const [planFormData, setPlanFormData] = useState({});
  const [editPlanForm, setEditPlanForm] = useState(false);

  const query = useQuery();
  const location = useLocation();

  useEffect(() => {
    if (query.get("edit-plan")) {
      setModal(true);
      setEditPlanForm(false);
      history.replace(location.pathname + location.hash);
    }
  }, [query, history, location]);

  const handleEditPlan = (data) => {
    setEditPlanForm((s) => !s);
    setPlanFormData(data);
    setModal((s) => !s);
  };

  const handleDeletePlan = (planId) => {
    dispatch(deletePlan(id, planId, sendNotification));
  };

  const handleDelete = async (data) => {
    try {
      await Axios.delete(urls.course.singleCourse(id));
      sendNotification("success", "Successfully Delete");
      dispatch(fetchTeacherCourses());
      history.goBack(-1);
    } catch (error) {
      if (error.response) {
        sendNotification(
          "danger",
          Object.values(error.response.data).toString()
        );
      } else sendNotification();
    }
  };

  return (
    <>
      <EditAddPlanForm
        modalValue={modal}
        setModalValue={(val) => setModal(val)}
        sendNotification={sendNotification}
        planFormData={planFormData}
        editPlanForm={editPlanForm}
        courseId={id}
      />

      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {(formik) => {
          // console.log(formik);
          return (
            <Form>
              <DeleteModal
                isOpen={modalDelete}
                handleDelete={handleDelete}
                toggleDelete={toggleDelete}
                name={formik.values.name}
              />
              <FormikControl
                control="input"
                type="text"
                label="Course Name"
                name="name"
                placeholder="Course Name"
              />
              <FormikControl
                control="input"
                type="textarea"
                rows="6"
                label="Course Description"
                placeholder="Course Description"
                name="course_introduction_text"
              />
              <FormikControl
                control="input"
                type="text"
                label="Course Demo Video"
                placeholder="An introduction video for course"
                name="course_intro_url"
              />
              <FormikControl
                control="checkbox"
                label="Teachers"
                name="teacher_ids"
                options={teachers}
              />
              <FormikControl
                control="checkbox"
                label="Goals"
                name="goal_ids"
                options={goals}
              />
              <FormikControl
                control="radio"
                label={"Course Type"}
                name="course_type"
                options={courseTypeOptions}
              />
              {!JSON.parse(initialValues.is_free || false) && (
                <>
                  <div className="d-flex align-items-center">
                    <label htmlFor="plans" className="mb-0 d-block">
                      Plans
                    </label>
                    <Button
                      size="md"
                      color="primary"
                      className="ml-auto"
                      onClick={() => {
                        setModal(true);
                        setEditPlanForm(false);
                      }}
                    >
                      Add New Plan
                    </Button>
                  </div>
                  <FormikControl
                    control="customCheckBox"
                    label={null}
                    name="plans"
                    options={plans}
                    disabled
                    editPlan={handleEditPlan}
                    deletePlan={handleDeletePlan}
                  />
                </>
              )}
              <FormikControl
                control="radio"
                label={"Type"}
                name="is_free"
                options={is_freeOptions}
              />
              <Row>
                <img
                  src={
                    (formik.values.preview_image &&
                      typeof formik.values.preview_image !== "string" &&
                      URL.createObjectURL(formik.values.preview_image)) ||
                    teacherCourse?.preview_image
                  }
                  className="avatar avatar-xl bg-transparent mr-3 ml-3"
                  alt="course_image"
                />
                <Col>
                  <FormikControl
                    control="file"
                    name="preview_image"
                    label="Add New Thumbnail"
                  />
                </Col>
              </Row>
              <FormikControl
                control="date"
                label="Start Date"
                name="start_time"
              />
              <Button
                className="btn-icon btn-3 mb-3"
                color={showEndDate ? "danger" : "primary"}
                type="button"
                onClick={() => setShowEndDate((s) => !s)}
                aria-expanded={showEndDate}
              >
                <span className="btn-inner--text">
                  {showEndDate ? "Remove End Date" : "Add End Date"}
                </span>
                <span className="btn-inner--icon">
                  <i
                    className={`fas ${showEndDate ? "fa-minus" : "fa-plus"}`}
                  />
                </span>
              </Button>
              {showEndDate && (
                // <Fade in={showEndDate}>
                <FormikControl
                  control="date"
                  label="End Date"
                  name="end_time"
                />
                // </Fade>
              )}
              <FormikControl
                control="checkbox"
                label={"Subjects"}
                name="subject_ids"
                options={subjects}
              />
              <Button
                type="submit"
                className="w-50"
                color="primary"
                // onClick={formik.handleSubmit}
                disabled={formik.isSubmitting || !formik.dirty}
              >
                Submit
              </Button>
              <Button
                color="danger"
                className="float-right"
                onClick={toggleDelete}
              >
                Delete
              </Button>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default EditCourse;
