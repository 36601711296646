import { FREE_LIBRARY } from "store/constants/addLibraryConstants";

const initialState = {};

const libraryListReducer = (state = initialState, action) => {
  switch (action.type) {
    case FREE_LIBRARY:
      console.log("recommendedBooks", action.payload);
      return action.payload;
    default:
      return state;
  }
};

export default libraryListReducer;
