import { Formik } from "formik";
import React from "react";
// import { useDispatch } from "react-redux";
import FormikControl from "../components/Formik/FormikControl";
// import CustomReactSelect from "../components/CustomReactSelect";
import { couponTypes } from "shared/constants";
import Axios from "axios";
import urls from "shared/urls";
import * as Yup from "yup";
import Button from "reactstrap/lib/Button";

const EditCoupon = ({ updateTable, initialValues }) => {
	const validationSchema = Yup.object().shape({
		code: Yup.string().required("Required"),
		course: Yup.string().required("Required"),
		plan: Yup.string().required("Required"),
		value: Yup.string().required("Required"),
		title: Yup.string().required("Required"),
		description: Yup.string().required("Required"),
	});

	const onSubmit = async (values, formik) => {
		let formData = { ...values };

		if (JSON.parse(values.isUnlimited || false)) {
			formData.max_uses = -1;
		}

		formData = {
			title: formData.title,
			description: formData.description,
			max_uses: formData.max_uses,
			coupon_type: formData.coupon_type,
			value: formData.value,
		};

		try {
			const response = await Axios.put(
				urls.discountCodes.singleCoupon(initialValues.id),
				formData
			);
			updateTable(response.data);
			formik.resetForm();
		} catch (error) {
			console.error(error);
			error.response && formik.setErrors(error.response.data);
		}
	};

	return (
		<Formik
			initialValues={{
				value: "",
				title: "",
				description: "",
				...initialValues,
				isUnlimited: initialValues.max_uses === -1 && true,
			}}
			enableReinitialize
			onSubmit={onSubmit}
			validationSchema={validationSchema}
		>
			{(formik) => (
				<form style={{ maxWidth: "600px" }}>
					{/* <code>{JSON.stringify(initialValues)}</code> */}
					<FormikControl
						label={
							formik.values.coupon_type === couponTypes.amount
								? "Amount"
								: "Percentage"
						}
						name="value"
						type="number"
						control="customInput"
						addonType={"prepend"}
						addonText={
							formik.values.coupon_type === couponTypes.amount ? "₹" : "%"
						}
					/>
					<FormikControl
						control="input"
						name="max_uses"
						type="number"
						min="-1"
						label="Maximum Usage"
						placeholder="Maximum usage"
						disabled={JSON.parse(formik.values.isUnlimited || false)}
					/>
					<FormikControl
						control="checkBoxWithLabel"
						name="isUnlimited"
						label="Check this box if you want it to be used unlimited times"
					/>
					<FormikControl
						control="input"
						name="title"
						type="text"
						label="Code title"
					/>
					<FormikControl
						control="input"
						name="description"
						type="text"
						label="Code description"
					/>
					<Button
						type="submit"
						color="primary"
						block
						disabled={!formik.dirty || formik.isSubmitting}
						onClick={formik.handleSubmit}
					>
						Edit Coupon
					</Button>
				</form>
			)}
		</Formik>
	);
};

export default EditCoupon;
