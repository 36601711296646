import { Field } from "formik";
import React from "react";
import uuid from "react-uuid";
const CheckBoxWithLabel = (props) => {
	const { name, label, ...rest } = props;
	const id = uuid();
	return (
		<div className="custom-control custom-checkbox mb-3">
			<Field
				className="custom-control-input"
				id={name + id}
				type="checkbox"
				name={name}
				{...rest}
			/>
			<label className="custom-control-label display-3" htmlFor={name + id}>
				{label}
			</label>
		</div>
	);
};

export default CheckBoxWithLabel;
