import { ErrorMessage } from "formik";
import React from "react";
// react plugin used to create DropdownMenu for selecting items
import Select2 from "react-select2-wrapper";
// reactstrap components
import { FormGroup } from "reactstrap";
import TextError from "./Formik/TextError";
const CustomReactSelect = ({
	dropDownArray,
	select,
	handleOnChange: onChange,
	onBlur,
	label,
	name,
	placeholder,
	rest,
}) => {
	// console.log(dropDownArray);
	return (
		<>
			<FormGroup>
				{label && <label htmlFor={label}>{label}</label>}
				<Select2
					className="form-control"
					value={select}
					data={dropDownArray}
					{...rest}
					options={{
						placeholder,
					}}
					onChange={(e) => onChange(e.target.value)}
				/>
				{name && <ErrorMessage name={name} component={TextError} />}
			</FormGroup>
		</>
	);
};

CustomReactSelect.defaultProps = {
	dropDownArray: [],
	select: null,
};
export default CustomReactSelect;
