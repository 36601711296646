import {
  FETCH_QUESTIONS_SUCCESS,
  FETCH_QUESTIONS_FAILED,
  FETCH_QUESTIONS_REQUEST,
  EDIT_QUESTION_SUCCESS,
  DELETE_QUESTION_REQUEST,
  DELETE_QUESTION_SUCCESS,
  DELETE_QUESTION_FAILED,
  EDIT_QUESTION_FAILED,
  ADD_QUESTION_SUCCESS,
  ADD_QUESTION_REQUEST,
  ADD_QUESTION_FAILED,
  EMPTY_ALL_QUESTIONS,
  EDIT_QUESTION_STATUS,
  // EDIT_QUESTION_REQUEST,
} from "../constants/questionConstants";
import Axios from "axios";
import urls from "shared/urls";
import omit from "lodash/omit";
import { SET_TEST_META } from "store/constants/testMetaConstants";
export const fetchAllQuestions = (testId) => async (dispatch) => {
  dispatch({ type: FETCH_QUESTIONS_REQUEST });
  try {
    const response = await Axios.get(urls.test.singleTest(testId));
    // FILTER: We don't need mmcq right now hence filtering it
    dispatch({
      type: FETCH_QUESTIONS_SUCCESS,
      payload: response.data.questions.filter(
        (question) => question.question_type !== "mmcq"
      ),
    });

    dispatch({
      type: SET_TEST_META,
      payload: omit(response.data, "questions"),
    });
  } catch (error) {
    console.error(error);
    dispatch({ type: FETCH_QUESTIONS_FAILED, payload: error });
  }
};
export const emptyAllQuestions = () => {
  return { type: EMPTY_ALL_QUESTIONS };
};
export const editQuestionType = (formData) => async (dispatch) => {
  let url = "/content/edit_ques/";
  switch (formData.question_type) {
    // case "1":
    // 	return <Mcq {...props} />;
    // case "2":
    // 	return null;
    case "3":
      url = "/content/edit_num/";
      break;
    case "4":
      url = "/content/edit_fitb/";
      break;
    case "5":
      url = "/content/edit_tf/";
      break;
    case "6":
      url = "/content/edit_subjective/";
      break;
    default:
      break;
  }

  try {
    const response = await Axios.post(url, formData);
    dispatch({ type: EDIT_QUESTION_SUCCESS, payload: response.data.data });
  } catch (error) {
    dispatch({ type: EDIT_QUESTION_FAILED });
    console.log(error.message);
  }
};

export const editQuestion = (formData) => async (dispatch, getState) => {
  const { id, ...data } = formData;

  if (id) {
    dispatch({
      type: EDIT_QUESTION_STATUS,
      payload: { id, questionState: "Saving" },
    });
    try {
      const response = await Axios.put(urls.test.question(id), data);
      dispatch({ type: EDIT_QUESTION_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: EDIT_QUESTION_FAILED });
      dispatch({
        type: EDIT_QUESTION_STATUS,
        payload: { id, questionState: "Not saved" },
      });
      console.error(error.message);
    }
  }
};

export const deleteQuestion =
  ({ questionId, testId }) =>
  async (dispatch) => {
    dispatch({ type: DELETE_QUESTION_REQUEST });
    try {
      await Axios.delete(urls.test.question(questionId));
      dispatch({ type: DELETE_QUESTION_SUCCESS, payload: questionId });
    } catch (error) {
      dispatch({ type: DELETE_QUESTION_FAILED });
      console.error(error.message);
    }
  };

// export const addNewQuestion = (testId) => async (dispatch, getState) => {
// 	dispatch({ type: ADD_QUESTION_REQUEST });
// 	try {
// 		const response = await Axios.post(urls.test.questions(testId));
// 		let questions = getState().questions;
// 		questions = Object.values(questions);
// 		const newQuestion = {
// 			id: response.data.questionid,
// 			question_type: "1",
// 			language: "2",
// 			paragraph: "",
// 			question: "<p><br></p>",
// 			solution: "",
// 			option_1: "",
// 			option_2: "",
// 			option_3: "",
// 			option_4: "",
// 			option_5: "",
// 			isOpen: true,
// 			is_option_1_correct: false,
// 			is_option_2_correct: false,
// 			is_option_3_correct: false,
// 			is_option_4_correct: false,
// 			is_option_5_correct: false,
// 			negative_marks: 0,
// 			max_marks: 1,
// 		};
// 		if (questions.length === 0) {
// 			// if there is no question we have to pass default object else we inherit the negative and max marks from previous question
// 			return dispatch({
// 				type: ADD_QUESTION_SUCCESS,
// 				payload: { [newQuestion.id]: newQuestion },
// 			});
// 		} else {
// 			dispatch({
// 				type: ADD_QUESTION_SUCCESS,
// 				payload: {
// 					[newQuestion.id]: {
// 						...newQuestion,
// 						negative_marks: questions[questions.length - 1].negative_marks,
// 						max_marks: questions[questions.length - 1].max_marks,
// 					},
// 				},
// 			});

// 			//edit question because when you add a new question and try to edit any value
// 			//all the other values are defaulted
// 			// so it's better to edit the question before with the values we need to avoid reset
// 			dispatch(
// 				editQuestion({
// 					...newQuestion,
// 					negative_marks: questions[questions.length - 1].negative_marks,
// 					max_marks: questions[questions.length - 1].max_marks,
// 				})
// 			);
// 		}
// 		// const newQuestion = {
// 		// 	id: response.data.questionid,
// 		// 	question_type: "1",
// 		// 	language: "2",
// 		// 	paragraph: "",
// 		// 	question: "<p><br></p>",
// 		// 	solution: "",
// 		// 	option_1: "",
// 		// 	option_2: "",
// 		// 	option_3: "",
// 		// 	option_4: "",
// 		// 	option_5: "",
// 		// 	isOpen: true,
// 		// 	is_option_1_correct: false,
// 		// 	is_option_2_correct: false,
// 		// 	is_option_3_correct: false,
// 		// 	is_option_4_correct: false,
// 		// 	is_option_5_correct: false,
// 		// 	negative_marks: questions[questions.length - 1].negative_marks,
// 		// 	max_marks: questions[questions.length - 1].max_marks,
// 		// };
// 	} catch (error) {
// 		console.log(error.message);
// 		dispatch({ type: ADD_QUESTION_FAILED });
// 	}
// };
export const addNewQuestion =
  ({ testId }) =>
  async (dispatch, getState) => {
    dispatch({ type: ADD_QUESTION_REQUEST });
    try {
      let response = "";

      let questions = getState().questions;
      questions = Object.values(questions);

      const newQuestion = {
        solution_text: "",
        choices: [
          {
            choice_text: "",
            // is_answer: false,
          },
          {
            choice_text: "",
            // is_answer: false,
          },
          {
            choice_text: "",
            // is_answer: false,
          },
          {
            choice_text: "",
            // is_answer: false,
          },
        ],
        question_type: "smcq",
        language: "english",
        max_marks: 1,
        negative_marks: 0,
        paragraph: "",
        question_text: "<p></p>",
        n_attempted: 0,
        n_correct: 0,
        n_wrong: 0,
        n_skipped: 0,
        extra_data: "",
        correct_answer: "",
      };
      if (questions.length === 0) {
        response = await Axios.post(urls.test.questions(testId), newQuestion);
        // if there is no question we have to pass default object else we inherit the negative and max marks from previous question
        return dispatch({
          type: ADD_QUESTION_SUCCESS,
          payload: { ...response.data, isOpen: true, questionState: "Saved" },
        });
      } else {
        let data = {
          ...newQuestion,
          question_type: questions[questions.length - 1].question_type,
          negative_marks: questions[questions.length - 1].negative_marks,
          max_marks: questions[questions.length - 1].max_marks,
        };
        if (questions[questions.length - 1].question_type === "true_false") {
          data = {
            ...data,
            choices: [
              {
                choice_text: "True",
                is_answer: true,
              },
              {
                choice_text: "False",
                is_answer: false,
              },
            ],
          };
        }
        response = await Axios.post(urls.test.questions(testId), data);
        return dispatch({
          type: ADD_QUESTION_SUCCESS,
          payload: { ...response.data, isOpen: true, questionState: "Saved" },
        });
      }
    } catch (error) {
      console.error(error.message);
      dispatch({ type: ADD_QUESTION_FAILED });
    }
  };

export const editQuestionOption = (formData) => async (dispatch) => {
  const { questionId, choiceId, ...data } = formData;
  if (questionId && choiceId) {
    dispatch({
      type: EDIT_QUESTION_STATUS,
      payload: { id: questionId, questionState: "Saving" },
    });
    try {
      const response = await Axios.put(
        urls.test.choice(questionId, choiceId),
        data
      );
      // console.log(response.data);
      dispatch({ type: EDIT_QUESTION_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({
        type: EDIT_QUESTION_STATUS,
        payload: { id: questionId, questionState: "Not saved" },
      });
      dispatch({ type: EDIT_QUESTION_FAILED });
      console.error(error.message);
    }
  }
};
