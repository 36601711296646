import { Form, Formik } from "formik";
import React from "react";
import { useSelector } from "react-redux";
import {
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	Button,
	Col,
} from "reactstrap";
import FormikControl from "../components/Formik/FormikControl";
import * as Yup from "yup";
import _ from "lodash";
import Axios from "axios";
import urls from "shared/urls";
const Notifications = ({ sendNotification }) => {
	const teacherCourses = useSelector((state) =>
		Object.values(state.teacherCourses)
	);

	const radioOptions = [
		{ id: 1, display_name: "All Students" },
		{ id: 2, display_name: "Select Course" },
	];

	const onSubmit = async (values, submitProps) => {
		let formData = { ...values };
		if (parseInt(formData.remove) === 1) {
			formData = _.omit(formData, "courseid");
		}
		formData = _.omit(formData, "remove");

		try {
			if (parseInt(values.remove) === 1) {
				await Axios.post(urls.notification.all, formData);
				sendNotification("success", "Sent to all students");
			} else {
				await Axios.post(urls.notification.course(formData.courseid), formData);
				sendNotification("success", "Sent to selected course");
			}
			submitProps.resetForm();
		} catch (error) {
			console.error(error);
			sendNotification();
		}
	};

	const validationSchema = Yup.object().shape({
		title: Yup.string()
			.min(2, "Too Short!")
			.max(50, "Too Long!")
			.required("Required"),
		// text: Yup.string()
		// 	.min(2, "Too Short!")
		// 	.max(50, "Too Long!")
		// 	.required("Required"),
		// remove: Yup.string().required("Required"),
		// courseid: Yup.string().when("remove", {
		// 	is: 2,
		// 	then: Yup.string().required("Required"),
		// }),
	});
	return (
		<Col md={9}>
			<Card>
				<CardHeader className="text-center display-4">
					Send Notification
				</CardHeader>
				<CardBody>
					<Formik
						initialValues={{
							title: "",
							text: "",
							courseid: teacherCourses[0]?.id || "", //first course default
							remove: 1,
						}}
						validationSchema={validationSchema}
						onSubmit={onSubmit}
						enableReinitialize
					>
						{(formik) => {
							// console.log(formik.errors);
							return (
								<Form>
									<FormikControl
										control="input"
										type="text"
										label="Title"
										name="title"
										placeholder="Notification  Title"
									/>
									<FormikControl
										control="input"
										type="textarea"
										label="Body"
										name="text"
										placeholder=" Notification Body"
									/>

									<FormikControl
										control="radio"
										name="remove"
										label="Select for which you want to send notification"
										options={radioOptions}
									/>
									{parseInt(formik.values.remove) === 2 && (
										<FormikControl
											control="radio"
											name="courseid"
											label="Select Course"
											options={teacherCourses}
										/>
									)}

									<CardFooter className="mx-0 px-0">
										<Button
											color="primary"
											type="submit"
											block
											disabled={!formik.dirty || formik.isSubmitting}
										>
											Send Notification
										</Button>
									</CardFooter>
								</Form>
							);
						}}
					</Formik>
				</CardBody>
				{/* <CardFooter>
				<Button>Send</Button>
			</CardFooter> */}
			</Card>
		</Col>
	);
};

export default Notifications;
