import React from "react";
import { Field, ErrorMessage } from "formik";
import TextError from "./TextError";
import style from "./radioButton.module.css";
import { FormGroup } from "reactstrap";
import uuid from "react-uuid";
function RadioButtons(props) {
	const { label, name, options, ...rest } = props;
	const customId = uuid();
	return (
		<FormGroup>
			<label className={`d-block`}>{label}</label>
			<div className="d-flex flex-wrap">
				<Field name={name}>
					{({ field, form }) => {
						return options.map((option) => {
							return (
								<React.Fragment key={option.id}>
									<input
										type="radio"
										id={field.name + option.id + customId}
										{...field}
										{...rest}
										// onChange={(e) =>
										// 	form.setFieldValue(name, parseInt(e.target.value))
										// }
										className={style.radio}
										value={option.id}
										checked={
											field.name + field.value + customId ===
											field.name + option.id + customId
										}
									/>
									<label
										htmlFor={field.name + option.id + customId}
										className={`m-2 btn btn-secondary  ${style.label}`}
									>
										{option.display_name || option.name}
									</label>
								</React.Fragment>
							);
						});
					}}
				</Field>
			</div>
			<ErrorMessage component={TextError} name={name} />
		</FormGroup>
	);
}

export default RadioButtons;
