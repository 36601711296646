import Axios from "axios";
import React, { useEffect, useState } from "react";
import { couponTypes } from "shared/constants";
import urls from "shared/urls";
import CouponsTable from "./CouponsTable";
import moment from "moment";
import { Card, CardBody, Col } from "reactstrap";
import keyBy from "lodash/keyBy";
import omit from "lodash/omit";
import AddCoupon from "./AddCoupon";
import { useSelector } from "react-redux";
import CustomReactSelect from "../components/CustomReactSelect";

const formatCouponValue = (value, couponType) => {
	if (couponType === couponTypes.amount) {
		return `₹${value}`;
	} else return `${value}%`;
};
const Coupons = () => {
	const [coupons, setCoupons] = useState([]);
	const teacherCourses = useSelector((state) =>
		Object.values(state.teacherCourses).map((item) => ({
			id: item.id,
			text: item.name,
		}))
	);
	const [selectedCourseId, setSelectedCourseId] = useState("0");

	useEffect(() => {
		const fetchCoupons = async () => {
			const params = {};
			if (selectedCourseId !== "0") {
				params.c_id = selectedCourseId; // select all courses has id:0 for all
			}
			const response = await Axios.get(urls.discountCodes.list, {
				params,
			});

			setCoupons(
				response.data.results.map((item) => ({
					...item,
					maxUsage: item.max_uses === -1 ? "Unlimited" : item.max_uses,
					valueWithTag: formatCouponValue(item.value, item.coupon_type),
					created: moment(item.created).format("Do MMM YYYY"),
				}))
			);
		};
		fetchCoupons();
	}, [selectedCourseId]);

	const updateTable = (item) => {
		const couponsObject = keyBy(coupons, "id");
		setCoupons(
			Object.values({
				...couponsObject,
				[item.id]: {
					...item,
					maxUsage: item.max_uses === -1 ? "Unlimited" : item.max_uses,
					valueWithTag: formatCouponValue(item.value, item.coupon_type),
					created: moment(item.created).format("Do MMM YYYY"),
				},
			})
		);
	};

	const deleteCoupon = (id) => {
		// console.log(id, couponObject);
		return setCoupons((prevState) => {
			let couponObject = keyBy(prevState, "id");
			couponObject = omit(couponObject, id);
			return Object.values(couponObject);
		});
	};

	return (
		<Col lg={9}>
			<AddCoupon updateTable={updateTable} />
			<Card>
				<CardBody>
					<CustomReactSelect
						label="Select Course"
						dropDownArray={[{ id: 0, text: "All courses" }, ...teacherCourses]}
						select={selectedCourseId}
						handleOnChange={(id) => {
							setSelectedCourseId(id);
						}}
					/>
					<CouponsTable
						dataTable={coupons}
						updateTable={updateTable}
						deleteCoupon={deleteCoupon}
					/>
				</CardBody>
			</Card>
		</Col>
	);
};

export default Coupons;
