import React from "react";
import { useState } from "react";
import {
	FormGroup,
	Input,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Modal,
} from "reactstrap";
import TimeKeeper from "react-timekeeper";
import { ErrorMessage, Field } from "formik";
import TextError from "./TextError";

const TimePicker = (props) => {
	const [showTimePicker, setShowTimePicker] = useState(false);

	const { label, name, className, ...rest } = props;

	return (
		<>
			<FormGroup>
				<label htmlFor={name} className="font-weight-bold">
					{label}
				</label>
				<Field name={name}>
					{({ form, field }) => {
						const { setFieldValue } = form;
						const { value } = field;
						return (
							<>
								<Modal
									isOpen={showTimePicker}
									toggle={() => setShowTimePicker(!showTimePicker)}
									className={"modal-dialog-centered time-keeper-modal "}
								>
									<TimeKeeper
										time={value}
										onChange={(val) => setFieldValue(name, val.formatted24)}
										onDoneClick={() => setShowTimePicker(false)}
									/>
								</Modal>
								<InputGroup>
									<InputGroupAddon addonType="prepend">
										<InputGroupText>
											<i className="fas fa-clock" />
										</InputGroupText>
									</InputGroupAddon>

									<Input
										onClick={() => setShowTimePicker(true)}
										value={value}
										{...field}
										{...rest}
									/>
								</InputGroup>
							</>
						);
					}}
				</Field>
				<ErrorMessage component={TextError} name={name} />
			</FormGroup>
		</>
	);
};

export default TimePicker;
