import React from "react";
import ReactDOM from "react-dom";
// react library for routing

// bootstrap rtl for rtl support page
import "assets/vendor/bootstrap-rtl/bootstrap-rtl.scss";
// plugins styles from node_modules
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
// plugins styles downloaded
import "assets/vendor/fullcalendar/dist/fullcalendar.min.css";
import "assets/vendor/sweetalert2/dist/sweetalert2.min.css";
import "assets/vendor/select2/dist/css/select2.min.css";
import "assets/vendor/quill/dist/quill.core.css";
import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
// core styles
import "assets/scss/argon-dashboard-pro-react.scss?v1.1.0";

//IMPORTED styles
import "./index.css";

//redux and firebase
import firebase from "firebase/app";

import "firebase/firestore";
import "firebase/storage";

import { createStore, applyMiddleware } from "redux";

import { ReactReduxFirebaseProvider, getFirebase } from "react-redux-firebase";
import { createFirestoreInstance } from "redux-firestore";
import { rootReducer } from "./store/reducers/1RootReducer";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import App from "App";
import { Provider } from "react-redux";
// const firebaseConfig = {
// 	apiKey: process.env.REACT_APP_API_KEY,
// 	authDomain: process.env.REACT_APP_AUTH_DOMAIN,
// 	databaseURL: process.env.REACT_APP_DATABASE_URL,
// 	projectId: process.env.REACT_APP_PROJECT_ID,
// 	storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
// 	messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
// };
var firebaseConfig = {
  apiKey: "AIzaSyBEeseqWIs31ZOXOTnzcodTlB6ly1b3Gs8",
  authDomain: "classcast-198812.firebaseapp.com",
  databaseURL: "https://classcast-198812.firebaseio.com",
  projectId: "classcast-198812",
  storageBucket: "classcast-198812.appspot.com",
  messagingSenderId: "633109830849",
  appId: "1:633109830849:web:b23e7611e7a3884e3e0840",
  measurementId: "G-74DQGNY35Q",
};

const rrfConfig = {
  userProfile: "users",
  useFirestoreForProfile: true,
};

firebase.initializeApp(firebaseConfig);
firebase.firestore().settings({ timestampsInSnapshots: true });

const middlewares = [thunk.withExtraArgument(getFirebase)];
const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middlewares))
);

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance, //since we are using Firestore
  // userProfile: "users", // where profiles are stored in database
  // presence: "presence", // where list of online users is stored in database
  // sessions: "sessions",
};

// function AuthIsLoaded({ children }) {
// 	const auth = useSelector((state) => state.firebase.auth);
// 	if (!isLoaded(auth))
// 		return (
// 			<div
// 				className=" d-flex flex-column align-items-center  justify-content-center text-center"
// 				style={{ height: "15rem", width: "100%" }}
// 			>
// 				<strong>Loading ...</strong>
// 			</div>
// 		);
// 	return children;
// }

ReactDOM.render(
  // <React.StrictMode>
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <App />
    </ReactReduxFirebaseProvider>
  </Provider>,
  // </React.StrictMode>,

  document.getElementById("root")
);
