import Axios from "axios";
import urls from "./urls";
import { getAuthInfo, saveAuthInfo, clearAuthInfo } from "./helpers";

import { BASE_URL } from "./constants";

function paramsSerializer(params = {}) {
	const arr = [];
	for (const [key, value] of Object.entries(params)) {
		if (Array.isArray(value)) {
			value.forEach((v) => arr.push(`${key}=${v}`));
		} else {
			arr.push(`${key}=${value}`);
		}
	}
	return arr.join("&");
}

const axios = Axios.create({
	baseURL: BASE_URL,
});

// request interceptor to add the token
axios.interceptors.request.use((request) => {
	const authInfo = getAuthInfo();
	if (authInfo) {
		request.headers["Authorization"] = `Bearer ${authInfo.access_token}`;
		request.headers["Content-Type"] = "application/json";
	}

	return request;
});

let tokenIsRefreshing = false;

const interceptor = (error) => {
	if (error.response) {
		if (error.response.status === 500) {
			return Promise.reject({});
		}
		if (error.response.status === 401) {
			if (tokenIsRefreshing) {
				return Promise.reject(error);
			}

			tokenIsRefreshing = true;
			refreshToken();

			// Reject the current request while the token refreshes
			return Promise.reject(error);
		} else {
			return Promise.reject(error);
		}
	}
	return Promise.reject(error);
};

// Reference: https://gist.github.com/Godofbrowser/bf118322301af3fc334437c683887c5f
function refreshToken() {
	Axios({
		method: "post",
		url: `${BASE_URL}${urls.auth.refresh}`,
		data: {
			refresh_token: getAuthInfo().refresh_token,
		},
	})
		.then((response) => {
			const authInfo = {
				...getAuthInfo(),
				access_token: response.data.data.access,
			};
			saveAuthInfo(authInfo);

			// If refresh token call succeeds then resolve all the calls in the queue with the new token
			// processQueue(null, response.data.data.access_token)
			// resolve(axios(originalRequest))
			window.location.reload();
		})
		.catch((error) => {
			// if (error.response) {
			// if (error.response.status === 404) {
			// eslint-disable-next-line no-console
			console.log("> Refresh token expired. Logging out.");
			clearAuthInfo();
			window.location.href = "/app/login";
			// }
			// }
			// If refresh token call fails then reject all the calls in the queue
			// processQueue(err)
			// reject(err)
		})
		.finally(() => {
			tokenIsRefreshing = false;
		});
}

axios.interceptors.response.use(undefined, interceptor);

axios.CancelToken = Axios.CancelToken;
axios.isCancel = Axios.isCancel;

export default axios;
